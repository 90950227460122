@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');

html {
  -webkit-text-size-adjust: auto;
  -ms-text-size-adjust: auto;
}

html,
body,
img,
form {
  margin: 0px;
  padding: 0px;
  border: none;
  letter-spacing: normal;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: #fafafa;
  color: black;
}

body.inner-body {
  background-color: #fff;
}

body.gray-inner {
  background-color: #fafafa;
}

body {
  overflow-x: hidden;
}

.loader,
.loadera,
.loader1,
.loader2,
#loaderone {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
}

.loader img,
.loadera img {
  width: 30%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 20px 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.loader1 img,
#loaderone img {
  width: 40px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 20px 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.loader2 img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 20px 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.loader h2,
.loadera h2 {
  position: fixed;
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  top: 65%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.loader-outer {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fff;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 999999;
}

.tj-loader {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 20px 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.tj-loader h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #444;
  text-align: center;
}

body,
td {
  font: 14px Arial, sans-serif;
  color: #000;
}

a {
  color: #000;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

td {
  vertical-align: top;
}

p {
  padding: 0px;
  /* margin: 0px 0px 14px 0px; */
}

object {
  outline: none;
}

fieldset {
  border: 0;
}

input,
select,
textarea,
button {
  outline: none !important;
}

h1 {
  font-size: 17px;
  padding: 0px;
  margin: 0px 0px 10px 0px;
}

h2 {
  font-size: 15px;
  padding: 0px;
  margin: 0px 0px 10px 0px;
}

h3 {
  font-size: 13px;
  padding: 0px;
  margin: 0px 0px 10px 0px;
}

.clear {
  clear: both;
}

.none {
  line-height: 0;
}

.relative {
  width: 100%;
  position: relative;
}

.img-marg {
  float: left;
  margin: 0 10px 5px 0;
}

.float-l {
  float: left;
}

.float-r {
  float: right;
}

.highlight {
  font-weight: bold;
}

.hfeed .posts {
  margin-bottom: 10px;
}

input,
select,
button,
textarea {
  outline: none;
}

.body-padding {
  padding-bottom: 30px;
}

.index-page .body-padding {
  padding-bottom: 0px;
}

.body-wrapper {
  padding-bottom: 50px;
  /* padding-top: 10px; */
  background-color: #e8e4ff;
}

#top {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 999;
}

.mobile-menu {
  background: #141d1e;
  display: none;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 89px;
  padding-top: 27px;
  padding-bottom: 26px;
}

.mobile-menu li {
  display: block;
  padding: 0px 60px 0px 60px;
}

.mobile-menu li a {
  font-size: 12px;
  color: #fff;
  font-family: 'Raleway';
  display: block;
  padding: 14px 0px 14px 0px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px solid #1a2526;
}

.mobile-menu li a:hover {
  color: #bd8100;
}

.mobile-menu li:last-child a {
  border-bottom: 0px;
}

.mobile-menu li ul {
  display: none;
}

.mobile-menu li li {
  padding: 0px;
}

.mobile-menu li li:last-child a {
  border-bottom: 1px solid #1a2526;
}

.mobile-menu li li a {
  padding-left: 10px;
  padding-right: 10px;
}

.fixed .mobile-menu {
  top: 59px;
}

.menu-btn {
  float: right;
  display: none;
  width: 12px;
  height: 10px;
  background: url(../public/img/menu-btn.png) right top no-repeat;
  margin: 4px 23px 0px 0px;
}

.wrapper-padding {
  position: relative;
}
.airport-flight-row {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Aligns elements in a row */
  width: 100%;
  padding: 8px 12px;
}

.airport-flight-container {
  display: flex;
  align-items: center;
  gap: 12px; /* Space between image and details */
  flex-grow: 1;
}
.airport-flight-details {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between airline name, number, and aircraft type */
}
.airport-cabin-class {
  margin-left: auto;
  font-weight: bold;
  color: #333;
  margin-right: 2%;
}
.baggage-info {
  display: flex;
  /* justify-content: space-between; */
  padding: 10px 0;
  font-size: 14px;
  color: #333;
  border-top: 1px solid #ddd;
  gap: 20px;
}
.baggage-icon {
  width: 16px; /* Adjust size as needed */
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
}
.cabin-baggage {
  display: inline-flex;
  align-items: center; /* Aligns the image and text properly */
  gap: 5px; /* Adds a little space between the image and text */
}
.checkin-baggage{
  display: inline-flex;
  align-items: center; /* Aligns the image and text properly */
  gap: 5px; /* Adds a little space between the image and text */
}

.main-cont {
  width: 100%;
  min-width: 300px;
  margin-top: 30px;
}

.styled-table {
  width: 80%;
  border-collapse: collapse;
  margin-top: 10px;
}

.styled-table th,
.styled-table td {
  border: 1px solid rgb(227, 227, 227); 
  padding: 8px;
  text-align: left;
}

.styled-table th {
  background-color: #f4f4f4;
}

.styled-table th, .styled-table td {
  width: 50%;  /* Ensure equal width for both columns */
  padding-left: 4%;
}

.sub-header {
  font-weight: normal;  /* Makes the text inside brackets not bold */
  font-size: 0.9em;      /* Optional: adjusts the size of the subtext */
}
.table-container {
  margin-top: 20px;  /* Adjust the top margin as needed */
  margin-bottom: 20px;  /* Adjust the bottom margin as needed */
}
.note {
  font-size: 0.7em;       /* Smaller font size */
  color: #555;            /* Lighter color for the note */
  margin-top: 10px;       /* Adds some space above the note */
  margin-bottom: 10px;    /* Adds space below the note */
}
.highlighted-note {
  font-size: 0.8em;
  color: #555;
  margin-top: 20px;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f6e2d5;  
  border-radius: 5px;         /* Optional: rounded corners */
}


.header-a {
  height: 40px;
  width: 100%;
  background: #141d1e;
}

.header-b {
  /* position: fixed; */
}

.header-phone {
  float: left;
  padding: 11px 16px 0px 16px;
  height: 29px;
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
}

.header-phone span {
  background: url(../public/img/icon-phone.png) left top no-repeat;
  background-position: left 1px;
  padding: 1px 0px 0px 25px;
  font-size: 11px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  display: inline-block;
  min-height: 15px;
}

.header-phone a[href^=tel] {
  color: #fff;
  text-decoration: none;
}

.header-account {
  float: right;
}

.header-account a {
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
  padding: 14px 22px 0px 22px;
  height: 26px;
}

.header-social {
  float: right;
  border-left: 1px solid #333333;
  padding: 13px 22px 0px 25px;
  height: 27px;
}

.header-social a {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0px 9px 0px 9px;
}

.header-social .social-twitter {
  background: url(../public/img/head-twitter.png) left top no-repeat;
  margin-left: 0px;
}

.header-social .social-facebook {
  background: url(../public/img/head-facebook.png) left top no-repeat;
}

.header-social .social-vimeo {
  background: url(../public/img/head-vimeo.png) left top no-repeat;
}

.header-social .social-pinterest {
  background: url(../public/img/head-pinterest.png) left top no-repeat;
}

.header-social .social-instagram {
  background: url(../public/img/head-instargam.png) left top no-repeat;
  margin-right: 0px;
}

.header-social a:hover {
  background-position: left -14px;
}

.header-viewed {
  float: right;
  position: relative;
}

.viewed-drop {
  display: none;
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 999;
  border-top: 8px solid #fff;
  width: 330px;
  background: #fcf8f5;
}

.viewed-drop-a {
  padding: 17px 16px 17px 16px;
}

.viewed-item {
  border-bottom: 1px solid #ebe7e4;
  padding-bottom: 13px;
  margin-bottom: 13px;
}

.viewed-item:last-child {
  border-bottom: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.viewed-item-l {
  margin-top: 3px;
  width: 79px;
  float: left;
}

.viewed-item-lbl {
  margin-bottom: 7px;
}

.viewed-item-lbl a {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 13px;
  text-transform: uppercase;
  color: #252525;
  text-decoration: none;
}

.viewed-item-cat {
  margin-bottom: 8px;
  font-size: 10px;
  text-transform: uppercase;
  color: #939393;
  font-family: 'Raleway';
  font-weight: 600;
}

.viewed-price {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 17px;
  color: #bd8100;
}

.viewed-item-r {
  width: 208px;
  float: right;
}

.header-viewed-btn {
  display: block;
  border-left: 1px solid #333333;
  padding: 14px 22px 0px 25px;
  height: 26px;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 700;
  text-decoration: none;
}

.header-lang {
  float: right;
  position: relative;
}

.langs-drop {
  display: none;
  position: absolute;
  left: 0px;
  top: 41px;
  border-top: 5px solid #fff;
  padding: 10px 10px 3px 15px;
  background: #fcf8f5;
  width: 125px;
  z-index: 999;
}

.header-lang a {
  display: block;
  border-left: 1px solid #333333;
  padding: 13px 13px 0px 13px;
  height: 27px;
}

.header-lang a.langs-item {
  display: inline-block;
  font-size: 10px;
  color: #697374;
  margin: 0px;
  padding: 0px;
  height: auto;
  padding: 0px 0px 0px 24px;
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  border: 0px;
  line-height: 13px;
  margin-bottom: 11px;
}

.header-lang a.langs-item:hover {
  color: #bd8100;
}

.header-lang a.langs-item.en {
  background: url(../public/img/en.gif) left top no-repeat;
}

.header-lang a.langs-item.fr {
  background: url(../public/img/fr.gif) left top no-repeat;
}

.header-lang a.langs-item.de {
  background: url(../public/img/de.gif) left top no-repeat;
}

.header-lang a.langs-item.it {
  background: url(../public/img/it.gif) left top no-repeat;
}

.header-curency {
  float: right;
  position: relative;
}

.header-curency:hover a {
  color: #bd8100;
}

.curency-drop {
  position: absolute;
  left: 0px;
  display: none;
  top: 40px;
  border-top: 4px solid #fff;
  z-index: 999;
  font-family: 'Raleway';
  font-weight: 700;
  background: #fcf8f5;
  width: 126px;
  padding: 11px 10px 7px 14px;
}

.header-curency a {
  display: block;
  border-left: 1px solid #333333;
  padding: 14px 14px 0px 14px;
  height: 26px;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 700;
  text-decoration: none;
}

.header-curency .curency-drop div {
  margin-bottom: 8px;
}

.header-curency .curency-drop a {
  font-size: 11px;
  color: #697374;
  margin: 0px;
  padding: 0px;
  height: auto;
  display: inline;
  text-transform: uppercase;
  border: 0px;
}

.header-b {
  height: 53px;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 1px 1px 2px 0px rgba(50, 50, 50, 0.11);
  -moz-box-shadow: 1px 1px 2px 0px rgba(50, 50, 50, 0.11);
  box-shadow: 1px 1px 2px 0px rgba(50, 50, 50, 0.11);
  transition: transform 0.3s ease-out; /* Smooth transition */
}

.header-b-hidden {
  transform: translateY(-100%); /* Move the header up out of view */
}

.header-logo {
  float: left;
  margin: 5px 0px 0px 0px;
}

.header-logo img {
  width: 188px;
}

.fixed .header-logo img {
  width: 136px;
}

.header-right {
  float: right;
  margin-top: 20px;
}

.header-nav {
  float: right;
}

.header-nav ul,
.header-nav ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.header-nav ul li {
  margin-left: 30px;
  float: left;
}

.header-nav ul li a {
  font-size: 16px;
  color: black;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.hdr-srch-devider {
  float: right;
  height: 15px;
  width: 1px;
  margin: 1px 17px 0px 21px;
  background: #e2e2e2;
}

.hdr-srch {
  position: relative;
  float: right;
  margin: 2px 0px 0px 0px;
}

.hdr-srch-overlay {
  display: none;
  height: 36px;
  padding-top: 14px;
  background: #fff;
  width: 100%;
  position: absolute;
  right: 0px;
  top: 22px;
  z-index: 999;
}

.hdr-srch-overlay input[type='text'] {
  font-family: 'PT Sans';
  font-size: 13px;
  color: #6e6e6e;
  font-weight: 700;
  border: 0px;
  width: 90%;
  float: left;
  background: transparent;
  text-transform: uppercase;
}

.srch-close {
  display: block;
  width: 13px;
  height: 13px;
  float: right;
  margin: 3px 0px 0px 0px;
  background: url(../public/img/srch-close.png) left top no-repeat;
}

.hdr-srch-btn {
  display: block;
  width: 13px;
  height: 13px;
  background: url(../public/img/srch-icon.png) left top no-repeat;
}

.hdr-srch-btn:hover {
  background-position: left -13px;
}

.mp-slider {
  position: relative;
  padding: 53px 0px 0px 0px;
}

.mp-slider .slide-section {
  width: 100%;
  height: 807px;
}

.swiper-container {
  /* position: relative; */
  background: url(../public/img/sider-01.png) center bottom;
}

.swiper-preloader-bg {
  width: 100%;
  height: 100%;
  background: #fcf8f5;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 99;
}

.swiper-preloader {
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
}

.swiper-container,
.swiper-slide {
  width: 1925px;
  height: 750px;
  
}

.swiper-pagination {
  position: absolute;
  right: 40px;
  bottom: 32px;
  z-index: 999;
}

.swiper-pagination-switch {
  display: block;
  cursor: pointer;
  width: 6px;
  height: 6px;
  margin-top: 4px;
  border: 1px solid #fff;
  border-radius: 10000px;
}

.swiper-visible-switch.swiper-active-switch {
  background: #fff;
}

.mp-slider-lbl {
  padding: 50px 0px 0px 0px;
  text-align: center;
  width: 100%;
  font-size: 39px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: #fff;
  position: relative;
  top: 18px;
  opacity: 0;
}

.search-only .mp-slider-lbl {
  padding-top: 290px;
}

.mp-slider-lbl-a {
  padding: 20px 0px 28px 0px;
  text-align: center;
  font-size: 17px;
  color: #fff;
  position: relative;
  top: 18px;
  opacity: 0;
  font-weight: 400;
  font-family: 'Lora';
  font-style: italic;
}

.mp-slider-lbl-b {
  margin-bottom: 22px;
  color: #bd8100;
  font-family: 'Lato';
  font-size: 39px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  top: 18px;
  opacity: 0;
}

.btn-a {
  font-family: 'Montserrat';
  font-weight: 700;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  padding: 12px 33px 12px 33px;
  text-align: center;
  font-size: 11px;
  border-radius: 3px;
  webkit-border-radius: 3px;
  moz-border-radius: 3px;
  text-transform: uppercase;
  position: relative;
  top: 18px;
  opacity: 0;
}

.btn-a:hover {
  color: #4a90a4;
  background: #fff;
}

.slide-b .mp-slider-lbl,
.slide-b .mp-slider-lbl-a {
  color: #fff;
}

.mp-slider-btn {
  text-align: center;
}

.page-search {
  float: left;
  width: 347px;
  height: 385px;
  margin: 0px 31px 0px 0px;
  background: transparent;
}

.page-search.full-width-search {
  float: none;
  width: 100%;
  height: 385px;
  margin: 0px 0px 0px 0px;
  background: transparent;
}

.page-search.full-width-search .search-tab-content {
  width: 100%;
}

.search-tab-content {
  display: none;
}

.search-tab-content:first-child {
  display: block;
}

.page-search.full-width-search .page-search-tabs {
  width: 370px;
}

.search-tab {
  height: 38px;
  border: none;
  display: inline-block;
  margin: 0px 3px 0px 0px;
  float: left;
  text-align: center;
  font-size: 12px;
  color: #000;
  background: transparent;
  text-transform: uppercase;
  font-family: 'Montserrat';
  
  cursor: pointer;
  padding: 13px 25px 13px 21px;
}

.search-tab.nth {
  margin-right: 0px;
  float: right;
}

.search-tab.active {
  
  cursor: default;
  color: #bd8100;
  border: none;
}

.page-search-content {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.page-search-p {
  padding: 26px 21px 29px 21px;
}

.page-search-p1{
  padding: 25px 21px 11px 21px;
  width: 100%;
}
.page-search-p2 {
  padding: 21px 21px 1px 21px;
  width: 100%;
}
.page-search-p1 .bookingtype {
  background-color: rgba(0,0,0,.03);
width: 100%;
padding: 5px;
color: #000;
font-size: 14px;
text-align: center;
border-radius: 3px;
font-weight: 600;
cursor: pointer;

}

/* .page-search-p2 .bookingtype {
  display: block;
  padding-left: 0px;
  display: block;
  font-size: 14px;
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 25px;
} */
.page-search-p2 .bookingtype {
background-color: rgba(0,0,0,.03);
width: 100%;
padding: 5px;
color: #000;
font-size: 14px;
text-align: center;
border-radius: 3px;
font-weight: 600;
cursor: pointer;
}
.page-search-p1 .One_Way {
  width: 15%;
  float: left;
}

.page-search-p1 .Return {
  width: 15%;
  float: left;
}



.page-search-p2 .One_Way {
  width: 50%;
  float: left;
  
 
}

/* .page-search-p2 .Return {
  width: 65%;
  float: right;
} */
.page-search-p2 .Return {
  width: 50%;
  float: right;
  
 
  
}
.page-search-p1 .bookingtypecheck,
.page-search-p2 .bookingtypecheck{
  display: none;
}
.page-search-p1 .bookingtypecheck:checked+label::before {
  color: rgb(255, 114, 0);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 6px;
}

.full-width-search .page-search-p {
  padding: 1px 21px 1px 21px;
}

.page-search-content label {
  display: block;
    font-size: 15px;
    color: #4c4c4c;
    text-align: center;
    text-transform: capitalize;
    font-family: 'Open Sans';
    font-weight: 600;
    margin-bottom: 5px;
}

.search-line {
  margin: 0px 0px 18px 0px;
}

.search-line input[type='text'] {
  border: 1px solid #e3e3e3;
  background: #fff;
  width: 284px;
  border-radius: 3px;
  padding: 9px 8px 9px 8px;
  text-transform: uppercase;
  font-family: 'Raleway';
  font-size: 11px;
  color: #8a8a8a;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  -moz-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
}

.search-line-a {
  float: left;
  width: 140px;
  margin: 0px 0px 18px 0px;
}

.full-width-search .search-line-a {
  width: auto;
  margin-right: 19px;
  margin-bottom: 0px;
}

.search-line-b {
  float: right;
  width: 140px;
  margin: 0px 0px 18px 0px;
}

.search-line-c {
  margin-bottom: 17px;
}

.search-line-a.nth,
.search-line-b.nth,
.search-line-c.nth {
  margin-bottom: 0px;
}

.search-line-a input[type='text'],
.search-line-b input[type='text'] {
  border: 1px solid #e3e3e3;
  background: #fff;
  width: 120px;
  border-radius: 3px;
  font-size: 11px;
  padding: 8px;
  text-transform: uppercase;
  font-family: 'Raleway';
  color: #8a8a8a;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  -moz-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
}

.search-line-a.large input[type='text'] {
  width: 305px;
}

.search-line-a.middle input[type='text'] {
  width: 143px;
}

.search-line-a.middle-a input[type='text'] {
  width: 133px;
}

.search-line-select {
  width: 87px;
  float: left;
  margin: 0px 21px 0px 0px;
}

.search-line-select.nth {
  margin-right: 0px;
  float: right;
}

.search-select {
  width: 83px;
  overflow: hidden;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border: 1px solid #e3e3e3;
}

.search-line-select.middle {
  width: 94px;
}

.search-line-select.middle.last {
  margin-right: 0px;
}

.search-line-select.middle .search-select {
  width: 90px;
}

.srch-horizontal {
  margin-right: 12px;
  float: left;
}

.search-select select {
  background-color: #fff;
  cursor: pointer;
  background-image: url(../public/img/select-bg.gif);
  background-position: 80% center;
  background-repeat: no-repeat;
  border: 0px;
  border-radius: 0;
  font-size: 11px;
  padding: 7px 0px 8px 4px;
  outline: medium none;
  width: 117%;
  -webkit-appearance: none;
  -o-appearance: none;
  -khtml-appearance: none;
  appearance: none;
  text-transform: uppercase;
  font-family: 'Raleway';
  color: #8a8a8a;
}

.search-select.full-width {
  border: 1px solid #e3e3e3;
  background: #fff;
  font-size: 11px;
  width: 300px;
  background: url(../public/img/select-bg.gif) right center no-repeat;
  border-radius: 3px;
}

.search-select.full-width select,
.search-select.full-width-a select,
.search-select.full-width-b select,
.search-select.full-width-c select {
  background-color: #fff;
  cursor: pointer;
  background-image: url(../public/img/select-bg.gif);
  background-position: 94.3% center;
  background-repeat: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #d3d3d3;
  font-size: 11px;
  padding: 7px 0px 8px 4px;
  outline: medium none;
  width: 105%;
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: 600;
  color: #8a8a8a;
}

.search-select.middle-c {
  border: 1px solid #d6d6d6;
  background: #fff;
  font-size: 11px;
  width: 149px;
  background: url(../public/img/select-bg.gif) right center no-repeat;
  border-radius: 3px;
}

.full-width-search .search-line-a.last {
  margin-right: 0px;
}

.search-select.full-width-a {
  border: 1px solid #e3e3e3;
  background: #fff;
  font-size: 11px;
  width: 341px;
  background: url(../public/img/select-bg.gif) right center no-repeat;
  border-radius: 3px;
}

.search-select.full-width-b {
  border: 1px solid #e3e3e3;
  background: #fff;
  font-size: 11px;
  width: 321px;
  background: url(../public/img/select-bg.gif) right center no-repeat;
  border-radius: 3px;
}

.search-select.full-width-c {
  border: 1px solid #e3e3e3;
  background: #fff;
  font-size: 11px;
  width: 321px;
  margin-right: 18px;
  background: url(../public/img/select-bg.gif) right center no-repeat;
  border-radius: 3px;
}

.search-line-select.middle-b {
  width: auto;
  float: left;
  margin-right: 0px;
  margin-left: 18px;
}

.search-select.half-width select {
  background-color: #fff;
  cursor: pointer;
  background-image: url(../public/img/select-bg.gif);
  background-position: 89% center;
  background-repeat: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #d3d3d3;
  font-size: 11px;
  padding: 7px 0px 8px 4px;
  outline: medium none;
  width: 110%;
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: 600;
  color: #8a8a8a;
}

.search-select.half-width {
  width: 136px;
}

.search-select span {
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: 600;
  color: #8a8a8a;
}

.search-select-a {
  cursor: pointer;
  padding: 8px;
}

.search-footer {

  padding: 0px 21px 10px 22px;
}

/* .search-asvanced {
  padding-top: 17px;
} */
 
.search-asvanced {
  display: block;
  padding: 15px;
  background-color: #f9f9f9; /* Light background color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 640px; /* Adjust the width as needed */
  margin-left:38.5%;
  margin-top: -6%;
  position: absolute;
}

.overlay {
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(20, 29, 30, 0.19);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
}

.autorize-popup {
  z-index: 9999;
  width: 444px;
  height: 255px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: -300px;
  margin-top: -128px;
  margin-left: -222px;
}

.autorize-tab-a {
  display: block;
  float: left;
  width: 82px;
  height: 27px;
  text-align: center;
  text-decoration: none;
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  background: #141d1e;
  padding-top: 12px;
}

.autorize-tab-b {
  display: block;
  float: left;
  width: 303px;
  height: 27px;
  text-decoration: none;
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  background: #141d1e;
  padding-top: 12px;
  padding-left: 20px;
}

.autorize-tabs a.current {
  color: #292828;
  background: #fff;
  cursor: default;
}

.autorize-close {
  display: block;
  width: 39px;
  height: 39px;
  float: right;
  background: url(../public/img/popup-close.png) left top no-repeat;
}

.autorize-close:hover {
  background-position: left -39px;
}

.autorize-lbl {
  font-family: 'Raleway';
  font-size: 13px;
  color: #6d6d6d;
  margin: 0px 0px 18px 0px;
}

.autorize-padding {
  padding: 22px 20px 20px 21px;
}

.autorize-tab-content {
  display: none;
}

.mySeatModal {
  position: fixed;
  width: 70%;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: none;
}

.mySeatModal .modal-content {
  background-color: #d8d8d8;

}

.mySeatModal .modal-content .modal-header {

  padding: 10px;
  position: relative;
}

.mySeatModal .modal-content .modal-header h2 {
  background-color: transparent;
}

.mySeatModal .modal-content .modal-header .close-button {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 600;
  padding: 15px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mySeatModal .modal-content .modal-header .close-button:hover {
  background-color: #ccc;
}

.mySeatModal .modal-content .modal-body {
  background-color: #f2f2f2;
}

.mySeatModal .modal-content .modal-body #myseatModalbody h2 {
  background-color: transparent;
}

.mySeatModal .modal-content .modal-body #myseatModalbody #seatTable .seatTablebody {
  width: 100%;
  float: left;
  text-align: left;
}

.mySeatModal .modal-content .modal-body #myseatModalbody #seatTable .seatTablebody .flight_details {
  text-align: left;
  font-size: 15px;
  font-family: 'raleway';
  font-weight: 600;
  width: 100%;
}

.mySeatModal .modal-content .modal-body #myseatModalbody #seatTable .seatTablebody .passenger_name {
  width: 15%;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: 600;
  font-family: 'raleway';
}

.mySeatModal .modal-content .modal-body #myseatModalbody #seatTable .seatTablebody .colonseat {
  width: 1%;
  float: left;
}

.mySeatModal .modal-content .modal-body #myseatModalbody #seatTable .seatTablebody .seat_no {
  width: 8%;
  text-align: left;
  float: left;
  font-size: 14px;
  font-family: 'Open Sans';
}

.mySeatModal .modal-content .modal-body #myseatModalbody #seatTable .seatTablebody label {
  padding: 5px;
}

.accordion:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.accordion.active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.accordion:after {
  /* content: '\002B';
  color: #777;
  font-weight: normal;

  float: right;
  font-size: 29px;

  margin-left: 5px;
  padding: 0px;
  height: 0px;
  color: #bd8100;
  line-height: 0.8;
  margin: 0px; */
}


.accordion.active:after {
  content: "\2212";
}

.panel {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 0px !important;
  padding: 0 0px;
  background-color: white;
  max-height: 0;
  overflow: hidden;

  transition: max-height 0.2s ease-out;
}

.panel .seatleft {
  width: 20%;
  float: left;
  overflow-y: auto;
}

.panel .seatleft .seatleftul {
  list-style: none;
  padding: 0px;
  width: 80%;
}

/* .panel .seatleft .seatleftul .passengerdisplay{
  border: 2px solid #bd8100;
  margin: 2px;
  box-shadow: 10px 7px 10px 2px rgba(50, 50, 50, 0.05);
} */
.panel .seatleft .seatleftul .seatleftli {
  margin-top: 1%;
  margin-bottom: 20%;
  font-size: 13px;
  border: none;
  font-family: 'Open Sans';
  text-transform: uppercase;
  width: 100%;
  padding: 10px 15px;
  color: #785eff;
  background-color: #fff;
  box-shadow: 10px 7px 10px 2px rgba(50, 50, 50, 0.05);
  font-weight: 600;
}
.panel .seatleft .seatleftul .seatleftli span{
text-transform: capitalize;
font-size: 10px;
}
.panel .seatleft .seatleftul .seatleftli.active {
  background-color: #785eff;
  color: #fff;
  box-shadow: none;
}

.panel .seatleft .seatleftul .seatleftli.hover {
  background-color: #644ed4;
  color: #fff;
  box-shadow: none;
}

.panel .seatright {
  width: 75%;
  float: left;
}

.autorize-tab-content input[type='text'] {
  border: 1px solid #ebebeb;
  background: #fff;
  width: 381px;
  border-radius: 3px;
  font-size: 11px;
  padding: 10px 8px 10px 8px;
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: 600;
  color: #8a8a8a;
  margin-bottom: 15px;
}

.authorize-btn {
  display: block;
  width: 118px;
  height: 34px;
  padding-top: 3px;
  cursor: pointer;
  background: #bd8100;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  text-align: center;
  font-size: 11px;
  text-decoration: none;
  color: #fff;
  float: left;
  font-family: 'Montserrat';
  font-weight: normal;
  text-transform: uppercase;
  border: 0px;
}

.authorize-btn:hover {
  background: #ffca27;
  border-color: #ffca27;
}

.authorize-forget-pass {
  float: left;
  font-family: 'Raleway';
  font-size: 12px;
  color: #6d6d6d;
  text-decoration: none;
  margin: 12px 0px 0px 15px;
}

.header-account a:hover,
.header-viewed-btn:hover,
.header-viewed:hover .header-viewed-btn,
.header-curency a:hover,
.header-nav ul li a:hover {
  color: #644ed4;
}

.header-nav ul li {
  position: relative;
}

.header-nav ul li a {
  padding-bottom: 39px;
}

.fixed .header-nav ul li a {
  padding-bottom: 39px;
}

.fixed .header-nav ul ul {
  top: 37px;
}

.fixed .hdr-srch-overlay {
  top: 7px;
}

.header-nav ul ul {
  display: none;
  position: absolute;
  left: -14px;
  top: 34px;
  z-index: 999;
  width: 211px;
  background: #a594ff;
  padding: 17px 0px 18px 0px;
  border-radius: 0.5rem;
}

.header-nav ul li:hover>a {
  color: #785eff;
}

.header-nav ul ul li {
  margin: 0px;
  padding: 0px;
  display: block;
  float: none;
  margin-bottom: 19px;
  padding: 0px 15px 0px 15px;
}

.header-nav ul ul li:last-child {
  margin-bottom: 0px;
}

.header-nav ul ul li a {
  margin: 0px;
  padding: 0px;
  font-size: 11px;
  color: white;
  font-family: 'Raleway';
  font-weight: 600;
}

.wrapper-padding {
  max-width: 1325px;
  margin: 0 auto;
}

.wrapper-padding-a {
  max-width: 1190px;
  margin: 0 auto;
}

.wrapper-a-holder {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  height: 1px;
}

.wrapper-a {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  height: 1px;
  left: 0px;
  z-index: 99;
  bottom: 298px;
}

.full-width-search .wrapper-a {
  bottom: 146px;
}

.special-offer-a {
  margin: 42px 0px 0px 0px;
  float: left;
  width: 346px;
}

.special-offer-img {
  height: auto;
  margin: 0px;
}

.special-offer-img img {
  width: 100%;
}

.special-offer-img a {
  display: block;
  line-height: 0px;
}

.special-offer-img img,
.special-offer-img a {
  height: auto;
  margin: 0px;
  padding: 0px;
}

.special-offer-foot-l {
  margin-top: 3px;
  float: left;
  width: 230px;
}

.special-offer-foot-l b {
  display: block;
  font-weight: normal;
  display: block;
  text-transform: uppercase;
  color: #252525;
  margin-bottom: 7px;
  font-family: 'Montserrat';
}

.special-offer-foot-l span {
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  color: #939393;
}

.special-offer-foot-r {
  float: right;
  text-align: right;
  margin-top: 1px;
}

.special-offer-foot-r b {
  font-family: 'Montserrat';
  font-size: 20px;
  display: block;
  font-weight: normal;
  margin-bottom: 3px;
  color: #bd8100;
}

.special-offer-foot-r span {
  display: block;
  font-size: 10px;
  font-family: 'Raleway';
  font-weight: 600;
  color: #939393;
  text-transform: uppercase;
}

.special-offer-foot {
  background: #fff;
  padding: 21px 19px 25px 20px;
}

.special-offer-b {
  float: left;
  width: 345px;
  height: 344px;
  margin: 42px 0px 0px 31px;
}

.weather-block {
  width: 345px;
  height: 344px;
  /* background: url(../public/img/paris.jpg) left top no-repeat; */
  background-size: 345px 344px;
  ;
}

.weather-i {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin: 57px 13px 0px 14px;
}

.weather-i:nth-child(2n) {
  margin-left: 19px;
}

.weather-i:nth-child(2n) .weather-b {
  margin-left: 3px;
}

.weather-devider {
  margin: 56px 0px 0px 0px;
  height: 1px;
  background: #9f9e9d;
}

.weather-a {
  margin: 0px 0px 19px 0px;
  font-size: 13px;
  color: #fff;
  font-family: 'Montserrat';
  text-transform: uppercase;
}

.weather-b {
  margin-bottom: 17px;
}

.weather-c {
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
}

.weather-block-padding {
  text-align: center;
}

.weather-footer {
  text-align: left;
  padding: 20px 0px 0px 18px;
}

.weather-foot-link-a {
  font-size: 21px;
  line-height: 25px;
  color: #fff;
  font-weight: nomal;
  font-family: 'Montserrat';
  font-weight: normal;
  display: block;
}

.weather-footer a {
  text-decoration: none;
}

.weather-foot-link {
  display: block;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: 600;
  margin-bottom: 5px;
}

.srch-btn {
  display: block;
  width: 102px;
  height: 24px;
  padding-top: 10px;
  background: #785eff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  text-align: center;
  font-size: 11px;
  text-decoration: none;
  color: #fff;
  border: 0px;
  margin-top: 1px;
  float: left;
  font-family: 'Montserrat';
  font-weight: normal;
  text-transform: uppercase;
}

button.srch-btn {
  cursor: pointer;
  font-family: 'Open Sans';
  
  font-weight: normal;
  padding-top: 2px;
  border-radius: 5px;
  float: right;
  font-weight: 700;
  margin-top: 21px;
  height: 34px;
}

.date-icon {
  display: block;
  width: 16px;
  height: 15px;
  background: url(../public/img/datepick.png) left top no-repeat;
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 4px;
  cursor: pointer;
}

.input-a {
  position: relative;
}

.srch-tab-left .input-a .searchFromResultsclass,
.srch-tab-right .input-a .searchFromResultsclass {
  position: absolute;
  top: 100%;

  border-radius: 3px;
  background-color: #fff;
  padding-left: 6px;
  width: 100px;
  border: 1px solid #e3e3e3;
  margin-left: -7px;
  list-style: none;
  width: 100%;
  z-index: 999;
}

.srch-tab-left .redorigin,
.srch-tab-left .redorigin1,
.srch-tab-right .redestination,
.srch-tab-right .redestination1  {
  color: red;
  font-size: 12px;
  font-family: 'Raleway';
  display: none;
}

.srch-tab-left .error-message,
.srch-tab-right .error-message {
  color: red;
  font-size: 12px;
  font-family: 'Raleway';
}

.srch-tab-left .redsearchdeparture ,
.srch-tab-left .redsearchdeparture1,
.srch-tab-right .redsearchreturn1,
.srch-tab-right .redsearchreturn{
  color: red;
  font-size: 12px;
  font-family: 'Raleway';
  display: none;
}

.date-inpt {
  cursor: pointer;
  z-index: 99;
  position: relative;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-radius: 0px !important;
  border-color: #ebebeb;
  background: #fff;
}

.ui-widget-header {
  background: #fff !important;
  border: 0px !important;
}

.ui-datepicker .ui-datepicker-title {
  font-size: 12px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-weight: normal;
  color: #4c4c4c;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  font-family: 'Montserrat';
  border: 0px !important;
  font-size: 11px;
  font-weight: normal;
  border-radius: 0px !important;
  display: block;
}

.ui-datepicker td span,
.ui-datepicker td a {
  padding: 5px 18px 10px 10px !important;
  font-weight: normal !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: 7px;
  background: #fafafa !important;
}

.ui-datepicker td {
  padding: 0px !important;
  color: #686d6e;
  border: 1px solid #eaeaea;
}

.ui-datepicker td a:hover {
  background: #bd8100 !important;
  color: #fff;
}

.ui-state-default.ui-state-active {
  background: #bd8100 !important;
  color: #fff;
}

.ui-datepicker th {
  font-size: 11px;
  font-weight: normal;
}

.ui-datepicker {
  padding: 10px 14px 10px 14px;
  margin-left: -8px;
  margin-top: 15px;
  width: 252px;
}

.ui-widget-header .ui-icon.ui-icon-circle-triangle-w {
  width: 4px;
  height: 8px;
  left: 10px;
  background: url(../public/img/cal-left.png) left top no-repeat;
  background-position: 0px 0px;
}

.ui-widget-header .ui-icon.ui-icon-circle-triangle-e {
  width: 4px;
  height: 8px;
  left: auto;
  margin-left: 0px;
  right: 0px;
  background: url(../public/img/cal-right.png) left top no-repeat;
  background-position: 0px 0px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 0px;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-next {
  right: 0px;
  cursor: pointer;
}

.ui-corner-all.ui-state-hover {
  border-color: transparent;
  background: transparent;
}

.ui-datepicker .ui-datepicker-prev.ui-state-hover {
  left: -1px;
}

.ui-datepicker .ui-datepicker-next.ui-state-hover {
  right: -1px;
}

.input-a {
  padding: 8px 12px 7px 7px;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(50, 50, 50, 0.05);
  /* color: red; */
min-height: 34px;
}

.custom-datepicker {
  font-size: 14px !important;
  font-weight: 600 !important;

}
 

.input-a input[type='text'] {
  width: 100%;
  border: 0px;
  background: transparent;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: bolder;
  text-transform: capitalize;
  color: black
}

.textarea-a {
  padding: 7px 7px 7px 7px;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(50, 50, 50, 0.05);
  color: #8a8a8a;
}

.textarea-a textarea {
  width: 100%;
  border: 0px;
  resize: none;
  background: transparent;
  font-family: "Raleway";
  font-size: 11px;
  text-transform: uppercase;
}

.srch-tab-left {
  float: left;
  width: 150px;
  position: relative;
}

.srch-tab-right {
  float: right;
  width: 46.5%;
  position: relative;
}

.srch-tab-3c {
  width: 28.6%;
  float: left;
  margin-right: 7%;
}

.srch-tab-3c:nth-child(3n) {
  float: right;
  margin-right: 0px;
}

.srch-tab-3c .custom-select {
  cursor: pointer;
  height: 34px;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(50, 50, 50, 0.05);
  background: url(../public/img/mp-select.png) right top no-repeat;
  font-size: 11px;
  color: #8a8a8a;
}

.srch-tab-3c .custom-select span {
  display: block;
  padding: 10px 7px 9px 7px;
}


.select-wrapper {
  padding-right: 2px;
}

.srch-tab-line {
  margin-bottom: 17px;
}



.srch-tab-line.no-margin-bottom .redpassenger,
.srch-tab-line.no-margin-bottom .infantmore {
  color: red;
  font-size: 12px;
  font-family: 'Raleway';
  display: none
}

.srch-tab-line select {
  cursor: pointer;
}

.srch-tab-line .custom-select {
  text-transform: uppercase;
  cursor: pointer;
  width: 100% !important;

  background: #fff none repeat scroll 0 0;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(50, 50, 50, 0.05);
  color: #8a8a8a;
  background: url(../public/img/mp-select.png) right top no-repeat;
  font-size: 9px;
  color: #8a8a8a;
}

.srch-tab-line .custom-select span {
  display: block;
  width: 90% !important;
  padding: 8px 7px 9px 7px;
}

.select-wrapper {
  position: relative;
}

.search-large-i {
  /* width: 70%; */
  /* float: left; */
}

.search-large-i:nth-child(3n) {
  float: right;
  margin-right: 0px;
}

/* .search-large-i1 {
  width: 80%;
  margin-right: 2.7%;
  float: left;
}

.search-large-i1:nth-child(3n) {
  float: right;
  margin-right: 0px;
} */

.srch-btn:hover {
  background: #644ed4;
  border-color: black;
}

.input-a1 input[type="radio"] {
  display: none;
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}

.input-a1 label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Raleway';
  position: relative;
  vertical-align: middle;
  text-align: center;
  line-height: 20px;
  color: #8a8a8a;
}

.input-a1 label:hover {
  background-color: #f7f2f2;
  color: #fff;
  border: 1px solid #f7f2f2
}

.input-a2 label {
  width: 200px;
  text-transform: Capitalize;
}

.input-a2 label:hover {
  background-color: #f7f2f2;
  color: #fff;
  border: 1px solid #f7f2f2;
}

.input-a1 input[type="radio"]:checked+label::after {
  content: attr(value);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #6c757d;
}

.input-a1 input[type="radio"]+label:hover {
  color: #8a8a8a;
}

.input-a2 input[type="radio"]+label:hover {
  color: #8a8a8a;
}

.input-a1 input[type="radio"]:checked+label {
  background-color: #bd8100;
  border-color: #bd8100;
  color: #fff;
}

.srch-lbl {
  cursor: pointer;
  float: right;
  font-family: 'Raleway';
  font-weight: 500;
  color: #566065;

  font-size: 10px;
  text-transform: uppercase;
}

.mp-offesr {
  font-family: 'Raleway';
  padding: 70px 0px 0px 0px;
}

.mp-offesr.no-margin {
  padding-top: 0px;
}

.mp-pop {
  padding: 20px 0px 0px 0px;
}

.offer-slider {
  margin-bottom: 95px;
}

.offer-slider-lead {
  margin-top: 9px;
  font-size: 13px;
  color: #455051;
  line-height: 20px;
  font-family: 'Raleway';
}

.offer-slider header {
  text-align: center;
  margin-bottom: 32px;
}

.modal-backdrop.show {
  opacity: 0;
}


.select-wrapper1 input[type="radio"] {
  display: none;
  background-color: #e3e3e3;
  border-color: #e3e3e3;
  font-size: 13px;
}

.select-wrapper1 label {
  display: inline-block;
    width: 35px; /* Adjusted width */
    height: 35px; /* Adjusted height */
    background-color: #fff;
    border: 1px solid #ced4da;

    cursor: pointer;
    font-weight: 500;
    font-family: 'Open Sans';
    position: relative;
    vertical-align: middle;
    text-align: center;
    line-height: 35px; /* Center the text */
    color: #8a8a8a;
    transition: all 0.3s;
}

/* .select-wrapper1 label:hover {
  background: #f7f2f2;
  color: black;
  border: 1px solid #f7f2f2;
} */
.select-wrapper1 input[type="radio"] + label:hover {
  background: #644ed4;
  border-color: #f7f2f2;
  color: black;
}

.select-wrapper2 label {
  width: 200px;
    text-transform: capitalize;
    font-size: 13px;
    height: 30px;
}

.select-wrapper2 label:hover {
  background: #f7f2f2;
  border: 1px solid #f7f2f2;
}

.select-wrapper1 input[type="radio"]:checked+label::after {
  content: attr(value);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
}

.select-wrapper1 input[type="radio"]:checked+label {
  background-color: #785eff;
  border-color: #785eff;
  color: #fff;
}

.select-wrapper1 input[type="radio"]:checked+label:hover {
  background: #644ed4;
  border-color: #644ed4;
  color: #fff;
}

.select-wrapper1 input[type="radio"]+label:hover {
  color: #fff;
}

.select-wrapper2 input[type="radio"]+label:hover {
  color: #fff;
}
.row-container {
  display: flex;
  justify-content: space-between; /* Add space between the two divs */
  align-items: flex-start; /* Align items at the top */
  gap: 10px; /* Optional: Add space between the divs */
}

.mp-popular {
  margin-bottom: 95px;
}

.mp-popular header,
header.page-lbl {
  text-align: center;
  margin-bottom: 31px;
}

.mp-popular header b,
.page-lbl b,
.offer-slider-lbl {
  font-size: 23px;
  color: #141d1e;
  display: block;
  font-family: 'Montserrat';
  text-transform: uppercase;

  font-weight: normal;
  margin-bottom: 12px;
  padding: 0px 0px 16px 0px;
  background: url(../public/img/pop-hdr.png) center bottom no-repeat;
}

.mp-popular header p,
.page-lbl p {
  font-family: Raleway;
  line-height: 26px;
  font-size: 15px;
  color: #455051;
}

.offer-slider-lbl {
  font-size: 23px;
  color: #141d1e;
  font-family: 'Montserrat';
  text-transform: uppercase;

}

.offer-slider header p {
  font-family: 'Lora';
  line-height: 26px;
  font-size: 15px;
  font-style: italic;
  color: #455051;
  display: inline-block;
  width: 640px;
}

.mp-popular header p,
.page-lbl p {
  font-style: italic;
  display: inline-block;
  width: 640px;
  font-family: 'Lora';
}

.offer-slider-i {

  margin-right: 15px;
}

.offer-slider-i.catalog-i {
  width: 32%;
  float: left;
  margin: 0px 2% 2% 0px;
  font-family: 'Raleway';
}

.offer-slider-i.catalog-i:nth-child(3n) {
  margin-right: 0px;
  float: right;
}

.offer-slider-i.catalog-i .cat-list-btn {
  margin-bottom: 5px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 10px;
  border-radius: 3px;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: 15px;
  background: #fff;
  border: 1px solid #e5e5e5;
  color: #838383;
}

.offer-slider-i.catalog-i .cat-list-btn:hover {
  color: #bd8100;
}

.catalog-i .offer-slider-l {
  width: auto;
  margin-top: 1px;
}

.catalog-i .offer-slider-devider {
  margin-right: 15px;
}

.offer-slider-img img {
  width: 100%;
}

.catalog-head {
  background: #fff;
  padding: 9px 17px 9px 17px;
  margin-bottom: 25px;
}

.catalog-head label {
  font-family: 'Raleway';
  float: left;
  margin: 9px 15px 0px 0px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  color: #3a4142;
}

.catalog-head .search-select {
  float: left;
  width: 89px;
  margin-top: 1px;
  margin-right: 10px;
  border: 1px solid #dedcdc;
}

.catalog-head .search-select select {
  /* background-image: url("../public/img/select-bg2.png"); */
  padding-top: 9px;
  padding-bottom: 8px;
  font-size: 9px;
  color: #8a8a8a;
  background-position: 80% center;
  background-repeat: no-repeat;
}

.show-thumbs,
.show-table,
.show-list {
  margin-left: 5px;
  display: block;
  width: 29px;
  height: 29px;
  float: right;
}

.show-thumbs {
  background: url(../public/img/sort-a.png) left top no-repeat;
}

.show-table {
  background: url(../public/img/sort-b.png) left top no-repeat;
}

.show-list {
  background: url(../public/img/sort-c.png) left top no-repeat;
}

.show-thumbs.chosen,
.show-table.chosen,
.show-list.chosen {
  background-position: left -29px;
}

.cat-list-item-h {
  width: 100%;
  float: left;
}

.cat-list-item-l {
  float: left;
  margin: 0px;
  padding: 0px;
  line-height: 29px;
  overflow: hidden;
}

.forbusl {
  width: 1px;
}

.cat-list-item-l .headbusl {
  font-size: 20px;
  color: #bd8100;
  font-weight: 600;
  font-family: "Montserrat";
  text-transform: uppercase;
}

.cat-list-item-l img {
  width: 100%;
}

.cat-list-item-l .container {
  padding: 0;
}


.cat-list-item-l .container {
  position: relative;
}


.cat-list-item-l .mySlides {
  display: none;
}


.cat-list-item-l .cursor {
  cursor: pointer;
}


.cat-list-item-l .prev,
.cat-list-item-l .next {
  cursor: pointer;
  position: absolute;
  top: 56%;
  width: auto;
  padding: 12px;
  text-decoration: none;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}


.cat-list-item-l .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}


.cat-list-item-l .prev:hover,
.cat-list-item-l .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


.cat-list-item-l .numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}


.cat-list-item-l .caption-container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}

.cat-list-item-l .row:after {
  content: "";
  display: table;
  clear: both;
}


.cat-list-item-l .column {
  float: left;
  width: 16.66%;
}

.cat-list-item-l .demo {
  opacity: 0.6;
}

.cat-list-item-l .active,
.cat-list-item-l .demo:hover {
  opacity: 1;
}

.cat-list-item-r {
  margin: 0px 0px 0px 307px;
}

.forbusr {
  margin: 0px 0px 0px 1px;
}

.cat-list-item-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.cat-list-item-p {
  padding: 0px 18px 0px 18px;
}

.cat-list-item {
  background: #fff;
  overflow: hidden;
  margin: 0px 0px 20px 0px;
}

.cat-list-content-a {
  width: 100%;
  float: left;
}

.cat-list-content-l {
  margin: 0px 125px 0px 0px;
}

.cat-list-content-lb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.cat-list-content-lpadding {
  padding: 18px 30px 7px 0px;
  min-height: 156px;
  border-right: 1px solid #ede7e1;
}

.cat-list-content-r {
  width: 124px;
  float: left;
  overflow: hidden;
  margin: 0 0 0 -125px;
}

.cat-list-content-p {
  padding: 29px 0px 17px 18px;
}

.cat-list-item .offer-slider-link {
  font-family: 'Raleway';
  color: #252525;
  margin-bottom: 8px;
}

.cat-list-item .offer-slider-location {
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0px;
  color: black;

  font-weight: 600;
  text-transform: capitalize;
}

.cat-list-content-l p {
  font-size: 13px;
  line-height: 21px;
  margin: 13px 0px 14px 0px;
  color: #455051;
}

.cat-icon-01 {
  display: block;
  float: left;
  width: 15px;
  height: 13px;
  background: url(../public/img/cat-icon-01.png) left top no-repeat;
}

.cat-icon-02 {
  display: block;
  float: left;
  width: 9px;
  height: 13px;
  background: url(../public/img/cat-icon-02.png) left top no-repeat;
}

.cat-icon-03 {
  display: block;
  float: left;
  width: 11px;
  height: 13px;
  background: url(../public/img/cat-icon-03.png) left top no-repeat;
}

.cat-icon-04 {
  display: block;
  float: left;
  width: 11px;
  height: 13px;
  background: url(../public/img/cat-icon-04.png) left top no-repeat;
}

.cat-icon-05 {
  display: block;
  float: left;
  width: 11px;
  height: 13px;
  background: url(../public/img/cat-icon-05.png) left top no-repeat;
}

.cat-icon-06 {
  display: block;
  float: left;
  width: 7px;
  height: 13px;
  background: url(../public/img/cat-icon-06.png) left top no-repeat;
}

.cat-icons span.active {
  background-position: left -13px;
}

.cat-icons span {
  margin-right: 13px;
}

.cat-list-review {
  font-size: 10px;
  margin-top: 17px;
  margin-bottom: 17px;
  color: black;
  font-weight: 500;
  text-transform: capitalize;
}

.cat-list-review .review {
  padding: 3px;
  background: #bd8100;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
}

.cat-list-review .frombus {
  /* padding: 3px; */
  /* background: #bd8100; */
  font-size: 14px;
  font-weight: 600;
  color: #bd8100;
  border-radius: 4px;
}

.cat-list-review .review img {
  width: 15px;
  margin-top: -5px;
}

.cat-list-item .offer-slider-r {
  float: none;
  margin-bottom: 18px;
}

.cat-list-item .stars {
  margin-bottom: 7px;
}

.cat_list_btn {
  display: inline-block;
  font-family: 'Montserrat';
  font-weight: normal;
  background: #bd8100;
  padding: 9px 15px 9px 15px;
  border-radius: 3px;
  border: 1px solid #bd8100;
  text-decoration: none;
  color: #fff;
  font-size: 11px;

  text-transform: uppercase;
}

.cat_list_btn:hover {
  background-color: #fff;
  color: #bd8100;
}

.cat-list-item .cat-list-btn {
  padding-left: 26px;
  padding-right: 26px;
}

.cat-list-content-l .offer-slider-link {
  margin-bottom: 8px;
}

.cat-list-item-rb .offer-slider-r b {
  font-weight: 600 !important;
  margin-bottom: 7px;
}

.cat-list-item-rb .offer-slider-r span {
  font-size: 10px;
}

.cat-list-item-rb .cat-list-btn {
  border: 1px solid #bd8100;
  border-radius: 3px;
  font-size: 10px;
  padding-bottom: 9px;
  padding-top: 9px;
  font-family: 'Montserrat';
  color: white;
  font-weight: normal;
}

.cat-list-item-rb .cat-list-btn:hover {
  color: #bd8100;
}

.cat-list-item-rb .offer-slider-link a {
  font-size: 20px;
  color: #bd8100;
  font-weight: 600;
}

.offer-slider-txt {
  padding: 20px 13px 21px 13px;
  background: #fff;
}

.offer-slider-img {
  text-decoration: none;
  display: block;
  margin: 0px;
  padding: 0px;
  line-height: 0px;
  width: 100%;
  height: 100%;
  position: relative;
}

.offer-slider-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background: url(../public/img/offer-over.png) left top repeat;
}

.offer-slider-btn {
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  width: 104px;
  height: 23px;
  padding-top: 6px;
  text-align: center;
  line-height: 18px;
  position: absolute;
  margin-left: -52px;
  margin-top: -15px;
  left: 50%;
  top: -200px;

}

.last-order {
  height: 353px;
  padding: 147px 0px 0px 0px;
  margin-bottom: 40px;
}

.last-order-content {
  text-align: center;
}

.last-order-a {
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: normal;
  color: #fff;
  margin-bottom: 19px;
  text-transform: uppercase;
}

.last-order-b {
  font-size: 23px;
  color: #fff;
  margin-bottom: 18px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: normal;
}

.last-order-c {
  font-size: 14px;
  color: #fff;
  margin-bottom: 17px;
  font-family: 'Lola';
  font-weight: normal;
  font-style: italic;
}

.last-order-d {
  margin-bottom: 22px;
  font-family: 'Montserrat';
  font-weight: normal;
}

.last-order-btn {
  font-family: 'Montserrat';
  font-weight: narmal;
  border: 2px solid #fff;
  display: inline-block;
  font-size: 11px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 11px 26px 11px 26px;
}

.last-order-btn:hover {
  background: #fff;
  color: #303b3c;
}

.duble-margin {
  margin-bottom: 100px;
}

.partners {
  text-align: center;
  border-bottom: 1px solid #f0e9e4;
  padding-bottom: 30px;
  margin-bottom: 94px;
}

.partners a {
  margin: 0px 28px 0px 28px;
}

.partners a:first-child {
  margin-left: 0px;
}

.partners a:last-child {
  margin-right: 0px;
}

.testimonials {
  background: #fff;
  padding: 93px 0px 96px 0px;
}

.testimonials-lbl {
  text-align: center;
  font-size: 23px;
  margin-bottom: 16px;
  color: #141d1e;
  padding-bottom: 16px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  background: url("../public/img/pop-hdr.png") center bottom no-repeat;

}

.testimonials-lbl-a {
  text-align: center;
  font-size: 15px;
  margin-bottom: 36px;
  color: #455051;
  font-style: italic;
  font-family: 'Lora';
}

.testimonials-i {
  text-align: center;
}

.testimonials-a {
  margin-bottom: 17px;
}

.testimonials-b {
  margin-bottom: 15px;
  font-size: 15px;
  color: #455051;
  font-family: 'Raleway';
  line-height: 26px;
}

.testimonials-c {
  margin-bottom: 18px;
}

.testimonials-c li {
  display: inline-block;
}

.testimonials-d {
  font-family: 'Raleway';
  font-size: 11px;
  color: #454545;
  font-weight: 500;
  text-transform: uppercase;
}

.testimonials-holder {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
}

.testimonials-holder .bx-pager {
  display: none;
}

.testimonials-holder .bx-prev,
.testimonials-holder .bx-next {
  display: block;
  width: 11px;
  height: 21px;
  overflow: hidden;
  text-indent: -9999px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
}

.testimonials-holder .bx-prev {
  background: url(../public/img/test-l.gif) left top no-repeat;
  left: 0px;
}

.testimonials-holder .bx-next {
  background: url(../public/img/test-r.gif) left top no-repeat;
  right: 0px;
}

.testimonials-holder .bx-prev:hover,
.testimonials-holder .bx-next:hover {
  background-position: left -21px;
}

.last-order-d {
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 600;
  color: #bd8100;
}


.mp-popular-row {
  max-width: 1100px;
  margin: 0 auto;
}

.mp-popular-row .offer-slider-i {
  float: left;
  width: 346px;
  margin-right: 31px;
}

.mp-popular-row .offer-slider-i:last-child {
  margin-right: 0px;
}


.offer-slider-link {
  margin-bottom: 12px;
}

.offer-slider-link a {
  font-size: 14px;
  color: #4c4c4c;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
  font-family: "Montserrat";

}

.offer-slider-l {
  width: 151px;
  float: left;
}

.offer-slider-location {
  margin-bottom: 7px;
  font-size: 10px;
  color: #747d7e;
  font-weight: 600;
  text-transform: uppercase;
}

.stars ul,
.stars ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.stars ul li {
  float: left;
  margin-right: 1px;
}

.popular-slider {
  margin-bottom: 90px;
}

.offer-slider-r {
  float: right;
  margin: 2px 0px 0px 0px;
}

.offer-slider-r b {
  font-family: 'Montserrat';
  font-weight: normal;
  letter-spacing: -0.03em;
  display: block;
  font-size: 20px;
  margin-bottom: 6px;
  color: #bd8100;
  line-height: 15px;
}

.offer-slider-devider {
  width: 2px;
  margin: 0px 12px 0px 0px;
  height: 33px;
  background: #ede7e1;
  float: right;
}

.offer-slider-r .avg {
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
  color: #939393;
}

.offer-slider-r .avg {
  float: right;
  margin-top: 6px;
}

.offer-slider-c {
  padding: 0px 45px 0px 45px;
  position: relative;
}

.offer-slider-c .bx-pager {
  display: none;
}

.offer-slider-c .owl-next,
.offer-slider-c .owl-prev {
  display: none !important;
  position: absolute;
  width: 15px;
  height: 29px;
  margin-top: -16px;
  top: 50%;
  overflow: hidden;
  text-indent: -9999px;
}

.offer-slider-c .owl-prev {
  background: url(../public/img/offers-l.png) left top no-repeat;
  left: -45px;
}

.offer-slider-c .owl-next {
  background: url(../public/img/offers-r.png) left top no-repeat;
  right: -45px;
}

.offer-slider-c .owl-next:hover,
.offer-slider-c .owl-prev:hover {
  background-position: left -29px;
}

.mp-b {
  margin-top: 100px;
  padding-bottom: 95px;
  background: #ffffff;
  padding-top: 75px;
}

.mp-b-left {
  width: 357px;
  float: left;
}

.mp-b-right {
  width: 723px;
  float: right;
}

.mp-b-lbl {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 18px;
  text-transform: uppercase;
  color: #141d1e;
  margin-bottom: 32px;
}

nav ul,
nav ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.regions {
  margin-bottom: 12px;
}

.regions-holder {
  width: 347px;
  height: 177px;
  position: relative;
}

.regions-holder .asia,
.regions-holder .africa,
.regions-holder .austalia,
.regions-holder .europe,
.regions-holder .north-america,
.regions-holder .south-america {
  width: 347px;
  height: 177px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.regions-holder .asia {
  /* background: url(../public/img/asia.png) left top no-repeat; */
}

.regions-holder .africa {
  /* background: url(../public/img/africa.png) left top no-repeat; */
}

.regions-holder .austalia {
  /* background: url(../public/img/australia.png) left top no-repeat; */
}

.regions-holder .europe {
  /* background: url(../public/img/europe.png) left top no-repeat; */
}

.regions-holder .north-america {
  /* background: url(../public/img/north-america.png) left top no-repeat; */
}

.regions-holder .south-america {
  /* background: url(../public/img/south-america.png) left top no-repeat; */
}

.regions-holder area {
  cursor: pointer;
}

.regions-holder map {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 9999;
}

.regions-nav ul li {
  display: inline-block;
  background: url(../public/img/regions-nav.png) right top no-repeat;
  padding: 0px 9px 4px 0px;
  margin: 0px 0px 0px 2px;
  background-position: right 7px;
}

.regions-nav ul li a.chosen {
  color: #bd8100;
}

.regions-nav ul li:first-child {
  margin-left: 0px;
}

.regions-nav ul li:nth-child(4n),
.regions-nav ul li:last-child {
  background: transparent;
}

.regions-nav ul li:nth-child(4n) {
  margin-right: 12px;
}

.regions-nav ul li:nth-child(5n) {
  margin-left: 0px;
}

.regions-nav ul li a {
  font-family: 'Raleway';
  color: #3a4142;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
}

.regions-nav ul li a:hover {
  color: #bd8100;
}

.reasons-item-a {
  width: 286px;
  float: left;
  background: url(../public/img/reasons-01.png) left top no-repeat;
  padding: 6px 0px 0px 63px;
  margin: 0px 0px 26px 0px;
}

.reasons-item-b {
  width: 286px;
  float: right;
  background: url(../public/img/reasons-02.png) left top no-repeat;
  padding: 6px 0px 0px 63px;
  margin: 0px 0px 26px 0px;
}

.reasons-item-c {
  width: 286px;
  float: left;
  background: url(../public/img/reasons-03.png) left top no-repeat;
  padding: 6px 0px 0px 63px;
}

.reasons-item-d {
  width: 286px;
  float: right;
  background: url(../public/img/reasons-04.png) left top no-repeat;
  padding: 6px 0px 0px 63px;
}

.reasons-lbl {
  font-size: 14px;
  color: #3a4142;
  background: url(../public/img/reasons-lbl.gif) left bottom no-repeat;
  font-family: 'Montserrat';
  font-weight: normal;
  margin-bottom: 10px;
  padding: 0px 0px 14px 0px;
  text-transform: uppercase;
}

.reasons-txt {
  font-family: 'Raleway';
  color: #626262;
  line-height: 25px;
}

.advantages-row {
  text-align: center;
}

.advantages-i {
  display: inline-block;
  text-align: center;
  margin: 18px 31px 0px 31px;
  width: 315px;
}

.advantages-a {
  margin-bottom: 19px;
}
.advantages-a img{
  width: 65px;
}
.advantages-b {
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 14px;
}

.advantages-c {
  font-size: 13px;
  font-family: 'Raleway';
  line-height: 25px;
  color: #626262;
}

.footer-a {
  color: #fff;
  background: #141d1e;
  padding: 56px 100px 31px 100px;
}

.footer-a .section {
  width: 281px;
  float: left;
  display: block;
}

.footer-a .section:last-child {
  float: right;
  width: 254px;
}

.footer-lbl {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 12px;
  font-family: 'Montserrat';
  font-weight: normal;
  background: url(../public/img/foot-lbl.gif) left bottom no-repeat;
  padding: 0px 0px 18px 0px;
  text-transform: uppercase;
}

.footer-adress {
  font-family: 'Raleway';
  font-size: 13px;
  line-height: 33px;
  color: #f7f7f7;
  background: url(../public/img/footer-icon-01.png) left top no-repeat;
  padding: 0px 0px 0px 22px;
  background-position: left 9px;
  margin: 0px 0px 9px 0px;
}

.footer-phones {
  font-family: 'Raleway';
  font-size: 13px;
  color: #f7f7f7;
  background: url(../public/img/footer-icon-02.png) left top no-repeat;
  padding: 0px 0px 0px 22px;
  margin: 0px 0px 17px 0px;
  background-position: left 1px;
}

.footer-phones a[href^=tel] {
  color: #fff;
  text-decoration: none;
}

.footer-email {
  font-family: 'Raleway';
  font-size: 13px;
  color: #f7f7f7;
  margin: 0px 0px 17px 0px;
  background: url(../public/img/footer-icon-03.png) left top no-repeat;
  padding: 0px 0px 0px 22px;
  background-position: left 2px;
}

.footer-skype {
  font-family: 'Raleway';
  font-size: 13px;
  color: #f7f7f7;
  background: url(../public/img/footer-icon-04.png) left top no-repeat;
  padding: 0px 0px 0px 22px;
  background-position: left 2px;
}

.footer-tour {
  width: 218px;
  padding-bottom: 13px;
  font-size: 12px;
  font-family: 'Raleway';
}

.footer-tours {
  margin-top: 19px;
}

.footer-tour-l {
  float: left;
  margin-top: 3px;
  width: 84px;
}

.footer-tour-l img {
  width: 100%;
}

.footer-tour-r {
  float: right;
  width: 118px;
}

.footer-tour-a {
  margin-bottom: 8px;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: normal;
  letter-spacing: -0.03em;
}

.footer-tour-b {
  font-family: 'Raleway';
  font-weight: 600;
  margin-bottom: 9px;
  font-size: 9px;
  color: #a2a2a2;
  text-transform: uppercase;
}

.footer-tour-c {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 17px;
  color: #bd8100;
  text-transform: uppercase;
}

.tweet {
  margin-bottom: 17px;
  background: url(../public/img/icon-tweet.png) left top no-repeat;
  padding: 0px 0px 0px 25px;
  font-size: 13px;
  background-position: left 7px;
  color: #f7f7f7;
  width: 240px;
  line-height: 26px;
  font-family: 'Raleway';
}

.tweet-date {
  font-size: 12px;
  font-family: 'Raleway';
  color: #b7b7b7;
  font-weight: 300;
}

.tweet a {
  color: #f7f7f7;
  text-decoration: none;
}

.twitter-follow {
  margin-top: 24px;
  font-size: 10px;
  font-weight: 600;
  font-family: 'Raleway';
  color: #fff;
  text-transform: uppercase;
}

.twitter-follow a {
  color: #fff;
  text-decoration: none;
}

.footer-subscribe {
  width: 243px;
  height: 27px;
  margin: 22px 0px 11px 0px;
  padding: 7px 0px 0px 10px;
  background: #2c3536;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.footer-subscribe-a {
  background: url(../public/img/icon-subscribe.png) left top no-repeat;
  min-height: 11px;
  background-position: left 4px;
  padding: 0px 0px 0px 21px;
}

.footer-subscribe-a input {
  background: transparent;
  width: 204px;
  border: 0px;
  font-family: 'Raleway';
  color: #525f60;
  font-size: 13px;
}

.footer-subscribe-btn {
  display: block;
  border: 0px;
  cursor: pointer;
  width: 85px;
  height: 32px;
  padding-bottom: 1px;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: normal;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  background: #785eff;
  font-size: 11px;
  text-transform: uppercase;
}

.footer-subscribe-btn:hover {
  background: #644ed4;
}

.footer-b {
  height: 52px;
  background: #000000;
  color: #fff;
}

.footer-left {
  font-size: 10px;
  color: #fff;
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
  float: left;
  margin: 19px 0px 0px 0px;
  letter-spacing: -0.03em;
}

.footer-social {
  float: right;
  margin: 19px 0px 0px 0px;
}

.footer-social a {
  float: left;
  display: block;
  margin: 0px 10px 0px 10px;
  width: 14px;
  height: 14px;
}

.footer-social a:last-child {
  margin-right: 0px;
}

.footer-social a.footer-twitter {
  background: url(../public/img/footer-twitter.png) left top no-repeat;
}

.footer-social a.footer-facebook {
  background: url(../public/img/footer-facebook.png) left top no-repeat;
}

.footer-social a.footer-vimeo {
  background: url(../public/img/footer-vimeo.png) left top no-repeat;
}

.footer-social a.footer-pinterest {
  background: url(../public/img/footer-pinterest.png) left top no-repeat;
}

.footer-social a.footer-instagram {
  background: url(../public/img/footer-instagram.png) left top no-repeat;
}

.footer-social a:hover {
  background-position: left -14px;
}

.advantages-row.flat {
  text-align: left;
  margin-top: 39px;
}

.flat-adv-a {
  width: 100%;
  float: left;
}

.flat-adv-l {
  width: 80px;
  margin-top: 3px;
  float: left;
}

.flat-adv-r {
  margin: 0px 0px 0px 80px;
}

.flat-adv-rb {
  width: 100%;
  float: left;
}

.flat-adv {
  width: 48%;
  float: left;
  margin-bottom: 29px;
}

.flat-adv:nth-child(2n) {
  width: 48%;
  float: right;
}

.flat-adv:nth-child(3n),
.flat-adv:nth-child(4n) {
  margin-bottom: 0px;
}

.flat-adv.large .flat-adv-l {
  width: 127px;
  margin-top: 3px;
  float: left;
}

.flat-adv.large .flat-adv-r {
  margin: 0px 0px 0px 127px;
}

.flat-adv.large {
  margin-bottom: 45px;
}

.flat-adv-b {
  font-size: 14px;
  color: #3a4142;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: normal;
  margin-bottom: 14px;
}

.flat-adv-c {
  font-size: 14px;
  color: #626262;
  line-height: 26px;
  font-family: 'Raleway';
}

.testimonials-flat {
  padding: 150px 0px 0px 0px;
  width: 100%;
  height: 350px;
  margin: 0px 0px 93px 0px;
  /* background: url(../public/img/testimonials-s.jpg) center top no-repeat; */
}

.testimonials-flat.about-ts {
  /* background: url(../public/img/about-ts.jpg) left top no-repeat; */
}

.testimonials-flat .testimonials-b,
.testimonials-flat .testimonials-d {
  color: #fff;
}

.testimonials-flat .testimonials-holder .bx-prev {
  background: url(../public/img/ss-l.png) left top no-repeat;
  height: 21px;
  width: 12px;
}

.testimonials-flat .testimonials-holder .bx-next {
  background: url(../public/img/ss-r.png) left top no-repeat;
  height: 21px;
  width: 12px;
}

.testimonials-flat .testimonials-holder .bx-prev:hover,
.testimonials-flat .testimonials-holder .bx-next:hover {
  background-position: left -21px;
}

.inform-block {
  padding: 0px 0px 60px 0px;
}

.flat-adv-btn {
  display: inline-block;
  margin-top: 17px;
  font-size: 11px;
  color: #30353a;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: normal;
  border: 2px solid #e6e6e6;
  border-radius: 3px;
  padding: 10px 19px 10px 19px;
}

.flat-adv-btn:hover {
  color: #bd8100;
}

.pop-destinations {
  background: #ffffff;
  padding: 92px 20px 61px 20px;
}

.pop-destinations .offer-slider-i,
.offer-slider-c {
  font-family: 'Raleway';
  font-weight: 500;
}

.pop-destinations .offer-slider-i {
  margin-bottom: 13px;
}

.pop-destinations .offer-slider-i:nth-child(3n) {
  margin-right: 0px;
}

.pop-destinations .offer-slider-txt {
  padding-left: 0px;
  padding-right: 0px;
}

.partners-wrapper {
  padding: 94px 0px 0px 0px;
}

.partners-wrapper .partners {
  margin-top: 32px;
  margin-bottom: 66px;
  border: 0px;
}

.partners-wrapper.no-margin {
  padding-top: 0px;
}

.partners-wrapper.no-margin .partners {
  margin-bottom: 33px;
}

.travel_experience {
  background: #fff;
  text-align: center;
  margin-top: 105px;
  padding: 92px 0px 92px 0px;
}

.travel_experience.mp-popular {
  margin-bottom: 0px;
}

.t-experience-i {
  display: inline-block;
  margin: 0px 29px 0px 29px;
  width: 325px;
  text-align: center;
  font-family: 'Raleway';
}

.t-experience-i:first-child {
  margin-left: 0px;
}

.t-experience-i:last-child {
  margin-right: 0px;
}

.t-experience-a {
  margin-bottom: 22px;
}

.t-experience-b {
  font-size: 14px;
  font-family: 'Montserrat';
  margin-bottom: 13px;
  color: #3a4142;
  font-weight: normal;
  text-transform: uppercase;
}

.t-experience-c {
  font-size: 14px;
  color: #626262;
  line-height: 24px;
}

.theme-teaser {
  width: 100%;
  height: 500px;
  margin-bottom: 93px;
  background: url(../public/img/theme-teaser.jpg) center top no-repeat;
}

.theme-teaser .wrapper-padding {
  border-collapse: collapse;
  display: table;
  width: 100%;
}

.theme-teaser-l {
  width: 40%;
  height: 500px;
  padding: 0px 20px 0px 0px;
  vertical-align: middle;
  display: table-cell;
}

.theme-teaser-r {
  width: 60%;
  height: 500px;
  padding: 0px;
  line-height: 0px;
  text-align: right;
  vertical-align: bottom;
  display: table-cell;
}

.theme-teaser-a {
  color: black;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 7px;
  font-family: 'Montserrat';
  line-height: 36px;
  text-transform: uppercase;
}

.theme-teaser-b {
  margin-bottom: 18px;
  font-size: 15px;
  color: black;
  line-height: 25px;
  font-family: 'Raleway';
}

.theme-teaser-c {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: normal;
  background: #785eff;
  text-decoration: none;
  border-radius: 2px;
  padding: 12px 36px 12px 36px;
  color: white;
}

.theme-teaser-c:hover {
  background: #644ed4;
  color: white;
}

.theme-teaser-padding {
  padding: 0px 60px 0px 60px;
}

.pagination a {
  display: inline-block;
  background: #fff;
  text-decoration: none;
  text-align: center;
  float: left;
  margin-right: 6px;
  width: 34px;
  height: 26px;
  padding-top: 8px;
  font-size: 13px;
  font-family: 'Open Sans';
  color: #737778;
}

.pagination a:hover,
.pagination a.active {
  color: #bd8100;
}

.two-colls {
  width: 100%;
  float: left;
  /* display: flex; */
  align-items: flex-start;
  
}

.two-colls1 {
  width: 100%;
  float: left;
}

.two-colls2 {
  width: 100%;
  float: left;
}

.two-colls-left {
  width: 346px;
  float: left;
  position: sticky;
  top: 0;
  height: auto;
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.two-colls-left::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.two-colls-left1 {
  margin-left: 10px;
  width: 474px;
  float: left;
  overflow: hidden;
}

.two-colls-left2 {
  margin-left: 10px;
  width: 972px;
  float: right;
  overflow: hidden;
}

.two-colls-right {
  margin: 0px 0px 0px 346px;

}


.two-colls-right1 {
  margin: 0px 0px 0px 479px;
}

.two-colls-right2 {
  margin: 0px 0px 0px 0px;
}

.two-colls-right-b {
  width: 100%;
  float: left;
  /* overflow: hidden; */
  /* overflow-y: auto; */
}

.two-colls-right .padding {
  padding: 0px 0px 0px 26px;
}

.two-colls-right1 .padding {
  padding: 0px 0px 0px 26px;
}

.two-colls-right2 .padding {
  padding: 0px 0px 0px 0px;
}

.page-title {
  float: left;
  font-family: 'Montserrat';
  font-size: 15px;
  text-transform: uppercase;
  color: #141d1e;
  font-weight: normal;
}

.page-title span {
  color: #788687;
}

.breadcrumbs {
  float: right;
  text-transform: uppercase;
  font-size: 11px;
  color: #828282;
  font-family: 'Raleway';
  font-weight: 600;
}

.breadcrumbs a {
  text-decoration: none;
  margin: 0px 7px 0px 7px;
  color: #828282;
}

.breadcrumbs span {
  color: #4a90a4;
  margin: 0px 0px 0px 7px;
}

/* .page-head {padding-bottom: 30px;} */
.srch-results-lbl {
  background: #fff;
  margin-bottom: 25px;
  padding: 16px 18px 16px 18px;
  font-size: 13px;
  color: #3a4142;
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
}

.srch-results-lbl span {
  display: block;
  height: 15px;
  background: url(../public/img/result-icon.png) left top no-repeat;
  background-position: left 2px;
  padding: 0px 0px 0px 28px;
}

.side-block-search input[type='text'] {
  font-size: 11px;
}

.side-block-search .search-select {
  border: 1px solid #dedcdc;
  width: 89px;
}

.side-block-search .search-line-select {
  width: 89px;
}

.side-block-search .search-line input[type='text'] {
  width: 294px;
}

.side-block-search .search-select.half-width {
  border: 1px solid #dedcdc;
  width: 145px;
}

.side-block {
  
  background: #fff;
  margin-bottom: 10px;
}

.side-block .side-price {
  padding: 10px 18px 20px 10px;
}

.side-block .side-price .price-ammounts .price-ammountsp .price-ammountsinput {
  border: 0;
  color: #f6931f;
  font-weight: bold;

}

.side-block .page-search-p {
  padding: 10px 18px 1px 18px;
}

.side-block .page-search-p #error-message1,
.side-block .page-search-p #error-message2 {
  color: red;
  margin-left: 2%;
  font-family: 'Raleway';
  font-size: 13px;
}

.side-block .page-search-p label {
  font-size: 11px;
  color: #4c4c4c;
  text-transform: capitalize;
  font-family: 'Montserrat';
  font-weight: normal;
  margin-bottom: 9px;
}

.side-block .search-line-a {
  width: 144px;
}

.side-block .search-line-b {
  width: 145px;
}

.side-block .search-line-a input {
  width: 129px;
}

.side-block .search-line-b input {
  width: 129px;
}

.side-padding {
  padding: 21px 18px 20px 18px;
}
/* .airline-label {
  display: flex;
  align-items: center;
  gap: 8px; 
  font-size: 14px;
  color: #333; 
} */

.airline-logo {
  width: 17px; /* Adjust size as needed */
  height: 17px;
  object-fit: contain;
  border-radius: 3px; /* Optional: To make logos look uniform */
}

.side-lbl {
  font-size: 13px;
  margin-bottom: 10px;
  color: #3a4142;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: normal;
}

.price-ammountsp {
  /* margin: 14px 0px 0px 0px; */
  margin-bottom: 0px;
}

.side-block .ui-slider-horizontal {
  height: 3px !important;
  border: 0px !important;
  background: #d9e0e2 !important;
  border-radius: 100px !important;
}

.side-block .ui-widget-header {
  background: #4a90a4 !important;
}

.side-block .ui-state-default,
.side-block .ui-widget-content .ui-state-default,
.side-block .ui-widget-header .ui-state-default,
.side-block .ui-widget-header .ui-state-focus {
  background: #fff !important;
  border-radius: 100px !important;
  border: 1px solid #c2c5c8 !important;
  cursor: pointer !important;
  top: -8px;
  width: 17px;
  height: 17px;
}

.price-ammounts input[type='text'] {
  width: 100px;
  font-size: 12px;
  color: #666666;
  font-family: 'Open Sans';
  border: 0px;
  background: #fff;
}

#ammount-to {
  float: right;
  text-align: right;
}

.price-ranger {
  margin-top: 0px;
  margin-left: 7px;
  margin-right: 4px;
}

.star-rating-l {
  margin: 1px 0px 0px 0px;
  font-size: 11px;
  color: #848484;
  font-family: 'Raleway';
  font-weight: 600;
  float: left;
}

.star-rating-r {
  margin-left: 8px;
  float: left;
}

.star-rating-r a {
  margin-right: 3px;
  float: left;
}

.side-block .jq-checkbox {
  width: 15px;
  margin: 0px 5px 0px 0px;
  background: #fff;
  box-shadow: none;
  border: 1px solid #dedcdc;
  border-radius: 2px;
  height: 15px;
  position: relative;
}

.side-block .jq-checkbox.checked span {
  width: 10px;
  height: 8px;
  left: 2px;
  bottom: 4px;
  margin: 0px;
  box-shadow: none;
  background: transparent url(../public/img/checkbox.png) left top no-repeat;
  position: absolute;
}

.side-block .checkbox {
  margin-bottom: 18px;
  height: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.side-block .checkbox label {
  display: block;
  margin-left: 7px;
  font-family: 'Open Sans';
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 11px;
  color: #848484;
  gap: 10px;
  display: flex;
}

.side-block .checkbox label.active {
  color: #4a90a4;
}

.side-block .checkbox:last-child {
  margin-bottom: 5px;
}

.checkbox .jq-checkbox {
  width: 15px;
  margin: 0px 5px 0px 0px;
  background: #fff;
  box-shadow: none;
  border: 1px solid #dedcdc;
  border-radius: 2px;
  height: 15px;
  position: relative;
}

.checkbox .jq-checkbox.checked span {
  width: 10px;
  height: 8px;
  left: 2px;
  bottom: 4px;
  margin: 0px;
  box-shadow: none;
  background: transparent url(../public/img/checkbox.png) left top no-repeat;
  position: absolute;
}

.jq-radio {
  width: 13px;
  height: 13px;
  position: relative;
  background: #fff;
  box-shadow: none;
  border: 1px solid #96a0a1;
}

.jq-radio.checked {
  background: #96a0a1;
}

.jq-radio.checked span {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 3px;
  top: 3px;
  background: #fff;
  border-radius: 100px;
}

.portfolio-photos {
  display: none;
}

.fancybox-skin,
.fancybox-opened .fancybox-skin {
  background: transparent;
  box-shadow: none;
}

.fancybox-title-over-wrap {
  background: transparent;
  bottom: -50px;
  left: -10px;
  font-family: 'Montserrat';
  font-size: 12px;
  text-transform: uppercase;
}

.fancybox-close {
  width: 10px;
  height: 10px;
  background: url(../public/img/colorbox-close.png) left top no-repeat;
  bottom: -20px;
  right: 14px;
  top: auto;
}

.fancybox-prev span {
  display: block;
  width: 10px;
  height: 16px;
  left: -40px;
  background: url(../public/img/colorbox-left.png) left top no-repeat;
  visibility: visible;
}

.fancybox-next span {
  display: block;
  width: 10px;
  height: 16px;
  right: -40px;
  background: url(../public/img/colorbox-right.png) left top no-repeat;
  visibility: visible;
}

/* .flight-item {
  
  
} */
.flight-item {
  background: #fff;
  margin-bottom: 19px;
  padding: 18px 18px 13px 18px;
  display: flex !important;
  justify-content: center; 
  align-items: center; 
  height: 100%;
  /* border: 2px solid black; */
  /* border-color: black !important; */
}
.flight-item1 {
  background: #fff;
  margin-bottom: 19px;
  padding: 18px 18px 13px 18px;
}

.flight-item2 {
  background: #fff;
  margin-bottom: 19px;
  padding: 18px 18px 0px 18px;
}

.flt-i-a {
  width: 100%;
  float: left;
}

.flt-i-b {
  margin: 0px 117px 0px 0px;
}

.flt-i-bb {
  width: 100%;
  float: left;
  overflow: hidden;
  margin-bottom: 10px;
}

.flt-i-bb.flight-return {
  padding-top: 18px;
  margin-bottom: 0px;
}

.flt-i-c {
  width: 140px;
  float: left;
  height: 117px;
  overflow: hidden;
  margin: 0 0 0 -91px;
}
.add-btn {
  position: absolute;
  top: 53%;
  right: -10px; /* Place the button slightly outside the container */
  transform: translateY(-69%);
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 22px;
  height: 23px;
  background-color: white;
  color: #785eff;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* transition: transform 0.2s ease, background-color 0.2s ease; */
}


.flt-l-a {
  width: 100%;
  float: left;
}

.flt-l-b {
  width: 115px;
  float: left;
  overflow: hidden;
  /* display: grid;
  justify-content: center; */
}

.flt-l-b1 {
  width: 50px;
  float: left;
  overflow: hidden;
}

.flt-l-c {
  margin: 0px 0px 0px 120px;
}

.flt-l-c1 {
  margin: 0px -6px 0px 50px;
}

.flt-l-cb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.way-lbl {
  margin-bottom: 23px;
  margin-top: 5px;
  margin-left: 9%;
  text-transform: capitalize;
  font-size: 12px;
  color: #8a8a8a;
  font-family: 'Raleway';
  display: inline-block;
  padding-right: 37px;
  font-weight: 500;
  background-size: 14px;
  background: url(../public/img/depart-icon1.svg) right top no-repeat;
  background-position: right 1px;
}

.flt-i-bb.flight-return .way-lbl {
  /* background: url(../public/img/return-icon.svg) right top no-repeat; */
  background-size: 14px;
  background-position: right 1px;
  padding-right: 42px;
}

.flyght-info-head {
  font-size: 12px;
  color: #4c4c4c;
  font-family: 'Montserrat';
  font-weight: normal;
  /* background: #f5f7f7; */
  padding: 5px 9px 4px 0px;
  margin-bottom: 5px;
}

.flight-line {
  padding: 15px 0px 14px 0px;
  border-bottom: 1px solid #ebebeb;
}

.flight-line:last-child {
  border-bottom: 0px;
  padding-bottom: 6px;
}

.flight-radio {
  display: inline-block;
  vertical-align: middle;
  margin: -3px 5px 0px 9px;
}

.flight-line-a {
  display: inline-block;
  vertical-align: middle;
}

.flight-line-a b {
  font-size: 15px;
  color: black;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  display: block;
}
.apiairportname{
  color: #a0a0a0 !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  display: flex;
  align-items: center;
}

.flight-line-a span {
  font-size: 13px;
  color: black;
  font-family: 'Open Sans';
  font-family: 'Montserrat';
}

.flight-line-d1 {
  display: inline-block;
  vertical-align: middle;
  width: 115px;
  height: 13px;
  background: url(../public/img/DOTT_LINE.svg) left top no-repeat;
  margin: 5px 0px 0px 13px;
}

.flight-line-d2 {
  display: inline-block;
  vertical-align: middle;
  width: 115px;
  height: 13px;
  background: url(../public/img/FLIGHT_LINE.svg) left top no-repeat;
  margin: 5px 11px 0px 0px;
}

.flight-line-d3 {
  display: inline-block;
  vertical-align: middle;
  width: 11px;
  height: 13px;
  background: url(../public/img/alt-time.png) left top no-repeat;
  margin: 0px 11px 0px 13px;
}

.flight-line-d4 {
  display: inline-block;
  vertical-align: middle;
  width: 131px;
  height: 13px;
  background: url(../public/img/bus_line.svg) left top no-repeat;
  margin: 5px 11px 0px 0px;
}

.flight-line-b {
  float: right;
  margin-top: 42px;
  line-height: 16px;
}
.flt-i-price-container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start;  */
}

.flight-line-b b {
  cursor: pointer;
  display: block;
  background: url(../public/img/details-icon.png) right top no-repeat;
  background-position: right 7px;
  font-size: 12px;
  /* font-family: 'Open Sans'; */
  font-weight: normal;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
  color: blue;
  position: relative;
  padding-right: 11px;
}


sup {
  color: red;
  vertical-align: super;
}

.flight-line-b b.open {
  cursor: pointer;
  display: block;
  background: url(../public/img/details-icon-up.png) right top no-repeat;
  background-position: right 6px;
  font-size: 11px;
  font-family: 'Open Sans';
  font-weight: normal;
  text-transform: uppercase;
  font-weight: 600;
}

.flight-line-b .ui-slider-handle {
  outline: none !important;
}

.flight-line-b span {
  font-family: 'Raleway';
  font-weight: 500;
  color: #ff0000;
  font-size: 10px;
}

.flt-l-c-padding {
  padding-right: 4px;
}

.flt-i-padding {
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 10px;
}

.flt-i-price,
.flt-i-price1 {
  font-family: 'Montserrat';
  margin-bottom: 4px;
  line-height: 23px;
  font-weight: bold;
  font-size: 15px;
  color: #644ed4;
  float: right;
  margin-right: 5px;
}

.flt-i-price1 {
  cursor: pointer;
  position: relative;
}

.flt-i-c1 .flt-i-price1-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
}

.flt-i-price1 .hover-target {
  color: black;
  font-size: 11px;
  float: right;
  text-decoration: underline;
  margin-right: 3px;
}

.flt-i-c1 .modal-overlay {
  display: none;
  position: absolute;
  top: calc(1% - 100%);
  left: 0;
  width: 150px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.flt-i-price-b {
  margin-bottom: 14px;
  font-family: 'Raleway';
  font-weight: normal;
  text-transform: lowercase;
  font-size: 9px;
  color: #939393;
  float: right;
  margin-right: 23px;
}

.flight-details {
  display: none;
  /* border: 1px solid #785eff; */
  padding: 13px 9px 6px 11px;
  font-family: 'Montserrat';
  font-weight: 600;
  margin: 12px 0px 0px 0px;
}

.flight-details .tab .tablinks {
  border: none;
  font-size: 11px;
  color: #848484;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 7px 9px;
  cursor: pointer;
}

.flight-details .tab .tablinks:hover {
  background: #fff;
}

.flight-details .tab .tablinks.active {
  background: #785eff;
  color: #fff;
}

.flight-details-l {
  float: left;
  width: 174px;
}

.flight-details-m {
  float: left;
  width: 60px;
  margin-left: 46px;
  margin-top: 15px;
}

.flight-details-r {
  float: right;
  width: 198px;
}

.flight-details-a {
  font-size: 11px;
  color: #785eff;
  padding-top: 8px;
  padding-bottom: 9px;
  font-family: 'Montserrat';
  line-height: 20px;
}

.flight-details-b {
  margin-bottom: 6px;
  font-size: 12px;
  color: #4d4d4d;
  font-family: 'Montserrat';
}

.flight-details-e {
  width: 60px;
  margin-bottom: 6px;
  font-size: 11px;
  color: #4d4d4d;
  font-family: 'Montserrat';
  margin-left: 19px;
  text-align: center;
  font-weight: 500;
}

.flight-details-e hr {
  color: #785ef7;
  background-color: #785ef7;
  border: 1px solid #785ef7;
}

.flight-details-c {
  font-size: 11px;
  color: #a0a0a0;
  font-family: 'Montserrat';
}
.flight-details-c1{
  font-size: 9px;
  color: #a0a0a0;
  font-weight: 500;
  font-family: 'Montserrat';
}
.flight-details-d {
  background-color: #f5f7f7;
  font-family: 'Montserrat';
  margin: 6px 0px 0px 0px;
  text-align: center;
  font-size: 10px;
  color: #848484;
  line-height: 22px;
}

.side-time-holder {
  padding-top: 4px;
}

.side-time {
  margin-bottom: 22px;
}

.side-time .ui-widget-header {
  background: #bd8100;
}

.side-lbl-a {
  font-size: 11px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-family: 'Open Sans';
  font-weight: bold;
  color: #4a90a4;
}

.time-ammounts {
  text-transform: uppercase;
  margin-bottom: 18px;
  font-size: 10px;
  text-transform: uppercase;
  color: #666666;
  font-family: 'Open Sans';
}

.alt-flight {
  background: #fff;
  margin-bottom: 20px;
}

.alt-flight-a {
  width: 100%;
  float: left;
}

.alt-flight-l {
  margin: 0px 135px 0px 0px;
}

.alt-flight-lb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.alt-flight-lr {
  width: 135px;
  float: left;
  overflow: hidden;
  margin: 0 0 0 -135px;
}

.alt-flight-lr .padding {
  padding: 29px 0px 10px 18px;
}

.alt-flight .flt-i-price,
.alt-flight .flt-i-price1 {
  font-weight: 500;
}

.alt-flight .alt-flight-lr .cat-list-btn {
  text-align: center;
  width: 97px;
  padding-left: 0px;
  padding-right: 0px;
}


#preloader {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #FFF;
  z-index: 11000;
}

#spinner {
  position: relative;
  height: 35px;
  width: 35px;
  top: 50%;
  margin-bottom: -12px;
  margin: 0 auto;
  border: 3px solid rgba(226, 226, 226, 0.75);
  border-radius: 100%;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
}

#spinner:before {
  content: "";
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  height: 100%;
  width: 100%;
  border-top: 3px solid #bd8100;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 3px solid transparent;
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.datepicker-popup {
  min-width: 534px !important;
}

.alt-flight-lr .cat-list-btn {
  padding-left: 20px;
  padding-right: 20px;
}

.alt-center-l {
  width: 161px;
  float: left;
  overflow: hidden;
}

.alt-center {
  width: 100%;
  float: left;
}

.alt-center-c {
  margin: 0px 0px 0px 161px;
}

.alt-center-cb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.alt-center-lp {
  padding: 18px 0px 18px 18px;
}

.alt-logo {
  display: table;
}

.alt-logo a {
  display: table-cell;
  width: 161px;
  height: 112px;
  background: #f7f9fa;
  text-align: center;
  vertical-align: middle;
}

.alt-center-cp {
  padding: 27px 0px 30px 18px;
  border-right: 1px solid #ede7e1;
}

.alt-lbl {
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #4c4c4c;
}

.alt-info {
  font-weight: 500;
  color: #747d7e;
  font-size: 13px;
}

.alt-info b {
  font-family: 'Open Sans';
  font-weight: bold;
  color: #4a90a4;
  margin-right: 6px;
}

.alt-devider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ede7e1;
  margin: 11px 0px 13px 0px;
}

.alt-data-i {
  line-height: 12px;
  float: left;
}

.alt-data-i b {
  display: block;
  font-family: 'Raleway';
  font-weight: 600px;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-size: 10px;
  color: #848484;
}

.alt-data-i span {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 12px;
  color: #4a90a4;
}

.alt-departure {
  background: url(../public/img/alt-depart.png) left center no-repeat;
  padding: 0px 0px 0px 23px;
}

.alt-arrival {
  background: url(../public/img/alt-return.png) left center no-repeat;
  margin-right: 27px;
  padding: 0px 0px 0px 23px;
}

.alt-time {
  background: url(../public/img/alt-time.png) left center no-repeat;
  margin-right: 20px;
  padding: 0px 0px 0px 23px;
}

.alt-center-c .flight-line-b {
  line-height: 15px;
  margin-top: -1px;
  margin-right: 32px;
}

.alt-details {
  display: none;
  padding: 23px 21px 25px 21px;
  border-top: 1px solid #e8e8e8;
}

.alt-details-i {
  font-family: 'Raleway';
  float: left;
  margin-right: 70px;
  font-weight: 600;
}

.alt-details-i:nth-child(2n) {
  margin-right: 56px;
}

.alt-details-i b {
  display: block;
  font-size: 12px;
  margin-bottom: 3px;
  color: #4d4d4d;
}

.alt-details-i span {
  color: #a0a0a0;
  font-size: 11px;
}

.alt-fligt-table {
  width: 90%;
  /* float: left; */
  background: #ffffff;
  /* margin-bottom: 19px; */
  /* margin-right: 19px; */
  margin: auto;
  display: block;
}

.alt-fligt-table:nth-child(3n) {
  float: right;
  margin-right: 0px;
}

.alt-fligt-table-a {
  padding: 10px 10px 17px 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.alt-fligt-table-img {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.alt-fligt-table-img a {
  display: table-cell;
  height: 124px;
  width: 100%;
  background: #f7f9fa;
  text-align: center;
  vertical-align: middle;
}

.alt-fligt-table-content {
  padding: 16px 8px 0px 8px;
}

.alt-fligt-table-info {
  border-top: 1px solid #ede7e1;
  border-bottom: 1px solid #ede7e1;
  padding: 13px 0px 13px 0px;
  margin-bottom: 16px;
}

/* .alt-fligt-table-info .alt-departure { float:right; } */
.alt-fligt-table-info .alt-arrival {
  margin: 0px;
  float: right;
}

.alt-fligt-table .alt-info {
  margin-bottom: 13px;
  text-transform: capitalize;
}

.alt-fligt-table-foot-l {
  float: left;
  width: 100%;
}

.alt-fligt-table-foot-r {
  float: right;
}

.alt-fligt-table .flt-i-price,
.alt-fligt-table .flt-i-price1 {
  margin-bottom: 3px;
  margin-top: -4px;
  font-size: 18px;
}

.alt-fligt-table .flt-i-price-b {
  font-size: 9px;
  margin-bottom: 0px;
  color: #939393;
}

.alt-fligt-table .cat-list-btn {
  padding-left: 20px;
  padding-right: 20px;
}

.cat-list-item .offer-slider-r span {
  float: none;
}

.offer-slider-i .offer-slider-r b {
  margin-bottom: 1px;
}

.preloader {
  background: url(../public/img/89.gif) center center no-repeat #ffffff; 
}

.tour-item .cat-list-item-p {
  padding: 0px;
}

.tour-item .cat-list-content-lpadding {
  padding: 0px;
}

.tour-item-a {
  padding: 20px 0px 20px 17px;
}

.tour-item-b {
  padding: 17px 17px 20px 17px;
}

.tour-item-lbl {
  margin-bottom: 6px;
}

.tour-item-lbl a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  color: #4c4c4c;
  font-weight: normal;
  font-family: 'Montserrat';
}

.tour-item-date {
  font-size: 10px;
  color: #747d7e;
  font-family: 'Raleway';
  font-weight: 600;
}

.tour-item-devider {
  width: 100%;
  height: 1px;
  background: #ede7e1;
}

.tour-item-b p {
  margin: 0px 0px 10px 0px;
}

.tour-item-plus {
  margin: 0px 3px 0px 2px;
  display: inline-block;
}

.tour-item-icons {
  float: left;
}

.tour-icon-txt {
  float: left;
  font-size: 10px;
  margin: 4px 0px 0px 9px;
  color: #747d7e;
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
}

.tour-duration {
  float: left;
  font-size: 10px;
  margin: 4px 0px 0px 33px;
  color: #747d7e;
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
}

.tour-item .offer-slider-r span {
  font-weight: 500;
  color: #747d7e;
}

.tour-item .cat-list-item-rb .offer-slider-r b {
  margin-bottom: 6px;
}

.tour-item .cat-list-btn:hover {
  background: #4a90a4;
}

.tour-i-holder {
  float: left;
}

.tour-grid .offer-slider-link a {
  font-weight: normal;
  font-family: 'Montserrat';
  letter-spacing: -0.03em;
  font-size: 12px;
  color: #4c4c4c;
}

.tour-grid .offer-slider-txt {
  padding: 17px 15px 19px 17px;
}

.tour-grid .offer-slider-link {
  margin-bottom: 11px;
}

.tour-grid .offer-slider-r b {
  font-weight: 500;
}

.tour-grid .offer-slider-r span {
  float: none;
  font-weight: 500;
}

.tour-grid .offer-slider-location {
  margin-bottom: 3px;
}

.tour-grid .cat-list-btn {
  width: 86px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}
.sp-page{
  position: relative;
}

.sp-page-a {
  width: 100%;
  float: left;
}

.sp-page-l {
  margin: 0px 348px 0px 0px;
}

.sp-page-l .chooserooms {
  background-color: white;
  margin-right: 29px;
  margin-top: 2%;
  padding: 1%;
}

.sp-page-l .chooserooms .roomsdetail {}

.sp-page-lb {
  width: 100%;
  float: left;
  overflow: hidden;
}

/* .sp-page-l img {width:100%;} */
.sp-page-l .container {
  padding: 0;
}


.sp-page-l .container {
  position: relative;
}


.sp-page-l .mySlides {
  display: none;
}


.sp-page-l .cursor {
  cursor: pointer;
}


.sp-page-l .prev,
.sp-page-l .next {
  cursor: pointer;
  position: absolute;
  top: 45%;
  width: auto;
  padding: 12px;
  text-decoration: none;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}


.sp-page-l .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}


.sp-page-l .prev:hover,
.sp-page-l .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


.sp-page-l .numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}


.sp-page-l .caption-container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}

.sp-page-l .row:after {
  content: "";
  display: table;
  clear: both;
}


.sp-page-l .column {
  float: left;
  width: 16.66%;
}

.sp-page-l .demo {
  opacity: 0.6;
}

.sp-page-l .active,
.sp-page-l .demo:hover {
  opacity: 1;
}

.sp-page-l nav ul {
  display: flex;
}

.sp-page-l .content-tabs-head ul li a {
  text-align: center;
  height: 48px;
  width: 100%;
}

.sp-page-l .content-tabs-i {
  padding: 4%;
}

.sp-page-r {
  width: 348px;
  float: left;
  overflow: hidden;
  margin: 0 0 0 -348px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.sp-page-r1 {
  margin: 0 0 0 -348px;
}

.sp-page-p {
  padding-right: 29px;
}

.h-detail-r {
  background: #fff;
  padding: 20px 20px 10px 20px;
  margin-bottom: 7px;
}

.h-detail-lbl {
  margin: 0px 0px 17px 0px;
  font-size: 16px;
  color: #4c4c4c;
}

.h-help {
  background: #fff;
  padding: 21px 20px 21px 20px;
  margin-bottom: 28px;
}

.h-help-lbl {
  font-family: 'Montserrat';
  margin-bottom: 8px;
  color: #4c4c4c;
  font-weight: normal;
  font-size: 16px;
}

.h-help-lbl-a {
  font-family: 'Raleway';
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  color: #747d7e;
}

.h-help-phone {
  font-family: 'Open Sans';
  font-size: 20px;
  color: #4a90a4;
  background: url(../public/img/h-phone.png) left top no-repeat;
  padding: 0px 0px 0px 23px;
  background-position: left 5px;
}

.h-help-email {
  font-family: 'Raleway';
  margin: 6px 0px 0px 23px;
  font-size: 13px;
  color: #888888;
}

.h-liked {
  background: #fff;
  margin-bottom: 28px;
  padding: 21px 20px 21px 20px;
}

.h-liked-lbl {
  font-family: 'Montserrat';
  font-weight: normal;
  margin-bottom: 16px;
  font-size: 16px;
  color: #4c4c4c;
}

.h-liked-rating {
  margin-bottom: 8px;
}

.h-liked-item {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.h-liked-item:last-child {
  border-bottom: 0px;
  padding: 0px;
  margin: 0px;
}

.h-liked-item-i {
  width: 100%;
  float: left;
}

.h-liked-item-l {
  width: 81px;
  float: left;
  overflow: hidden;
  margin-top: 4px;
}

.h-liked-item-c {
  margin: 0px 0px 0px 81px;
}

.h-liked-item-cb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.h-liked-item-p {
  padding: 0px 20px 0px 17px;
}

.h-liked-title {
  margin-bottom: 5px;
}

.h-liked-title a {
  font-size: 12px;
  text-decoration: none;
  color: #4c4c4c;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: normal;
}

.h-liked-price {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 17px;
  color: #bd8100;
}

.h-liked-comment {
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 5px;
  font-size: 9px;
  color: #939393;
}

.h-detail-lbl-a {
  font-family: 'Montserrat';
  margin-bottom: 7px;
  font-size: 18px;
  color: #4c4c4c;
  font-weight: normal;
}

.h-detail-lbl-b {
  font-size: 12px;
  color: black;
  font-weight: 500;
}

.h-detail-stars {
  padding: 10px 0px 10px 0px;
  font-weight: 500;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
}

.h-stars-list,
.h-stars-list li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.h-stars-list li {
  float: left;
  margin-right: 1px;
  background: #bd8100;
  /* padding-left: 2%; */
  padding: 10%;
  color: white;
  margin: 2%;
  height: 27px;
  border-radius: 3px;
  display: block;
  width: 51px;
}

.h-stars-list {
  float: left;
  margin-right: 8px;
}

.h-stars-lbl {
  float: left;
  margin: 7px 0px 0px 0px;
  font-size: 11px;
  color: #747d7e;
  text-transform: capitalize;
}

.h-add-review {
  text-decoration: none;
  margin: 7px 0px 0px 0px;
  float: right;
  background: url(../public/img/add.png) left top no-repeat;
  padding: 0px 0px 0px 17px;
  background-position: left 0px;
  text-transform: capitalize;
  font-size: 11px;
  color: #747d7e;
}

.h-details-text {
  padding: 16px 0px 17px 0px;
  margin-bottom: 22px;
  border-bottom: 1px solid #ebebeb;
}

.h-details-text p {
  font-size: 13px;
  color: #626262;
  font-family: 'Raleway';
  line-height: 21px;
  margin-bottom: 20px;
}

.h-details-text p:last-child {
  margin-bottom: 0px;
}

.wishlist-btn {
  display: block;
  height: 41px;
  margin-bottom: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}

.wishlist-btn:hover .wishlist-btn-r {
  color: #4a90a4;
}

.wishlist-btn:hover .wishlist-btn-l i {
  background-position: left -10px;
}

.wishlist-btn:hover {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(50, 50, 50, 0.07);
  -moz-box-shadow: 0px 1px 3px 0px rgba(50, 50, 50, 0.07);
  box-shadow: 0px 1px 3px 0px rgba(50, 50, 50, 0.07);
}

.book-btn:hover {
  background: #ff8521;
}

.reasons-rating-txt {
  font-family: 'Raleway';
  font-size: 14px;
  color: #7e898b;
  line-height: 26px;
  margin-bottom: 12px;
}

.reasons-rating {
  overflow: hidden;
  position: relative;
  padding: 20px 20px 20px 20px;
  background: #fff;
  margin-bottom: 28px;
}

.reasons-rating .bx-pager {
  position: absolute;
  z-index: 999;
  bottom: 30px;
  right: 20px;
}

.reasons-rating .bx-pager-item {
  margin-bottom: 4px;
}

.reasons-rating .bx-pager-item a {
  display: block;
  border-radius: 1000px;
  border: 1px solid #818c8e;
  overflow: hidden;
  text-indent: -999px;
  width: 8px;
  height: 8px;
}

.reasons-rating .bx-pager-item a.active {
  background: #bd8100;
  border-color: #bd8100;
}

.reasons-rating .bx-controls-direction {
  display: none;
}

.reasons-rating-user-l {
  position: relative;
  float: left;
  width: 52px;
}

.reasons-rating-user-l span {
  display: block;
  width: 20px;
  height: 17px;
  padding-top: 3px;
  text-align: center;
  background: #4a90a4;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 100px;
  font-size: 9px;
  color: #fff;
  font-family: 'Open Sans';
  font-weight: bold;
}

.reasons-rating-user-r {
  float: left;
  margin: 5px 0px 0px 14px;
  font-family: 'Raleway';
}

.reasons-rating-user-r b {
  display: block;
  font-weight: 600;
  font-size: 13px;
  color: #4c4c4c;
}

.reasons-rating-user-r span {
  font-size: 11px;
  color: #555555;
}

.h-tabs-right {
  float: right;
  padding: 18px 0px 0px 0px;
}

.h-tabs-right i {
  float: left;
  display: block;
  width: 15px;
  height: 15px;
  background: url(../public/img/more-icon.png) left top no-repeat;
}

.h-tabs-right span {
  float: left;
  font-family: 'Raleway';
  font-weight: 700;
  margin: 1px 0px 0px 8px;
  font-size: 10px;
  color: #787878;
  text-transform: uppercase;
}

.h-tabs-right a:hover i {
  background-position: left -15px;
}

.h-tabs-right a:hover span {
  color: #4a90a4;
}

.h-reasons {
  background: #fff;
  padding: 21px 20px 9px 20px;
}

.h-reasons-row {
  margin-top: 20px;
}

.reasons-i {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 13px;
}

.reasons-i:last-child {
  margin-bottom: 0px;
  border-bottom: 0px;
  padding-bottom: none;
}

.reasons-l {
  width: 143px;
  margin-top: 1px;
  float: left;
  font-size: 13px;
  font-weight: 600;
  color: #a0a0a0;
  font-family: 'Open Sans';
  overflow: hidden;
}

.reasons-h {
  width: 100%;
  float: left;
}

.reasons-r {
  margin: 0px 0px 0px 151px;
  font-family: 'Raleway';

  font-size: 13px;
}

.reasons-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.reasons-i-lbl {
  font-weight: normal;
  font-family: 'Open Sans';
  margin-bottom: 7px;
  text-transform: uppercase;
  font-size: 12px;
  color: #a0a0a0;
}

.reasons-r p {
  font-size: 12px;
  color: #626262;
  line-height: 21px;
}

.reasons-p {
  padding: 0px 0px 0px 11px;
}

.h-tabs {
  background: #fff;
  padding: 0px 18px 0px 14px;
  margin-bottom: 7px;
  height: 47px;
}

.h-tabs-left {
  float: left;
  padding: 12px 0px 0px 0px;
}

.h-tab-item-01 i {
  display: block;
  background: url(../public/img/gall-icon.png) left top no-repeat;
  float: left;
  width: 17px;
  height: 16px;
}

.h-tab-item-02 i {
  display: block;
  background: url(../public/img/map-icon.png) left top no-repeat;
  float: left;
  width: 13px;
  height: 16px;
}

.h-tab-item-03 i {
  display: block;
  background: url(../public/img/calc-icon.png) left top no-repeat;
  float: left;
  width: 17px;
  height: 16px;
}

.h-tab-i span {
  display: none;
  float: left;
  margin: 3px 0px 0px 7px;
  text-transform: uppercase;
  color: #bd8100;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Raleway';
}

.h-tab-i.active span {
  display: block;
}

.h-tab-i.active i {
  background-position: left -16px;
}

.h-tab-i {
  height: 22px;
  float: left;
  margin-right: 12px;
  padding: 3px 12px 0px 0px;
}

.mm-tabs-wrapper {
  margin-bottom: 37px;
}

.tab-item {
  display: none;
  position: relative;
}

.tab-item:nth-child(1) {
  display: block;
}

.wishlist-btn-l {
  float: left;
  width: 39px;
  height: 41px;
  display: block;
  float: left;
  border-right: 1px solid #e5e5e5;
}

.wishlist-btn-r {
  float: right;
  width: 266px;
  height: 27px;
  padding-top: 14px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Raleway';
  color: #6a7880;
  font-weight: 600;
  text-align: center;
  display: block;
}

.wishlist-btn-l i {
  display: block;
  background: url(../public/img/whishlist.png) left top no-repeat;
  float: left;
  margin: 16px 0px 0px 15px;
  width: 10px;
  height: 10px;
}

.book-btn {
  display: block;
  background: #bd8100;
  height: 41px;
  margin-bottom: 15px;
  border: 1px solid #bd8100;
  border-radius: 3px;
}

.book-btn-l {
  float: left;
  width: 39px;
  height: 41px;
  display: block;
  float: left;
  border-right: 1px solid #ed6b00;
}

.book-btn-r {
  float: right;
  width: 266px;
  height: 27px;
  padding-top: 14px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Raleway';
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  display: block;
}

.book-btn-l i {
  display: block;
  background: url(../public/img/book-icon.png) left top no-repeat;
  float: left;
  margin: 17px 0px 0px 14px;
  width: 12px;
  height: 8px;
}

.gallery-i a {
  display: block;
  position: relative;
}

.gallery-i span {
  display: block;
  width: 102px;
  height: 69px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
  border: 3px solid transparent;
}

.gallery-i.active span {
  border-color: #ff9e4f;
}

.gallery-i:hover img {
  opacity: 0.8;
}

.tab-gallery-big {
  margin-bottom: 7px;
}

.tab-gallery-preview {
  position: relative;
  background: #fff;
  padding: 7px 10px 3px 20px;
}

.tab-gallery-preview .bx-pager {
  display: none;
}

.tab-gallery-preview .bx-prev,
.tab-gallery-preview .bx-next {
  display: block;
  width: 20px;
  height: 75px;
  overflow: hidden;
  text-indent: -999px;
  position: absolute;
  top: 7px;
}

.tab-gallery-preview .bx-prev {
  left: 0px;
  background: url(../public/img/gall-prev.png) left top no-repeat;
}

.tab-gallery-preview .bx-next {
  right: 0px;
  background: url(../public/img/gall-next.png) left top no-repeat;
}

.tab-gallery-preview .bx-prev:hover,
.tab-gallery-preview .bx-next:hover {
  background-position: left -75px;
}

.content-tabs-head ul li {
  float: left;
  margin-right: 3px;
  height: 47px;
  width: 113px;
}

.content-tabs-head ul li a .noofmeal {
  font-size: 9px;
  font-weight: 600;
  font-family: 'Montserrat';
  color: #4a90a4;
  text-transform: none;
}

.content-tabs-head ul li .choosetypemeal {
  float: right;
  color: #4c4c4c;
  padding: 5px;
  font-weight: 700;
  font-size: 11px;
  font-family: 'Raleway';
  margin-top: -46px;
  text-transform: capitalize;
}

.content-tabs-head ul li .choosetypemeal input {
  padding: 5px;
}

.content-tabs-head ul li .choosetypemeal label {
  padding: 5px;
}

.content-tabs-head ul li a {
  display: block;
  height: 40px;
  padding: 12px 15px 0px 15px;
  border-radius: 3px 3px 0px 0px;
  font-family: 'Raleway';
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  background: #bd8100;
}

.content-tabs-head ul li a.active {
  background: #fff;
  color: #bd8100;
  box-shadow: 3px 2px 2px grey;
}

.content-tabs-body {
  background: #fff;
  margin-top: 1%;
  height: 450px;
  padding: 0px 0px 0px 0px;
  /* border: 2px solid #e3e3e3; */
  overflow-y: auto;
}

.content-tabs-i {
  display: none;
}

.content-tabs-i:nth-child(1) {
  display: block;
}

.content-tabs-i h2 {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 16px;
  color: #4c4c4c;
  margin-bottom: 19px;
}

.content-tabs-i p {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Raleway';
  color: #626262;
  line-height: 26px;
  text-transform: capitalize;
}

.content-tabs-i p.small {
  font-size: 13px;
  line-height: 21px;
}

.tab-reasons {
  padding-top: 14px;
}

.tab-reasons h2 {
  margin-bottom: 26px;
  margin-top: 2%;
}

.tab-reasons-h {
  background: #fcfbfa;
  padding: 27px 20px 5px 26px;
  margin-bottom: 36px;
}

.tab-reasons-i {
  width: 41%;
  margin-bottom: 6px;
  float: left;
  padding-left: 43px;
}

.tab-reasons-i:nth-child(2n) {
  float: right;
}

.tab-reasons-i b {
  font-family: 'Montserrat';
  display: block;
  margin-bottom: 9px;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px;
  color: #3a4142;
}

.tab-reasons-i p {
  font-size: 12px;
  line-height: 22px;
}

.tab-reasons-i.reasons-01 {
  background: url(../public/img/tabs-i-01.png) left top no-repeat;
}

.tab-reasons-i.reasons-02 {
  background: url(../public/img/tabs-i-02.png) left top no-repeat;
}

.tab-reasons-i.reasons-03 {
  background: url(../public/img/tabs-i-03.png) left top no-repeat;
}

.tab-reasons-i.reasons-04 {
  background: url(../public/img/tabs-i-04.png) left top no-repeat;
}

.facilities table {
  width: 100%;
  border-collapse: collapse;
}

.facilities table {
  width: 100%;
}

.facilities table td {
  font-family: 'Raleway';
  font-size: 13px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 15px 0px 14px 0px;
  color: #626262;
}

.facilities table td.facilities-a {
  width: 40px;
  color: #4a90a4;
}

.facilities table td.facilities-a img {
  width: 25px;
}

.facility-label {
  background: url(../public/img/free-icon.png) left top no-repeat;
  padding: 0px 0px 0px 14px;
  background-position: left 4px;
}

.rates-line {
  width: 44%;
  float: left;
}

.rates-line-right {
  width: 44%;
  float: left;
  margin-left: 24px;
}

.rates-search {
  padding-top: 7px;
}

.rates-search label {
  display: block;
  margin-bottom: 8px;
  font-size: 11px;
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
  color: #4c4c4c;
}

.rates-search .search-line-a.middle input[type="text"] {
  width: 120px;
}

.rates-search .search-line-a {
  width: 152px;
  margin-right: 9px;
}

.rates-search .search-line-select.middle .search-select {
  width: 84px;
}

.rates-search .search-line-select.middle {
  width: 87px;
}

.rates-search .search-line-select.middle.last {
  width: 86px;
}

.rates-search .search-line-a input[type="text"],
.rates-search .search-line-b input[type="text"] {
  padding-top: 9px;
  padding-bottom: 8px;
}

.rates-srch {
  display: block;
  float: right;
  margin: 21px 0px 0px 0px;
  width: 34px;
  height: 34px;
  background: #bd8100 url(../public/img/rates-srch.png) left top no-repeat;
  border-radius: 2px;
  border: 0px;
  cursor: pointer;
}

.rates-srch:hover {
  background: #ffca27 url(../public/img/rates-srch.png) left top no-repeat;
}

.rates-devider {
  height: 1px;
  background: #ebebeb;
  margin: 12px 0px 35px 0px;
}

.available-row .cat-list-item {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 4px;
  margin-bottom: 19px;
}

.available-row .cat-list-content-p {
  padding-left: 40px;
}

.available-row .cat-list-item-l {
  width: 167px;
  margin-top: 4px;
}

.available-row .cat-list-item-r {
  margin-left: 167px;
}

.available-row .offer-slider-link a {
  font-size: 600;
}

.available-row .cat-list-content-lpadding {
  padding-top: 0px;
  min-height: 0px;
  padding-bottom: 15px;
  border: none;
}

.available-row .cat-list-content-l p {
  font-size: 13px !important;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 21px;
}

.available-row .cat-list-content-l .offer-slider-link {
  margin-bottom: 12px;
}

.available-row .cat-list-item-p {
  padding-left: 19px;
}

.available-row .cat-list-content-p {
  padding-top: 0px;
}

.available-btn {
  width: 82px;
  height: 21px;
  padding-top: 10px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #6a7880;
  display: block;
  text-decoration: none;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

.available-btn:hover {
  color: #bd8100;
}

.available-price {
  font-family: 'Open Sans';
  margin-top: -1px;
  font-weight: 600;
  font-size: 20px;
  color: #bd8100;
  margin-bottom: 4px;
}

.available-price-a {
  font-weight: 500;
  font-size: 10px;
  margin-bottom: 15px;
  color: #747d7e;
  text-transform: uppercase;
}

.available-price-c {
  font-size: 13px;
  color: #455051;
  margin-bottom: 21px;
}

.availabe-more {
  width: 100%;
  height: 24px;
  margin-top: 31px;
  padding-top: 11px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  color: #6a7880;
  display: block;
  text-decoration: none;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.availabe-more:hover {
  color: #bd8100;
}

.preferences-list,
.preferences-list li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.preferences-list {
  margin-top: 22px;
}

.preferences-list li {
  display: inline-block;
  float: left;
  width: 175px;
  font-size: 12px;
  color: #626262;
  margin: 0px 20px 14px 0px;
  min-height: 24px;
  padding: 6px 0px 0px 39px;
}

.preferences-list li:nth-child(3n) {
  margin-right: 0px;
}

.preferences-list li.internet {
  /* background: url(../public/img/pref-01.png) left top no-repeat; */
}

.preferences-list li.conf-room {
  /* background: url(../public/img/pref-02.png) left top no-repeat; */
}

.preferences-list li.play-place {
  /* background: url(../public/img/pref-03.png) left top no-repeat; */
}

.preferences-list li.restourant {
  /* background: url(../public/img/pref-04.png) left top no-repeat; */
}

.preferences-list li.bar {
  /* background: url(../public/img/pref-05.png) left top no-repeat; */
}

.preferences-list li.doorman {
  /* background: url(../public/img/pref-06.png) left top no-repeat; */
}

.preferences-list li.kitchen {
  /* background: url(../public/img/pref-07.png) left top no-repeat; */
}

.preferences-list li.spa {
  /* background: url(../public/img/pref-08.png) left top no-repeat; */
}

.preferences-list li.bike {
  /* background: url(../public/img/pref-09.png) left top no-repeat; */
}

.preferences-list li.entertaiment {
  /* background: url(../public/img/pref-10.png) left top no-repeat; */
}

.preferences-list li.hot-tub {
  /* background: url(../public/img/pref-11.png) left top no-repeat; */
}

.preferences-list li.pool {
  /* background: url(../public/img/pref-12.png) left top no-repeat; */
}

.preferences-list li.parking {
  /* background: url(../public/img/pref-13.png) left top no-repeat; */
}

.preferences-list li.gym {
  /* background: url(../public/img/pref-14.png) left top no-repeat; */
}

.preferences-list li.tv {
  /* background: url(../public/img/pref-15.png) left top no-repeat; */
}

.preferences-list li.pets {
  /* background: url(../public/img/pref-16.png) left top no-repeat; */
}

.preferences-list li.handicap {
  /* background: url(../public/img/pref-17.png) left top no-repeat; */
}

.preferences-list li.secure {
  /* background: url(../public/img/pref-18.png) left top no-repeat; */
}

.preferences-devider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ebebeb;
  margin: 12px 0px 36px 0px;
}

.preferences-list-alt,
preferences-list-alt li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.preferences-list-alt {
  margin-top: 24px;
}

.preferences-list-alt li {
  display: inline-block;
  float: left;
  width: 175px;
  font-size: 12px;
  color: #626262;
  margin: 0px 20px 11px 0px;
  min-height: 24px;
  padding: 5px 0px 0px 39px;
}

.preferences-list-alt li:nth-child(3n) {
  margin-right: 0px;
}

.preferences-list-alt li.internet {
  /* background: url(../public/img/pref-alt-01.png) left top no-repeat; */
}

.preferences-list-alt li.parking {
  /* background: url(../public/img/pref-alt-02.png) left top no-repeat; */
}

.preferences-list-alt li.gym {
  /* background: url(../public/img/pref-alt-03.png) left top no-repeat; */
}

.preferences-list-alt li.restourant {
  /* background: url(../public/img/pref-alt-04.png) left top no-repeat; */
}

.preferences-list-alt li.pets {
  /* background: url(../public/img/pref-alt-05.png) left top no-repeat; */
}

.preferences-list-alt li.pool {
  /* background: url(../public/img/pref-alt-06.png) left top no-repeat; */
}

.preferences-list-alt li.kitchen {
  /* background: url(../public/img/pref-alt-07.png) left top no-repeat; */
}

.preferences-list-alt li.conf-room {
  /* background: url(../public/img/pref-alt-08.png) left top no-repeat; */
}

.preferences-list-alt li.bike {
  /* background: url(../public/img/pref-alt-09.png) left top no-repeat; */
}

.preferences-list-alt li.entertaiment {
  /* background: url(../public/img/pref-alt-10.png) left top no-repeat; */
}

.preferences-list-alt li.bar {
  /* background: url(../public/img/pref-alt-11.png) left top no-repeat; */
}

.preferences-list-alt li.secure {
  /* background: url(../public/img/pref-alt-12.png) left top no-repeat; */
}

.reviews-c {
  width: 100%;
  float: left;
}

.reviews-l {
  width: 233px;
  float: left;
  overflow: hidden;
}

.reviews-r {
  margin: 0px 0px 0px 233px;
}

.reviews-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.reviews-total {
  font-family: 'Open Sans';
  margin-top: -15px;
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 50px;
  color: #4c4c4c;
}

.reviews-total-stars {
  display: block;
}

.reviews-total-stars li {
  float: left;
  margin-right: 3px;
}

.reviews-percents {
  margin-bottom: 15px;
}

.reviews-percents-i {
  height: 4px;
  width: 100%;
  background: #ede9e6;
  border-radius: 100px;
}

.reviews-percents-i span {
  display: block;
  height: 4px;
  width: 70%;
  background: #82bec5;
  border-radius: 100px;
}

.reviews-percents label {
  font-size: 10px;
  display: block;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 9px;
  color: #8c8c8c;
}

.reviews-devider {
  height: 1px;
  background: #ebebeb;
  width: 100%;
  margin: 16px 0px 36px 0px;
}

.hotel-reviews-devider {
  height: 1px;
  background: #ebebeb;
  width: 100%;
  margin: 22px 0px 36px 0px;
}

.hotel-reviews h2 {
  margin-bottom: 26px;
}

.hotel-reviews-i {
  width: 181px;
  float: left;
  margin-right: 50px;
  margin-bottom: 21px;
}

.hotel-reviews-i:nth-child(3n) {
  margin-right: 0px;
}

.hotel-reviews-left {
  float: left;
  font-size: 13px;
  color: #4c4c4c;
}

.hotel-reviews-right {
  float: right;
}

.hotel-reviews-right li {
  float: left;
  margin-left: 1px;
}

.guest-reviews h2 {
  margin-bottom: 23px;
}

.guest-reviews-i {
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid #ebebeb;
}

.guest-reviews-a {
  width: 100%;
  float: left;
}

.guest-reviews-l {
  width: 89px;
  float: left;
  overflow: hidden;
}

.guest-reviews-r {
  margin: 0px 0px 0px 89px;
}

.guest-reviews-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.guest-reviews-img {
  position: relative;
  margin-top: 3px;
  width: 70px;
  height: 70px;
}

.guest-reviews-img span {
  display: block;
  width: 26px;
  height: 22px;
  padding-top: 4px;
  border-radius: 1000px;
  text-align: center;
  position: absolute;
  right: 0px;
  bottom: 0px;
  background: #4a90a4;
  color: #fff;
  font-size: 12px;
  font-family: 'Open Sans';
  font-weight: 600;
}

.guest-reviews-b {
  width: 100%;
  float: left;
}

.guest-reviews-bl {
  margin: 0px 173px 0px 0px;
}

.guest-reviews-blb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.guest-reviews-br {
  width: 173px;
  padding-top: 3px;
  float: left;
  overflow: hidden;
  margin: 0 0 0 -173px;
}

.guest-reviews-lbl {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 14px;
  color: #343434;
}

.guest-reviews-lbl-a {
  font-size: 12px;
  margin-bottom: 10px;
  color: #555555;
}

.guest-reviews-txt {
  font-size: 13px;
  line-height: 21px;
  color: #626262;
}

.guest-reviews-br nav li {
  float: left;
  margin-right: 1px;
}

.guest-reviews-br nav {
  float: left;
  margin-right: 8px;
}

.guest-rating {
  float: left;
  font-size: 12px;
  color: #939393;
  font-family: 'Open Sans';
}

.guest-rating-txt {
  font-size: 13px;
  margin-top: 32px;
  color: #4cb318;
}

.guest-reviews-padding {
  padding-left: 60px;
}

.guest-reviews-more {
  width: 239px;
  height: 24px;
  padding-top: 13px;
  font-size: 10px;
  margin: 30px 0px 0px 0px;
  text-transform: uppercase;
  font-weight: 700;
  color: #6a7880;
  display: block;
  text-decoration: none;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.guest-reviews-more:hover {
  color: #bd8100;
}

.review-form {
  margin: 35px 0px 0px 0px;
}

.review-form label {
  display: block;
  font-size: 13px;
  color: #4c4c4c;
  margin-bottom: 9px;
}

.review-form .input-a {
  width: 63%;
  margin-bottom: 24px;
}

.review-form .input-a input {
  text-transform: none;
}

.review-form .textarea-a {
  margin-bottom: 36px;
}

.review-form .textarea-a textarea {
  text-transform: none;
  height: 100px;
}

.input-with-icon-container .input-a {
  padding-top: 8px;
  padding-bottom: 8px;
}

.review-ranger {
  margin-bottom: 26px;
}

.review-ranger label {
  float: left;
  width: 100px;
  font-size: 13px;
  color: #4c4c4c;
  display: block;
}

.review-ranger-r {
  float: left;
  margin: 6px 0px 0px 0px;
  width: 338px;
}

.review-ranger-r .ui-slider-horizontal {
  height: 5px;
}

.range-holder {
  position: relative;
  display: block;
  width: 20px;
  text-align: center;
}

.range-holder i {
  position: absolute;
  display: block;
  width: 20px;
  left: 1px;
  top: -18px;
  width: 17px;
  text-align: center;
  display: inline-block;
  font-size: 11px;
  font-family: 'Open Sans';
  color: #4c4c4c;
  font-style: normal;
}

.review-form .custom-select {
  cursor: pointer;
}

.review-form .customSelect1 {
  padding: 8px 7px 8px 7px;
  margin-bottom: 24px;
  cursor: pointer;
  border: 1px solid #dedcdc;
  background: url(../public/img/custom-select.png) right top no-repeat;
  cursor: pointer !important;
  width: 63%;
  border-radius: 2px;
}

.review-form .customSelectInner {
  font-size: 14px;
}

.review-send {
  margin-top: 5px;
  width: 239px;
  height: 40px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-size: 11px;
  font-family: 'Raleway';
  font-weight: 700;
  background: #bd8100;
  display: block;
  border-radius: 2px;
}

.review-send:hover {
  background: #ff8521;
}

.todo-row .cat-list-content-lpadding {
  padding: 0px 16px 0px 20px;
  min-height: 0px;
}

.todo-row .cat-list-item-l {
  width: 194px;
  margin-top: 2px;
}

.todo-row .cat-list-item-r {
  margin-left: 194px;
}

.todo-row .cat-list-item-p {
  padding: 0px;
}

.todo-row .cat-list-item-rb .offer-slider-link a {
  font-size: 14px;
  color: #343434;
  font-weight: 600;
  text-transform: none;
}

.todo-row .offer-rate {
  font-size: 12px;
  margin-bottom: 9px;
  color: #4a90a4;
}

.todo-row .cat-list-content-l .offer-slider-link {
  margin-bottom: 12px;
}

.todo-row .cat-list-content-l p {
  line-height: 21px;
  font-size: 13px;
  margin: 0px;
}

.todo-row .cat-list-content-r {
  width: 146px;
  margin-left: -146px;
}

.todo-row .cat-list-content-l {
  margin-right: 146px;
}

.todo-row .stars ul li {
  margin-right: 2px;
}

.todo-row .cat-list-review {
  margin-bottom: 16px;
  font-size: 12px;
  text-transform: none;
  color: #626262;
}

.todo-row .cat-list-item .stars {
  margin-bottom: 8px;
}

.todo-btn {
  width: 85px;
  height: 21px;
  padding-top: 10px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #6a7880;
  display: block;
  text-decoration: none;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

.todo-btn:hover {
  color: #bd8100;
}

.todo-row .cat-list-content-p {
  padding-left: 29px;
  padding-right: 0px;
  padding-top: 0px;
  margin-top: -9px;
}

@media all and (-webkit-min-device-pixel-ratio:0) {
  .todo-row .cat-list-content-p {
    margin-top: -2px;
  }
}

.todo-devider {
  height: 1px;
  background: #ebebeb;
  margin: 24px 0px 27px 0px;
}

.todo-row .cat-list-item {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 22px;
  padding-bottom: 20px;
}

.faq-item {
  padding-bottom: 17px;
  margin-bottom: 17px;
  border-bottom: 1px solid #ebebeb;
}

.faq-item-a {
  cursor: pointer;
}

.faq-item-left {
  font-size: 14px;
  color: #4c4c4c;
  font-weight: 500;
}

.faq-item-i {
  float: right;
  margin-top: 2px;
  display: block;
  width: 12px;
  height: 12px;
  background: url(../public/img/faq.png) left top no-repeat;
}

.faq-item.open .faq-item-i {
  background-position: left -12px;
}

.faq-item-p {
  display: none;
  font-size: 13px;
  padding-top: 13px;
  color: #626262;
  line-height: 21px;
}

#map {
  width: 100%;
  height: 546px;
}


.calendar-tab {
  background: #fff;
  padding: 24px 20px 17px 20px;
}

.calendar-tab-select label {
  font-size: 12px;
  font-family: 'Raleway';
  font-weight: 600;
  margin-bottom: 12px;
  display: block;
  text-transform: uppercase;
  color: #3a4142;
}

.calendar-tab-select select {
  cursor: pointer;
}

.calendar-tab-select .customSelect1 {
  border-radius: 2px;
  margin-bottom: 17px;
  background: url(../public/img/tab-select.png) right top no-repeat;
  width: 257px;
  height: 25px;
  padding: 7px 0px 0px 9px;
  border: 1px solid #dedcdc;
}

.calendar-tab-select .customSelectInner {
  font-size: 11px;
  color: #8a8a8a;
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: 600;
}

.tab-calendar-s {
  padding: 21px 0px 0px 15px;
}

.tab-calendar-colls {
  width: 100%;
  float: left;
}

.tab-calendar-collsl {
  margin: 0px 117px 0px 0px;
}

.tab-calendar-collslb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.tab-calendar-collsr {
  width: 117px;
  float: left;
  overflow: hidden;
  margin: 0 0 0 -117px;
}

.tab-calendar-colls table {
  width: 100%;
  border-collapse: collapse;
}

.tab-calendar-colls table thead td {
  font-size: 10px;
  color: #636e70;
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: 600;
  padding-bottom: 8px;
}

.tab-calendar-colls table tbody td {
  width: 72px;
  height: 72px;
  padding: 0px 7px 7px 0px;
}

.tab-calendar-colls table tbody td span {
  font-size: 13px;
  text-align: right;
  border-radius: 2px;
  color: #a5b5be;
  font-family: 'Open Sans';
  font-weight: 600;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #afd1db;
}

.tab-calendar-colls table tbody td span p {
  display: block;
  padding: 6px 7px 0px 0px;
}

.tab-calendar-colls table tbody td.date-passed span {
  background: #f6f9fa;
}

.tab-calendar-colls table tbody td.date-available span {
  color: #84d840;
  border-color: #84d840;
}

.tab-calendar-colls table tbody td.date-unavailable span {
  color: #f69191;
  border-color: #f69191;
}

.map-symbol-l {
  float: left;
  width: 14px;
  height: 14px;
  border-radius: 2px;
}

.map-symbol-r {
  float: left;
  margin: 2px 0px 0px 7px;
  font-size: 11px;
  color: #747d7e;
  font-weight: 500;
  text-transform: uppercase;
}

.passed .map-symbol-l {
  border: 1px solid #afd1db;
}

.available .map-symbol-l {
  border: 1px solid #84d840;
}

.unavailable .map-symbol-l {
  border: 1px solid #f69191;
}

.map-symbol {
  margin-bottom: 13px;
}

.booking-form-append {
  margin-top: 10px;
}

.card-header1.blurred,
.card-header2.blurred,
.card-header3.blurred {
  filter: blur(0.6px);
  pointer-events: none;
  background-color: #e3e3e3;
}

.card-header4 {
  background-color: #e3e3e3;
}

.card-header1.blurred.disabled:hover,
.card-header2.blurred.disabled:hover,
.card-header3.blurred.disabled:hover {
  cursor: pointer;
}

.card-body .seat_selection {
  border: 2px solid #785eff;
  height: 450px;
  overflow-y: auto;
}

/* .card-body .seat_selectionBusiness{
  border: 2px solid #e3e3e3;
  height: auto;
  overflow-y: auto;
} */
.card-body .seat_selection .plane_details {
  float: left;
  margin-top: 1%;
  margin-left: 1%;
  font-size: 12px;
  color: #4c4c4c;
  width: 100px;
  font-weight: 600;
  padding: 1%;
  border: 3px solid #e3e3e3;
}

.card-body .seat_selection .plane_details h1 {
  text-transform: capitalize;
  font-size: 12px;
}

.card-body .seat_selection .plane_details .square {
  margin: 3%;
  height: 15px;
  width: 15px;
  background-color: #555;
  border-radius: 6px;
}

.card-body .seat_selection .plane_details .square span {
  margin-left: 25px;
  font-size: 11px;
}

.card-body .seat_selection .plane_details .Selected {
  background-color: #0091ff;
}

.card-body .seat_selection .plane_details .Available {
  background-color: #9B7AC7;
}

.card-body .seat_selection .plane_details .Occupied,
.card-body .seat_selection .plane_details .Blocked {
  background-color: #d8d8d8;
}

.booking_continue {

  color: #fff;
  padding: 1.1%;
  font-weight: 700;
  font-size: 14px;
  border: none;
  line-height: 19px;
  font-family: 'Open Sans';
  border-radius: 4px;
  width: 170px;
  background-color: #785eff;
  text-transform: uppercase;
}

.booking_continue_hover:hover {
  background-color: #644ed4;
}

.booking-left .addon-button {
  font-size: 9px;
  color: #fff;
  font-family: 'Raleway';
  background-color: #bd8100;
  border-color: #bd8100;
}

.booking-left .accordion {
  background-color: #fff;
  color: #444;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 13px;
  transition: 0.4s;
  margin: 0px 0px;
}

.booking-left .accordion1 {
  background-color: #fff;
  color: #444;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 13px;
  transition: 0.4s;
}

.booking-left {
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  padding: 22px 19px 19px 19px;
  font-family: 'Raleway';
  margin-top: 5%;
}

.booking-left1 {
  width: 100%;
}

.booking-left .saveinfo {
  margin-top: 20px;
}

.booking-left h2 {
  font-weight: bold;
  font-family: 'Raleway';
  margin-bottom: 0px;
  padding: 9px;
  background-color: #bd8100;
  font-size: 16px;
  color: #fff;
}

.booking-left h1 {
  font-weight: bold;
  font-family: 'Open Sans';
  margin-bottom: 0px;
  padding: 0px;
  padding-left: 0px;
  background-color: #fff;
  font-size: 15px;
  text-transform: capitalize;
}

.booking-left .success_booked {
  text-align: center;
  font-weight: normal;
  background-color: #fff;
  font-family: 'Montserrat';
  margin-bottom: 18px;
  font-size: 16px;
  color: #4c4c4c;
}

.booking-left .success_booked_p {
  text-align: center;
}

.booking-left .success_booked_p b {
  text-align: center;
  font-family: 'Monstrait';
}

.booking-left .success_booked_button {
  text-align: center;
  width: 184px;
  display: block;
  margin-left: auto;
  border: 1px solid #bd8100;
  background-color: #bd8100;
  margin-right: auto;
  color: #fff;
  font-weight: 600;
  border-radius: 25px;
  padding: 5px 20px;
}

.booking-left h3 {
  font-weight: 500;
  font-family: 'Montserrat';
  text-transform: uppercase;
  margin-bottom: 18px;
  font-size: 12px;
  color: #4c4c4c;
}

.booking-form-i label,
.booking-form-i1 label {
  font-size: 12px;
  display: block;
  color: #626262;
}

.booking-form-i {
  width: 48.55%;
  margin-bottom: 12px;
  float: left;
  height: 75px;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
  background-color: #fff;
  /* padding-left: 2%; */
}

.booking-form-i4 {
  width: 100%;
  padding-top: 10px;
  margin-bottom:20px;
  height: 43px;
  color: #626262;
  background-color: transparent;
}

.booking-form-iw {
  width: 40%;
  height: 20px;
  padding-left: 55px;
  color: #626262;
  background-color: transparent;
}

.booking-form-ix {
  width: 20%;
  height: 20px;
  font-weight: 700;
  font-family: 'Open Sans';
  color: #626262;
  background-color: transparent;
}

.booking-form-iy {
  width: 20%;
  font-family: 'Open Sans';
  height: 20px;
  font-weight: 700;
  color: #626262;
  background-color: transparent;
}

.booking-form-iz {
  width: 20%;
  font-family: 'Open Sans';
  height: 20px;
  font-weight: 700;
  color: #626262;
  background-color: transparent;
}

.booking-form-i0 {
  width: 100%;
  height: 80px;
  color: #000;
  background-color: transparent;
}

.booking-form-i4 .booking-form-i9 {
  width: 100%;
  height: 20px;
  color: #000;
  margin-bottom: 3px;
  background-color: transparent;
}

.booking-form-i4 .booking-form-i0 {
  width: 100%;
  height: 3px;
  color: #000;
  background-color: transparent;
}

.booking-form-i4 .booking-form-i5 {
  width: 25%;
  height: 20px;
  color: #000;
  background-color: transparent;
  padding: 10px 0px 10px 0px;
}

.booking-form-i4 .booking-form-i6 {
  width: 75%;
  height: 43px;
  color: #000;
  font-family: 'Open Sans';
  background-color: transparent;
  padding: 10px 0px 10px 0px;
}

.booking-form-i4 h1 {
  background-color: transparent;
  font-family: 'Open Sans';
  font-size: 13px;
  text-transform: capitalize;
  font-weight: bold;
  color: #000;
  display: flex;
  margin-bottom: 5px;
}
.mandatory-star {
  position: absolute;
  /* right: -10px; */
  left: 23%;
  top: -8%;
  color: red;
  font-size: 18px;
  font-weight: bold;
}

.booking-form-i2 label::after {
  content: '*';
  color: red;
  font-size: 20px;
  position: relative;
}

.booking-form-i .meal_name {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
}

.booking-form-i .meal_price {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}

.booking-form-i .meal_add {
  float: right;

}

.booking-form-i .meal_add button {
  border: 1px solid #fff;
  padding: 3px 15px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 800;
  width: 90px;
  font-family: 'Montstrait';
  border-radius: 6px;
}

.booking-form-i1 {
  width: 48.55%;
  height: 60px;
  margin-bottom: 50px;
  float: right;
  background-color: #fff;
}

.booking-form-i:nth-child(2n) {
  float: right;
}

.booking-form {
  margin-bottom: 3% !important;
  display: block;
  margin-top: 1% !important;
}
.bagg-form-details{
  margin-bottom: 0% !important;
  display: block;
  /* margin-left: 1%; */
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}
.booking-form-i .input {
  padding: 6px 10px 6px 10px;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  border-radius: 2px;
}

.booking-form-i .input input[type='text'],
.booking-form-i .input input[type='date'] {
  border: 0px;
  width: 100%;
  height: 23px;
  color: rgb(98, 98, 98);
  font-weight: 600;

  font-size: 13px;
  font-family: 'Open Sans';
}

.booking-form-i .input input[type='email'] {
  border: 0px;
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  font-family: 'Open Sans';
}

.lineflights {
  display: inline-block;
  width: 360px;
}

.lineflightss {
  display: inline-block;
  width: 495px;
}

.booking-form .checkbox {
  margin-top: 2px;
}

.booking-form .remove {
  font-family: Montserrat;
  font-style: normal;
  padding: 0px 7px 0px 0px;

  text-transform: uppercase;
  color: #4a90a4;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  margin-left: 507px;
}

.booking-left .add {
  font-family: Montserrat;
  font-style: normal;
  padding: 7px;
  background-color: #bd8100;
  cursor: pointer;
  /* margin: 10px; */
  color: #fff;
  font-weight: 600;
  font-size: 13px;
}


.booking-left .passenger-submit,
.booking-left .save_details {

  padding: 8px;
  text-align: right;
  font-family: 'Open Sans';
  float: right;
  border-radius: 5px;
  margin-right: 6px;
  background-color: #785eff;
  cursor: pointer;
  border: 1px solid #785eff;
  color: #fff;
  margin-left: 5px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 13px;
}

.booking-left .passenger-submit:hover,
.booking-left .save_details:hover {
  background: #644ed4;
  border-color: #644ed4;
}

.flight-item.selected-flight {
  background-color: #FAF7F0 !important;
  border: 1.5px solid black;
}

.selected_flights {
  position: fixed;
  bottom: 2%;
  width: 958px;
  background-color: #bd8100;
  display: block;
  margin: 0px 0px 0px 3px;
  padding: 5px;
  border: 1px solid #bd8100;
  border-radius: 7px;
  color: #fff;
}

.selected_flights .departureselectedflight {
  width: 42%;
  float: left;

}

.selected_flights .departureselectedflight h1 {
  font-weight: 600;
  text-transform: capitalize;
  font-family: 'Raleway';
  font-size: 15px;
  padding: 10px;
}

.selected_flights .departureselectedflight .times_price {
  width: 100%;
}

.selected_flights .departureselectedflight .times_price .selectedtimes {
  width: 50%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.selected_flights .departureselectedflight .times_price .departureselectedflightamount {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Montserrat';
}

.selected_flights .returnselectedflight {
  width: 42%;
  float: left;

}

.selected_flights .returnselectedflight h1 {
  font-weight: 600;
  text-transform: capitalize;
  font-family: 'Raleway';
  font-size: 15px;
  padding: 10px;
}

.selected_flights .returnselectedflight .times_price {
  width: 100%;
}

.selected_flights .returnselectedflight .times_price .returnselectedflightamount {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Montserrat';
}

.selected_flights .returnselectedflight .times_price .selectedtimes {
  width: 50%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;

}

.selected_flights .bookselectedflight {
  width: 15%;
  float: left;

}

.selected_flights .hideflightdetails {
  width: 98%;
  float: left;
  padding: 13px;
  margin-left: 10px;

  margin-top: 10px;
  background-color: #fff;
  color: #4c4c4c;
  border-radius: 6px;

}

.selected_flights .bookselectedflightbutton .per_traveller {
  font-size: 11px;
  font-family: 'Raleway';
  width: 100%;
  margin-left: 60px;
  float: left;
}

.selected_flights .bookselectedflight .total_prices {
  margin-top: 14%;
  font-size: 22px;
  font-weight: 600;
  font-family: 'Montserrat';
  float: right;
}

.selected_flights .bookselectedflightbutton {
  width: 15%;
  float: left;
}

.selected_flights .bookselectedflightbutton .returnbook {
  border: 1px solid #bd8100;
  background-color: #fff;
  color: #bd8100;
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'Raleway';
  padding: 10px 15px;
  font-weight: 600;
  float: right;

  margin-top: 11%;
  border-radius: 22px;
}

.selected_flights .departureselectedflight .times_price .selectedtimes .selecteddeparturetime {
  width: 30%;
  float: left;
  padding-left: 5px;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Montserrat';
}

.selected_flights .departureselectedflight .times_price .selectedtimes .selectedarrivaltime {
  width: 37%;
  float: right;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Montserrat';
}

.selected_flights .returnselectedflight .times_price .selectedtimes .returnselecteddeparturetime {
  width: 30%;
  float: left;
  padding-left: 5px;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Montserrat';
}

.selected_flights .returnselectedflight .times_price .selectedtimes .returnselectedarrivaltime {
  width: 37%;
  float: right;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Montserrat';
}

.selected_flights .hideflightdetails .nav-tabs {
  border-bottom: 1px solid #fff;
}

.selected_flights .hideflightdetails .nav-tabs .nav-item {
  background-color: #f5f7f7;
}

.selected_flights .hideflightdetails .nav-tabs .nav-item .nav-link {
  color: #4c4c4c;
  font-size: 13px;
  font-weight: 600;
  font-family: 'Raleway';
  text-transform: capitalize;
}

.selected_flights .hideflightdetails .nav-tabs .nav-item .nav-link.active {
  color: #fff;
  background-color: #bd8100;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights {
  width: 100%;
  margin-top: 2%;
  overflow-y: auto;
  height: 230px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights {
  width: 48%;
  float: left;
  border: 2px solid #f5f7f7;
  border-radius: 3px;

  margin-right: 4%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflighthead {
  border-bottom: 2px solid #f5f7f7;
  padding: 5px;
  font-family: 'Raleway';
  font-size: 13px;
  font-weight: 600;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightairline {
  padding: 5px;
  width: 100%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightairline .depairline_details {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightairline .depairline_details .depairline_no {
  font-weight: 400;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails {
  width: 100%;
  padding: 10px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .line-details {
  width: 100%;
  padding: 1px;
  background-color: #bd8100;
  margin-top: 19%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_l {
  width: 35%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_l .depflightdetails_l_a {
  font-weight: 600;
  font-size: 17px;

  font-family: 'Montserrat';
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_l .depflightdetails_l_b {
  font-weight: 700;
  font-size: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_l .depflightdetails_l_c {
  font-weight: 500;
  font-size: 11px;
  font-family: 'Raleway';

}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_m {
  width: 30%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_m_a {
  font-family: 'Montserrat';
  font-size: 12px;
  margin-top: 10%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_m_b hr {
  padding: 1px;
  background-color: #bd8100;
  width: 50%;
  margin-top: 4px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_r {
  width: 35%;
  float: right;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_r .depflightdetails_r_a {
  font-weight: 600;
  font-size: 17px;
  font-family: 'Montserrat';

}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_r .depflightdetails_r_b {
  font-weight: 700;
  font-size: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depflightdetails .depflightdetails_r .depflightdetails_r_c {
  font-weight: 500;
  font-size: 11px;
  font-family: 'Raleway';

}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage {
  width: 100%;
  padding: 5px;
  margin-top: 15%;
  margin-bottom: 11%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_l {
  width: 35%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_l .depbaggage_l_a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  margin-left: 3%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_l .depbaggage_l_b {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  margin-left: 3%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_m {
  width: 30%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_m .depbaggage_m_a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  margin-left: 3%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_m .depbaggage_m_b {
  font-weight: 400;
  font-size: 11px;
  margin-left: 3%;
  font-family: 'Montserrat';
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_r {
  width: 35%;
  float: right;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_r .depbaggage_r_a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  margin-left: 3%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .departure_flights .depbaggage .depbaggage_r .depbaggage_r_b {
  font-weight: 400;
  font-size: 11px;
  margin-left: 3%;
  font-family: 'Montserrat';
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights {
  width: 48%;
  float: left;
  border: 2px solid #f5f7f7;
  border-radius: 3px;

}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflighthead {
  border-bottom: 2px solid #f5f7f7;
  padding: 5px;
  font-family: 'Raleway';
  font-size: 13px;
  font-weight: 600;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightairline {
  padding: 5px;
  width: 100%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightairline .retairline_details {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightairline .retairline_details .retairline_no {
  font-weight: 400;
}

.returnselectedflight .flight_details,
.departureselectedflight .flight_details {
  width: 100%;
  padding-left: 10px;
  text-transform: capitalize;
  font-size: 13px;
  font-family: 'Raleway';
}

.returnselectedflight .flight_details:hover,
.departureselectedflight .flight_details:hover {
  cursor: pointer;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails {
  width: 100%;
  padding: 10px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .line-details {
  width: 100%;
  padding: 1px;
  background-color: #bd8100;
  margin-top: 19%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_l {
  width: 35%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_l .retflightdetails_l_a {
  font-weight: 600;
  font-size: 17px;
  font-family: 'Montserrat';

}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_l .retflightdetails_l_b {
  font-weight: 700;
  font-size: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_l .retflightdetails_l_c {
  font-size: 11px;
  font-weight: 500;
  font-family: 'Raleway';

}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_m {
  width: 30%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_m .retflightdetails_m_a {
  font-family: 'Montserrat';
  font-size: 12px;
  margin-top: 10%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_m .retflightdetails_m_b hr {
  padding: 1px;
  background-color: #bd8100;
  width: 50%;
  margin-top: 4px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_r {
  width: 35%;
  float: right;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_r .retflightdetails_r_a {
  font-weight: 600;
  font-size: 17px;
  font-family: 'Montserrat';

}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_r .retflightdetails_r_b {
  font-weight: 700;
  font-size: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retflightdetails .retflightdetails_r .retflightdetails_r_c {
  font-size: 11px;
  font-weight: 500;
  font-family: 'Raleway';

}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage {
  width: 100%;
  padding: 5px;
  margin-top: 15%;
  margin-bottom: 11%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_l {
  width: 35%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_l .retbaggage_l_a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  margin-left: 3%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_l .retbaggage_l_b {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  margin-left: 3%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_m {
  width: 30%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_m .retbaggage_m_a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  margin-left: 3%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_m .retbaggage_m_b {
  font-weight: 400;
  font-size: 11px;
  margin-left: 3%;
  font-family: 'Montserrat';
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_r {
  width: 35%;
  float: right;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_r .retbaggage_r_a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  margin-left: 3%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .flights .return_flights .retbaggage .retbaggage_r .retbaggage_r_b {
  font-weight: 400;
  font-size: 11px;
  margin-left: 3%;
  font-family: 'Montserrat';
}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary {
  width: 100%;
  border: 2px solid #f5f7f7;
  height: 230px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummaryhead {
  border-bottom: 2px solid #f5f7f7;
  padding: 5px;
  font-family: 'Raleway';
  font-size: 13px;
  font-weight: 600;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody {
  width: 100%;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody .faresummarybody_l {
  width: 20%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody .faresummarybody_l .faresummarybody_l_a {
  text-transform: uppercase;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;

}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody .faresummarybody_l .faresummarybody_l_b {
  padding: 5px;
  font-size: 13px;
  font-weight: 400;

}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody .faresummarybody_l .faresummarybody_l_c {
  padding: 5px;
  font-size: 13px;
  font-weight: 400;

}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody .faresummarybody_r {
  width: 80%;
  float: left;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody .faresummarybody_r .faresummarybody_r_a {
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody .faresummarybody_r .faresummarybody_r_b {
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .faresummary .faresummarybody .faresummarybody_r .faresummarybody_r_c {
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges {
  width: 100%;
  border: 2px solid #f5f7f7;
  height: 230px;
  overflow-y: auto;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_head,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_head,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_head,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_head {
  border-bottom: 2px solid #fff;
  padding: 5px;
  background-color: #f5f7f7;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_head .dep_cancellation_head_details,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_head .ret_cancellation_head_details,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_head .dep_datechanges_head_details,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_head .ret_datechanges_head_details {
  font-weight: 500;
  font-size: 14px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_head .dep_cancellation_head_show,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_head .ret_cancellation_head_show,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_head .dep_datechanges_head_show,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_head .ret_datechanges_head_show {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #4a90a4;
  float: right;
  padding-right: 15px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_head .dep_cancellation_head_show:hover,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_head .ret_cancellation_head_show:hover,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_head .dep_datechanges_head_show:hover,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_head .ret_datechanges_head_show:hover {
  cursor: pointer;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_body,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_body,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_body,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_body {

  height: 146px;
  overflow-y: auto;
  margin: 10px;
  padding: 10px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_body .dep_cancellation_body_head,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_body .ret_cancellation_body_head,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_body .dep_datechanges_body_head,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_body .ret_datechanges_body_head {
  width: 100%;
  /* border: 2px solid #f5f7f7; */
  height: 40px;
  padding: 5px;
  font-weight: 600;
  font-size: 15px;
  /* /* border-right: 2px solid #f5f7f7; */
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_body .dep_cancellation_body_head .dep_cancellation_body_head_a,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_body .ret_cancellation_body_head .ret_cancellation_body_head_a,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_body .dep_datechanges_body_head .dep_datechanges_body_head_a,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_body .ret_datechanges_body_head .ret_datechanges_body_head_a {
  width: 50%;
  float: left;
  padding: 10px;
  border: 2px solid #f5f7f7;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_body .dep_cancellation_body_head .dep_cancellation_body_head_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_body .ret_cancellation_body_head .ret_cancellation_body_head_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_body .dep_datechanges_body_head .dep_datechanges_body_head_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_body .ret_datechanges_body_head .ret_datechanges_body_head_b {
  width: 50%;
  float: left;
  padding: 10px;
  border: 2px solid #f5f7f7;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_body .dep_cancellation_body_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_body .ret_cancellation_body_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_body .dep_datechanges_body_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_body .ret_datechanges_body_b {
  width: 100%;

  height: 40px;

  padding-left: 5px;
  font-weight: 400;
  font-size: 13px;
  padding-right: 5px;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_body .dep_cancellation_body_b .dep_cancellation_body_b_a,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_body .ret_cancellation_body_b .ret_cancellation_body_b_a,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_body .dep_datechanges_body_b .dep_datechanges_body_b_a,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_body .ret_datechanges_body_b .ret_datechanges_body_b_a {
  width: 50%;
  float: left;
  padding: 10px;
  border: 2px solid #f5f7f7;
}

.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .dep_cancellation_body .dep_cancellation_body_b .dep_cancellation_body_b_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .cancellation .ret_cancellation_body .ret_cancellation_body_b .ret_cancellation_body_b_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .dep_datechanges_body .dep_datechanges_body_b .dep_datechanges_body_b_b,
.selected_flights .hideflightdetails .tab-content .tab-pane .datechanges .ret_datechanges_body .ret_datechanges_body_b .ret_datechanges_body_b_b {
  width: 50%;
  float: left;
  padding: 10px;
  border: 2px solid #f5f7f7;
}

.booking-form-i .addon-select {
  cursor: pointer;
  width: 300px;
  opacity: .9;
  height: 32px;
  font-size: 12px;
  border: 1px solid #e3e3e3;
  color: #626262;
  font-family: 'Raleway';
}

.booking-form .checkbox label {
  padding-left: 2%;
  font-size: 12px;
  font-weight: 500;
  color: #848484;
  font-family: 'Raleway';
  cursor: pointer;
}

.booking-form .checkbox .jq-checkbox {
  margin-right: 7px;
}

.booking-form-i .textarea {
  padding: 6px 10px 6px 10px;
  height: 113px;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  border-radius: 2px;
}

.booking-form-i .textarea textarea {
  resize: none;
  height: 113px;
  border: 0px;
  width: 100%;
  font-size: 14px;
  font-family: 'Raleway';
}

.booking-devider {
  width: 100%;
  height: 20px;

}

.booking-devider.no-margin {
  margin-top: 7px;
}

.payment-wrapper {
  padding-top: 3px;
  margin-bottom: 27px;
}

.payment-tabs a {
  display: block;
  position: relative;
  height: 26px;
  border-radius: 2px 2px 0px 0px;
  padding: 13px 20px 0px 20px;
  background: #f7f7f7;
  border: 1px solid #fff;
  border-bottom: 0px;
  text-decoration: none;
  float: left;
  margin: 0px 1px 0px 0px;
  font-size: 11px;
  color: #73716f;
  text-transform: uppercase;
  font-weight: 700;
}

.payment-tabs a.active {
  border-color: #ebebeb;
  background: #fff;
  color: #bd8100;
}

.payment-tabs a.active span {
  display: block;
  width: 100%;
  height: 5px;
  background: #fff;
  position: absolute;
  left: 0px;
  bottom: -2px;
}

.payment-tabs-content {
  padding: 20px 18px 10px 18px;
  border: 1px solid #ebebeb;
}

.payment-tab label {
  display: block;
  font-size: 12px;
  color: #626262;
  margin-bottom: 11px;
  font-family: 'Raleway';
}

.payment-tab {
  display: none;
}

.payment-tab:first-child {
  display: block;
}

.payment-tab .checkbox label {
  cursor: pointer;
  font-weight: 500;
}

.payment-tab .jq-checkbox {
  margin-top: -1px;
}

.payment-tab .checkbox label a {
  text-decoration: none;
  color: #4a90a4;
}

.payment-type {
  margin-bottom: 18px;
}

.booking-complete p {
  padding-left: 2%;
  font-size: 13px;
  color: #626262;
  line-height: 21px;
}

.booking-left .booking-complete h2 {
  margin-bottom: 16px;
}

.booking-complete-btn {
  display: block;
  margin-top: 24px;
  width: 189px;
  height: 40px;
  background: #bd8100;
  color: #fff;
  text-transform: uppercase;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Raleway';
  font-weight: bold;
}

.booking-complete-btn:hover {
  background: #ff8521;
}

.card-type {
  width: 58px;
  height: 23px;
  float: left;
  margin: 0px 5px 0px 0px;
  padding-top: 7px;
  text-align: center;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  -moz-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  border-radius: 2px;
}

.card-expiration .customSelect {
  width: 100%;
  padding: 7px 0px 7px 0px;
  border: 1px solid #e3e3e3;
  background: url(../public/img/card-select.png) right top no-repeat;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  -moz-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  border-radius: 2px;
}

.card-expiration select {
  cursor: pointer;
}

.card-expiration {
  float: left;
  width: 47.5%;
}

.card-expiration:nth-child(2n) {
  float: right;
  margin-right: 3px;
}

.card-expiration .customSelectInner {
  font-size: 11px;
  color: #8a8a8a;
  padding-left: 9px;
}

.inpt-comment {
  width: 100%;
  float: left;
}

.inpt-comment-l {
  margin: 0px 79px 0px 0px;
}

.inpt-comment-lb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.inpt-comment-r {
  width: 79px;
  float: left;
  overflow: hidden;
  margin: 0 0 0 -79px;
}

.inpt-comment-r .padding {
  text-align: right;
  padding: 7px 0px 0px 10px;
}

.inpt-comment-r a {
  text-decoration: none;
  font-size: 11px;
  color: #626262;
}

.inpt-comment-r a:hover {
  color: #bd8100;
}

.checkout-coll {
  padding: 22px 20px 20px 20px;
  background: #fff;
  margin-bottom: 28px;
  font-family: 'Open Sans';
  margin-top: 14%;
}

.checkout-head {
  width: 100%;
  margin-bottom: 18px;
}

.checkout-headl {
  width: 94px;
  margin-top: 4px;
  float: left;
  overflow: hidden;
}

.checkout-headr {
  margin: 0px 0px 0px 94px;
}

.checkout-headrb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.checkout-headrp {
  padding: 0px 0px 0px 15px;
}

.chk-logo {
  margin-top: 10px;
}

.chk-lbl {
  margin-bottom: 7px;
  color: black;
}

.chk-lbl a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'Montserrat';
  font-size: 13px;
  color: #4c4c4c;
}

.chk-lbl-a {
  font-weight: 500;
  margin-bottom: 7px;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  color: black;

}

.chk-stars li {
  float: left;
  margin-right: 1px;
}

.chk-left {
  float: left;
}

.chk-left .review {
  background-color: #bd8100;
  color: #fff;
  border-radius: 4px;
  padding: 1px 5px 1px 5px;
  margin: 1%;
  font-size: 13px;
}

.chk-right {
  float: right;
  margin-top: 2px;
}

.chk-total {
  font-family: 'Montserrat';
}

#taxSum {
  position: absolute;
  padding: 1%;
  width: 100px;
  border: 2px solid #bd8100;
  top: 18%;
  right: 1%;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.chk-line {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  padding: 10px 0px 10px 0px;
  text-transform: capitalize;
  color: black;
  border-top: 1px solid #f2f2f2;
}
.chk-liness{
  border: none !important;
}
.chk-line.chk-fligth-info {
  padding: 8px 0px 8px 0px;
}

.chk-line:last-child {
  border-bottom: 1px solid #f2f2f2;
}

.chk-nights {
  background: url(../public/img/icon-nights.png) left top no-repeat;
  margin-right: 16px;
  color: #4a90a4;
  padding: 0px 0px 0px 16px;
  background-position: left 2px;
}

.chk-persons {
  color: #ff6739;
}

.chk-details h2 {
  font-weight: 600;
  margin-top: 10%;
  margin-bottom: 14px;
  text-transform: uppercase;
  font-size: 12px;
  color: #3a4142;
}

.chk-l {
  float: left;
  color: black;
}

.chk-r {
  float: right;
  color: #785eff;
}

.chk-detais-row {
  margin-bottom: 17px;
}

.chk-total-l {
  float: left;
  font-size: 12px;
  color: #3a4142;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 4px;
}

.chk-total-r {
  float: right;
  font-size: 16px;
  color: #785eff;
  font-weight: 600;
  font-family: 'Montserrat';
}

.chk-departure span {
  font-size: 10px;
  font-weight: 600;
  color: #848484;
  display: block;
  margin-right: 9px;
  margin-top: 1px;
  float: left;
}

.chk-departure b {
  display: block;
  font-size: 12px;
  font-family: 'Open Sans';
  margin-top: 5px;
  font-weight: 700;
  color: #785eff;
  float: left;
}

.chk-arrival {
  float: right;
}

.chk-arrival span {
  font-size: 10px;
  font-weight: 600;
  color: #848484;
  display: block;
  margin-right: 9px;
  margin-top: 1px;
  float: left;
}

.chk-arrival b {
  display: block;
  font-size: 12px;
  font-family: 'Open Sans';
  font-weight: 700;
  color: #785eff;
  margin-top: 5px;
  float: left;
}

.chk-fligth-devider {
  float: left;
  width: 1px;
  height: 22px;
  background: #ebebeb;
  margin: 4px 22px 0px 50px
}

.chk-fligth-time {
  float: left;
  margin: 10px 0px 0px 2px;
}

.payment-alert {
  line-height: 19px;
  position: relative;
  background: #f2f7f9;
  border: 1px solid #d7e7ec;
  border-radius: 2px;
  margin-top: 3px;
  padding: 18px 50px 18px 13px;
  font-size: 12px;
  color: #4a90a4;
  font-weight: 500;
}

.payment-alert-close {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 15px;
}

.payment-alert-close:hover {
  opacity: 0.8;
}

.payment-alert span {
  background: url(../public/img/alert-info.png) left top no-repeat;
  padding: 1px 0px 0px 17px;
  background-position: left 2px;
}

.paypal-btn {
  width: 155px;
  height: 24px;
  padding-top: 11px;
  font-size: 11px;
  margin: 20px 0px 12px 0px;
  text-transform: uppercase;
  font-weight: 600;
  color: #839098;
  display: block;
  text-decoration: none;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.paypal-btn:hover {
  color: #5d676d;
}

.comlete-alert {
  padding: 0px 0px 18px 0px;
  border-bottom: 1px solid #ebebeb;
}

.comlete-alert-a {
  background: url(../public/img/icon-complete.png) left top no-repeat;
  background-position: left 4px;
  padding: 0px 0px 0px 40px;
}

.comlete-alert-a b {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #4a90a4;
}

.comlete-alert-a span {
  font-size: 13px;
  color: #626262;
}

.complete-info h2 {
  margin-bottom: 18px;
}

.complete-info-l {
  float: left;
  width: 138px;
}

.complete-info-table {
  font-size: 13px;
  color: #626262;
}

.complete-info-table .complete-info-i .returnflightdetails {
  padding: 3px;
  font-size: 14px;
  margin-top: 2%;
  font-family: 'Raleway';
  font-weight: 600;
  color: #4c4c4c;
  margin-bottom: 2%;
  text-align: center;
  background-color: #e3e3e3;
}

.complete-info .passengers .countpassenger {
  width: 10%;
  float: left;
  font-size: 13px;
  font-family: 'Open Sans';
  color: #a0a0a0;
  font-weight: 600;
}

.complete-info .passengers .passengerdetail {
  width: 90%;
  float: left;
  font-size: 14px;
  font-family: 'Open Sans';
  color: #a0a0a0;
  font-weight: 500;
  text-transform: capitalize;
}

.complete-devider {
  height: 1px;
  background: #ebebeb;
  margin-bottom: 25px;
}

.complete-txt {
  margin-bottom: 26px;
}

.complete-txt.final {
  margin-bottom: 0px;
}

.complete-info .complete-txt h2 {
  margin-bottom: 16px;
}

.complete-txt p {
  font-size: 13px;
  margin-bottom: 15px;
  color: #626262;
  line-height: 21px;
}

.complete-txt-link a {
  font-size: 13px;
  text-decoration: none;
  color: #bd8100;
  font-family: 'Open Sans'
}

.flight-image {
  position: relative;
}

.flight-image span {
  position: absolute;
  left: 0px;
  bottom: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-family: 'Raleway';
  padding: 0px 15px 20px 15px;
}

.flight-d-logo {
  margin: 26px 0px 17px 0px;
}

.flight-d-i {
  width: 100%;
  float: left;
}

.flight-d-left {
  width: 157px;
  border-right: 1px solid #ebebeb;
  line-height: 27px;
  float: left;
  overflow: hidden;
}

.flight-d-right {
  margin: 0px 0px 0px 158px;
}

.flight-d-rightb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.flight-d-rightp {
  padding: 0px 0px 0px 25px;
}

.flight-da {
  font-size: 12px;
  color: #626262;
}

.flight-da b {
  color: #515657;
  margin: 0px 0px 0px 5px;
}

.flight-d-depart {
  font-size: 12px;
  margin: 0px 49px 0px 0px;
  float: left;
  color: #515657;
  line-height: 27px;
  font-weight: 700;
}

.flight-d-depart span {
  margin-right: 5px;
  font-weight: normal;
  color: #4a90a4;
}

.flight-d-time {
  font-size: 12px;
  margin: 0px 19px 0px 0px;
  float: left;
  color: #515657;
  line-height: 27px;
  font-weight: 700;
}

.flight-d-time span {
  margin-right: 5px;
  font-weight: normal;
  color: #4a90a4;
}

.flight-d-arrival {
  font-size: 12px;
  float: left;
  color: #515657;
  line-height: 27px;
  font-weight: 700;
}

.flight-d-arrival span {
  margin-right: 5px;
  font-weight: normal;
  color: #4a90a4;
}

.flight-d-time-icon {
  margin-top: 2px;
}

.flight-d-devider {
  height: 1px;
  background: #ebebeb;
  margin: 22px 0px 26px 0px;
}

.flight-d-text p:last-child {
  margin-bottom: 0px;
}

.h-detail-stars .flight-line-a b {
  margin-bottom: 0px;
}

.h-detail-stars.fligts-s {
  padding: 13px 0px 12px 0px;
}

.h-details-logo {
  border-bottom: 1px solid #ebebeb;
  padding: 17px 0px 14px 0px;
}

.h-tour .tour-icon-txt {
  margin-top: 0px;
  font-weight: 600;
  font-size: 11px;
  color: #747d7e;
  font-family: 'Raleway';
}

.tour-icon-person {
  float: left;
  font-weight: 600;
  font-size: 11px;
  color: #747d7e;
  text-transform: uppercase;
  padding: 0px 0px 0px 25px;
  margin: 0px 0px 0px 22px;
  font-family: 'Raleway';
  background: url(../public/img/persons-icon.png) left top no-repeat;
}

.h-tour {
  border-top: 1px solid #ebebeb;
  padding: 16px 0px 16px 0px;
}

.sex-type {
  float: left;
  padding: 9px 0px 9px 0px;
  font-weight: 500;
  width: 39px;
  text-align: center;
  margin-right: 4px;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  border-radius: 2px;
  font-size: 11px;
  color: #626262;
}

.sex-type.chosen {
  background: #bd8100;
  color: #fff;
  border-color: #e37012;
  -webkit-box-shadow: inset 0px 1px 2px 0px rgba(50, 50, 50, 0.2);
  -moz-box-shadow: inset 0px 1px 2px 0px rgba(50, 50, 50, 0.2);
  box-shadow: inset 0px 1px 2px 0px rgba(50, 50, 50, 0.2);
}

.form-sex {
  float: left;
}

.form-calendar {
  float: right;
  margin-right: 2px;
  width: 319px;
}

.form-calendar-a select,
.form-calendar-b select {
  cursor: pointer;
}

.form-calendar-a {
  float: left;
  width: 100%;
  margin-right: 5px;
}

.form-calendar-a .customSelectInner {
  font-size: 11px;
  color: #8a8a8a;
  padding-left: 9px;
}

.form-calendar-a .customSelect1 {
  width: 100%;
  padding: 7px 0px 7px 0px;
  border: 1px solid #e3e3e3;
  background: url(../public/img/card-select.png) right top no-repeat;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  -moz-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  border-radius: 2px;
}

.form-calendar-b {
  float: left;
  width: 90px;
  margin-right: 0px;
}

.form-calendar-b .customSelectInner {
  font-size: 11px;
  color: #8a8a8a;
  padding-left: 9px;
}

.form-calendar-b .customSelect1 {
  width: 100%;
  padding: 7px 0px 7px 0px;
  border: 1px solid #e3e3e3;
  background: url(../public/img/card-select.png) right top no-repeat;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  -moz-box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.05);
  border-radius: 2px;
}

.bookin-three-coll .booking-form-i {
  width: 31.35%;
  margin-right: 20px;
}

.bookin-three-coll .booking-form-i:nth-child(2n) {
  float: left;
}

.bookin-three-coll .booking-form-i:nth-child(3n) {
  float: right;
  margin-right: 0px;
}

.bookin-three-coll .booking-form-i .form-calendar-b {
  width: 80px;
}

.add-passanger {
  font-size: 12px;
  color: #848484;
  font-weight: 500;
  text-decoration: none;
  margin: 4px 0px 0px 0px;
  padding: 0px 0px 0px 14px;
  float: right;
  background: url(../public/img/add-pass-icon.png) left top no-repeat;
  background-position: left 2px;
}

.contacts-map {
  padding-top: 129px;
}

.contacts-map #map {
  height: 457px;
}

.contacts-page {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 76px;
}

.contacts-page .page-lbl p {
  font-size: 15px;
  color: #455051;
}

.contacts-page .page-lbl {
  margin-bottom: 49px;
}

.contacts-page-holder {
  padding-bottom: 124px;
  background: #fff;
}

.contacts-colls {
  width: 100%;
  float: left;
  font-family: 'Raleway';
}

.contacts-colls-l {
  width: 377px;
  float: left;
  overflow: hidden;
}

.contacts-colls-r {
  margin: 0px 0px 0px 377px;
}

.contacts-colls-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.contact-colls-lbl {
  text-transform: uppercase;
  font-family: 'Montserrat';
  margin-bottom: 28px;
  font-size: 17px;
  font-weight: normal;
  color: #141d1e;
}

.side-social {
  margin-top: 28px;
}

.side-social a {
  display: inline-block;
  margin-right: 20px;
  width: 13px;
  height: 14px;
  vertical-align: middle;
}

.side-social a.side-social-twitter {
  background: url(../public/img/left-social-01.png) left top no-repeat;
}

.side-social a.side-social-facebook {
  background: url(../public/img/left-social-02.png) left top no-repeat;
}

.side-social a.side-social-vimeo {
  background: url(../public/img/left-social-03.png) left top no-repeat;
}

.side-social a.side-social-pinterest {
  background: url(../public/img/left-social-04.png) left top no-repeat;
}

.side-social a.side-social-instagram {
  background: url(../public/img/left-social-05.png) left top no-repeat;
}

.side-social a:hover {
  background-position: left -14px;
}

.contacts-colls-txt p {
  font-size: 13px;
  color: #626262;
  margin-bottom: 18px;
}

.contacts-colls .booking-form-i {
  width: 47.90%;
  margin-bottom: 21px;
}

.contacts-colls .booking-form-i label {
  font-size: 13px;
  color: #626262;
}

.contacts-colls .booking-form-i label {
  margin-bottom: 8px;
}

.contacts-colls .booking-form {
  padding-bottom: 10px;
  background-color: #fff;
}

.booking-form-i.textarea {
  width: 100%;
}

.booking-form-i .textarea-wrapper {
  padding: 6px 10px 6px 10px;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  border-radius: 2px;
}

.booking-form-i .textarea-wrapper textarea {
  resize: none;
  height: 112px;
  border: 0px;
  width: 100%;
  font-size: 14px;
  font-family: 'Raleway';
}

.contacts-send {
  width: 124px;
  height: 36px;
  color: #fff;
  margin-top: 4px;
  border-radius: 2px;
  border: 0px;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: normal;
  background: #bd8100;
  text-transform: uppercase;
  cursor: pointer;
}

.contacts-send:hover {
  background: #ffca27;
  border-color: #ffca27;
}

.inner-page {
  padding: 130px 0px 0px 0px;
}

.inner-breadcrumbs {
  height: 75px;
  background: #fafafa;
}

.gray-inner .inner-breadcrumbs {
  border-bottom: 1px solid #ededed;
  max-width: 1100px;
  margin: 0 auto;
}

.content-wrapper {
  max-width: 1100px;
  margin: 0 auto;
}

.inner-breadcrumbs {
  margin-bottom: 93px;
}

.inner-breadcrumbs .page-title {
  font-family: 'Montserrat';
  float: left;
  margin: 27px 0px 0px 0px;
  font-weight: normal;
  font-size: 17px;
  color: #141d1e;
}

.inner-breadcrumbs .breadcrumbs {
  margin: 31px 0px 0px 0px;
}

.inner-breadcrumbs .breadcrumbs,
.inner-breadcrumbs .breadcrumbs a,
.inner-breadcrumbs .breadcrumbs span {
  color: #141d1e;
  font-weight: normal;
  font-size: 11px;
}

.paragraph {
  display: inline-block;
  margin: 9px 12px 0px 0px;
  float: left;
  background: #141d1e;
  color: #fff;
  text-align: center;
  font-size: 20px;
  border-radius: 2px;
  width: 33px;
  height: 29px;
  padding-top: 4px;
}

.paragraph-a {
  display: inline-block;
  margin: 9px 3px 0px 0px;
  float: left;
  color: #141d1e;
  text-align: center;
  font-size: 33px;
  font-weight: 500;
  border-radius: 2px;
  width: 39px;
  height: 29px;
  padding-top: 4px;
}

.paragraph-b {
  display: inline-block;
  margin: 9px 10px 0px 0px;
  float: left;
  border-radius: 1000px;
  background: #bd8100;
  color: #fff;
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  width: 32px;
  height: 29px;
  padding-top: 3px;
}

.about-content {
  font-family: 'Raleway';
}

.about-content .page-lbl p {
  font-size: 15px;
  color: #455051;
}

.about-content .page-lbl {
  margin-bottom: 10px;
}

.about-text {
  margin-bottom: 39px;
}

.about-text p {
  font-size: 14px;
  line-height: 24px;
  color: #626262;
}

.tree-colls-i {
  width: 31.5%;
  margin-right: 2.9%;
  float: left;
}

.about-percent label {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 7px;
  color: #626262;
}

.about-percent-a {
  width: 100%;
  height: 4px;
  background: #ebeef0;
  border-radius: 100px;
  overflow: hidden;
}

.about-percent-a span {
  display: block;
  width: 0px;
  height: 4px;
  border-radius: 100px;
  background: #bd8100;
}

.tree-colls-i:nth-child(2) {
  margin-right: 0px;
}

.tree-colls-i:last-child {
  float: right;
  margin-right: 0px;
}

.about-percent {
  margin-bottom: 19px;
}

.about-photo-image {
  margin-bottom: 21px;
  position: relative;
}

.about-photo-image img {
  width: 100%;
}

.about-photo {
  text-align: center;
}

.about-photo-lbl {
  font-weight: normal;
  font-family: 'Montserrat';
  margin-bottom: 6px;
  text-transform: uppercase;
  font-size: 14px;
  color: #141d1e;
}

.about-photo-sublbl {
  font-size: 13px;
  color: #717171;
}

.about-slider-h {
  font-family: 'Raleway';
  background: #fafafa;
  padding: 79px 0px 75px 0px;
  margin: 57px 0px 75px 0px;
  text-align: center;
}

.about-slider-h.styled {
  height: 280px;
  padding: 80px 0px 0px 0px;
  /* background: url(../public/img/about-styled.jpg) center top no-repeat; */
}

.about-slider-txt {
  font-size: 15px;
  margin-bottom: 14px;
  line-height: 26px;
  color: #626262;
}

.about-content .about-slider-txt {
  width: 640px;
  margin: 0 auto;
  margin-bottom: 14px;
}

.about-slider-txt-a {
  font-size: 13px;
  color: #7f7f7f;
}

.about-slider-img {
  margin-bottom: 18px;
}

.about-slider-h .bx-controls-direction {
  display: none;
}

.about-slider-h .bx-pager {
  text-align: center;
  margin-top: 22px;
}

.about-slider-h .bx-pager-item {
  display: inline-block;
  margin: 0px 5px 0px 5px;
}

.about-slider-h .bx-pager-item a {
  display: inline-block;
  overflow: hidden;
  text-indent: -9999px;
  width: 7px;
  height: 7px;
  border: 1px solid #b6b6b6;
  border-radius: 1000px;
}

.about-slider-h .bx-pager-item a.active {
  border-color: #ff6638;
  background: #ff6638;
}

.about-slider-h.styled .about-slider-txt,
.about-slider-h.styled .about-slider-txt-a {
  color: #ffffff;
}

.why-we {
  font-family: 'Raleway';
  padding-bottom: 114px;
}

.why-we .page-lbl p {
  font-size: 15px;
  color: #455051;
}

.why-we .page-lbl {
  margin-bottom: 18px;
}

.why-we-item {
  text-align: center;
}

.why-we-img {
  margin-bottom: 25px;
}

.why-we-lbl {
  font-size: 15px;
  font-family: 'Montserrat';
  margin-bottom: 25px;
  color: #3a4142;
  text-transform: uppercase;
}

.why-we-txt {
  font-size: 14px;
  line-height: 24px;
  color: #626262;
}

.about-two-colls-h {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.about-two-colls-l {
  width: 348px;
  float: left;
  overflow: hidden;
  font-size: 14px;
  line-height: 24px;
  color: #626262;
}

.about-two-colls-r {
  margin: 0px 0px 0px 348px;
}

.about-two-colls-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.about-two-colls-rp {
  padding: 4px 0px 0px 29px;
}

.about-us-devider {
  height: 1px;
  margin: 40px 0px 92px 0px;
  background: #f0f0f0;
}

.about-content .counters {
  padding-bottom: 96px;
}

.counters-i {
  display: inline-block;
  width: 132px;
  margin-right: 9.4%;
  float: left;
  text-align: center;
}

.counters-i:first-child {
  margin-left: 17px;
}

.counters-i:last-child {
  float: right;
  margin-right: 6px;
}

.counters-i b {
  color: #1a232b;
  display: block;
  line-height: 50px;
  margin-bottom: 5px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 50px;
}

.counters-i span {
  font-size: 12px;
  color: #1a232b;
  font-weight: 500;
  text-transform: uppercase;
}

.our-team {
  margin-bottom: 78px;
}

.our-team .page-lbl p {
  font-size: 15px;
  color: #455051;
}

.our-team .page-lbl {
  margin-bottom: 18px;
}

.team-row-h {
  padding-bottom: 55px;
}

.team-row-i {
  margin-right: 3.3%;
  width: 22.5%;
  font-family: 'Raleway';
  text-align: center;
  float: left;
}

.team-row-i:last-child {
  margin-right: 0px;
  float: right;
}

.team-row-img {
  margin-bottom: 20px;
  position: relative;
}

.team-row-img img {
  width: 100%;
}

.team-row-lbl {
  text-transform: uppercase;
  font-family: 'Montserrat';
  margin-bottom: 6px;
  font-weight: normal;
}

.team-row-txt {
  font-size: 13px;
  color: #717171;
}

.team-more-h {
  width: 92%;
  height: 89%;
  display: none;
  padding: 5% 4% 4% 4%;
  position: absolute;
  left: 0px;
  top: 0px;
}

.team-row-i:hover .team-more-h {
  display: block;
}

.about-photo:hover .team-more-h {
  display: block;
}

.team-row-b {
  margin-bottom: 77px;
}

.team-more {
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
}

.team-more-a {
  font-size: 13px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 0px 10px 0px 10px;
  line-height: 23px;
  color: #525659;
}

.team-more-social {
  text-align: center;
  padding-top: 12px;
}

.team-more-social a {
  margin: 0px 6px 0px 6px;
}

.team-fb {
  display: inline-block;
  background: url(../public/img/about-facebook.png) left top no-repeat;
  width: 8px;
  height: 13px;
}

.team-tw {
  display: inline-block;
  background: url(../public/img/about-twitter.png) left top no-repeat;
  width: 13px;
  height: 13px;
}

.team-gp {
  display: inline-block;
  background: url(../public/img/about-gp.png) left top no-repeat;
  width: 13px;
  height: 13px;
}

.team-inst {
  display: inline-block;
  background: url(../public/img/about-insta.png) left top no-repeat;
  width: 11px;
  height: 13px;
}

.team-pint {
  display: inline-block;
  background: url(../public/img/about-pinterest.png) left top no-repeat;
  width: 10px;
  height: 13px;
}

.team-vk {
  display: inline-block;
  background: url(../public/img/about-vk.png) left top no-repeat;
  width: 15px;
  height: 13px;
}

.team-more-social a:hover {
  background-position: left -13px;
}

.services {
  padding-top: 7px;
  padding-bottom: 53px;
}

.services-i {
  display: inline-block;
  float: left;
  width: 22%;
  margin-right: 4%;
  text-align: center;
}

.services-i:last-child {
  margin-right: 0px;
  float: right;
}

.services-img {
  margin-bottom: 22px;
}

.services-lbl {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 15px;
  color: #3a4142;
  font-weight: normal;
  font-family: 'Montserrat';
}

.services-txt {
  line-height: 25px;
  font-size: 14px;
  color: #687172;
}

.solutions {
  font-family: 'Raleway';
  padding: 5px 0px 100px 0px;
}

.solutions .page-lbl p {
  font-size: 15px;
  color: #455051;
}

.solutions .page-lbl {
  margin-bottom: 18px;
}

.solutions-i {
  position: relative;
  margin-right: 2.7%;
  float: left;
  cursor: pointer;
  width: 31.5%;
}

.solutions-i:last-child {
  float: right;
  margin-right: 0px;
}

.solutions-img {
  line-height: 0px;
}

.solutions-img img {
  width: 100%;
  height: 100%;
  line-height: 0px;
  margin: 0px;
  padding: 0px;
}

.solutions-over {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
}

.solutions-over-a {
  display: table;
  width: 100%;
  height: 100%;
}

.solutions-over-b {
  display: table-cell;
  color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.solutions-over-d {
  display: none;
}

.solution-icon {
  display: inline-block;
  width: 16px;
  height: 10px;
  background: url(../public/img/icon-solution.png) left top no-repeat;
}

.solution-lbl {
  text-transform: uppercase;
  margin-top: 11px;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Montserrat';
}

.solution-txt {
  font-size: 14px;
  padding: 0px 10px 0px 10px;
  line-height: 26px;
  margin-top: 5px;
}

.solution-icon-a {
  display: block;
  background: url(../public/img/solutions-i-a.png) left top no-repeat;
  position: absolute;
  left: 50%;
  margin-left: -4px;
  bottom: 18px;
  z-index: 999;
  width: 8px;
  height: 8px;
}

.solution-partners {
  background: #fafafa;
  padding: 94px 0px 93px 0px;
}

.solution-partners .page-lbl p {
  font-size: 15px;
  color: #455051;
}

.solution-partners .page-lbl {
  margin-bottom: 18px;
}

.solutions-partners-row {
  padding: 0px 10px 0px 10px;
}

.solutions-partners-row a {
  float: left;
  margin-right: 5%;
  opacity: 0.65;
}

.solutions-partners-row a:last-child {
  margin-right: 0px;
  float: right;
}

.solutions-partners-row a:hover {
  opacity: 1;
}

.solution-partners .partners {
  padding: 0px 10px 0px 10px;
  border: 0px;
  margin: 0px;
}

.cover_boxes {
  overflow: hidden;
  position: relative;
}

.cover_boxes ul {
  list-style: outside none none;
  margin: 0 -20px 0 0;
  padding: 0;
  width: 200%;
}

.cover_boxes ul li {
  float: left;
  margin-right: 17px;
  margin-bottom: 28px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease 0s;
  width: 265px;
}

.cover_boxes ul li.act {
  width: 530px;
  margin-right: 34px;
}

.cover_boxes ul li .box {
  width: 530px;
}

.cover_boxes ul li .box .thumb {
  display: inline-block;
  float: left;
  margin-right: 17px;
  position: relative;
  z-index: 10;
}

.cover_boxes ul li .box .thumb img {
  display: block;
  max-width: 100%;
  position: relative;
}

.cover_boxes ul li .box .box_content {
  bottom: 0;
  left: 281px;
  padding: 0;
  position: absolute;
  top: 0;
  width: 245px;
  padding-top: 1px;
  padding-right: 16px;
  z-index: 9;
}

.cover_boxes ul li .box h3 {
  font-size: 15px;
  margin-bottom: 16px;
  color: #3a4142;
  font-weight: 500;
  text-transform: uppercase;
}

.cover_boxes ul li .box p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #687172;
  line-height: 25px;
}

.cover_boxes ul li .box .qbutton,
.cover_boxes ul li .box h5 {
  margin: 20px 0 0;
}

.ga_button {
  width: 100px;
  height: 22px;
  padding-top: 10px;
  text-transform: uppercase;
  color: #6a7880;
  font-size: 11px;
  font-weight: 600;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  display: block;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.ga_button:hover {
  color: #fe6739;
}

.cover_gallery {
  padding-bottom: 119px;
  padding-top: 7px;
}

.portfolio-filters {
  text-align: center;
  padding: 74px 0px 50px 0px;
  font-family: 'Raleway';
}

.portfolio-filters ul li {
  display: inline-block;
  margin: 0px 5px 0px 5px;
}

.portfolio-filters ul li a {
  font-size: 12px;
  border-bottom: 2px solid transparent;
  display: inline-block;
  padding-bottom: 4px;
  color: #141d1e;
  text-transform: uppercase;
  text-decoration: none;
}

.portfolio-filters ul li a:hover {
  color: #515e5f;
}

.portfolio-filters ul li.active a {
  border-bottom: 2px solid #ff6638;
  color: #ff6638;
}

.portfolio-holder {
  overflow: hidden;
  max-width: 1128px;
  margin: 0 auto;
}

.portfolio-i {
  width: 22.4%;
  margin-bottom: 28px;
  margin-right: 1.3%;
  margin-left: 1.3%;
  text-align: center;
}

.portfolio-three-colls .portfolio-i {
  margin-bottom: 30px;
  width: 30.5%;
  margin-right: 1.4%;
  margin-left: 1.4%;
}

.portfolio-i-img {
  position: relative;
  overflow: hidden;
  line-height: 0px;
  cursor: pointer;
}

.portfolio-i-img img {
  width: 100%;
  -webkit-transition: all 1s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.portfolio-i:hover img {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.portfolio-i-text {
  font-family: 'Raleway';
  background: #fff;
  height: 77px;
}

.portfolio-i-text b {
  text-transform: uppercase;
  padding: 20px 0px 3px 0px;
  font-size: 13px;
  color: #3a4142;
  font-weight: normal;
  font-family: 'Montserrat';
  display: block;
}

.portfolio-i-text span {
  font-size: 12px;
  color: #687172;
}

.portfolio-i-over {
  position: absolute;
  z-index: 99;
  display: none;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
}

.portfolio-i-over-a {
  display: table;
  width: 100%;
  height: 100%;
}

.portfolio-i-over-b {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.portfolio-zoom {
  display: inline-block;
  background: url(../public/img/portfolio-zoom.png) left top no-repeat;
  margin-right: 1px;
  width: 38px;
  height: 38px;
}

.portfolio-more {
  display: inline-block;
  background: url(../public/img/portfolio-more.png) left top no-repeat;
  margin-left: 1px;
  width: 38px;
  height: 38px;
}

.portfolio-zoom:hover,
.portfolio-more:hover {
  opacity: 0.8;
}

.portfolio-i:hover .portfolio-i-over {
  display: block;
}

.portfolio-four-colls {
  padding-bottom: 95px;
}

.portfolio-three-colls {
  padding-bottom: 70px;
}

.portfolio-more-btn {
  padding-bottom: 111px;
}

.portfolio-more-btn a {
  font-size: 12px;
  text-transform: uppercase;
  color: #6a7880;
  font-weight: 600;
  font-family: 'Raleway';
  display: block;
  width: 180px;
  float: none;
  height: 30px;
  padding-top: 13px;
  background: #fff;
  text-align: center;
  text-decoration: none;
  background: #fff;
  border-radius: 2px;
  margin: 0 auto;
}

.portfolio-more-btn a:hover {
  color: #bd8100;
}

.p-item-page {
  width: 100%;
  padding-bottom: 54px;
  float: left;
  font-family: 'Raleway';
}

.p-item-page-l {
  margin: 3px 348px 0px 0px;
}

.p-item-page-lb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.p-item-page-r {
  width: 348px;
  font-family: 'Raleway';
  float: left;
  overflow: hidden;
  margin: 0 0 0 -348px;
}

.p-item-page-r h3 {
  font-weight: normal;
  font-family: 'Montserrat';
  color: #3a4142;
  font-size: 13px;
  text-transform: uppercase;
}

.p-item-details {
  padding-top: 14px;
  padding-bottom: 29px;
}

.p-item-details-i {
  font-size: 13px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 7px;
  margin-bottom: 7px;
  color: #687172;
}

.p-item-details-il {
  width: 90px;
  float: left;
}

.p-item-details-ir {
  float: left;
}

.p-item-more {
  font-size: 13px;
  color: #687172;
  line-height: 24px;
  padding: 9px 0px 32px 0px;
  font-family: 'Raleway';
}

.p-item-social a {
  margin-right: 21px;
}

.p-item-social a:hover {
  background-position: left -13px;
}

.p-item-slide,
.p-item-slide img {
  width: 100%;
}

.p-item-padding {
  margin-right: 31px;
  position: relative;
}

.p-item-padding .bx-pager {
  display: none;
}

.p-item-padding .bx-prev,
.p-item-padding .bx-next {
  width: 44px;
  height: 44px;
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  position: absolute;
  top: 50%;
  margin-top: -22px;
}

.p-item-padding .bx-prev {
  background: url(../public/img/p-slider-l.png) left top no-repeat;
  left: 21px;
}

.p-item-padding .bx-next {
  background: url(../public/img/p-slider-r.png) left top no-repeat;
  right: 21px;
}

.portfolio-nav {
  display: table;
  width: 100%;
  margin-bottom: 50px;
}

.portfolio-nav.no-margin {
  padding-bottom: 50px;
}

.portfolio-nav-l {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
}

.portfolio-nav-c {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.portfolio-nav-r {
  display: table-cell;
  text-align: right;
  vertical-align: middle;
}

.portfolio-nav-l a {
  display: inline-block;
  background: url(../public/img/portfolio-l.png) left top no-repeat;
  width: 36px;
  height: 36px;
}

.portfolio-nav-r a {
  display: inline-block;
  background: url(../public/img/portfolio-r.png) left top no-repeat;
  width: 36px;
  height: 36px;
}

.portfolio-nav-l a:hover,
.portfolio-nav-r a:hover {
  background-position: left -36px;
}

.portfolio-nav-c a {
  display: inline-block;
  opacity: 0.6;
  background: url(../public/img/portfolio-all.png) left top no-repeat;
  width: 19px;
  height: 19px;
}

.portfolio-nav-c a:hover {
  opacity: 1;
}

.portfolio-full {
  margin-bottom: 54px;
}

.portfolio-full-img img {
  width: 100%;
}

.portfolio-full-more {
  width: 100%;
  float: left;
  padding-bottom: 26px;
}

.portfolio-full-more-l {
  width: 345px;
  float: left;
  overflow: hidden;
}

.portfolio-full-more-r {
  margin: 0px 0px 0px 345px;
}

.portfolio-full-more-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.portfolio-full-more .p-item-details {
  padding-bottom: 22px;
}

.portfolio-full-more .p-item-details-i {
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.portfolio-full-more h3 {
  font-weight: normal;
  font-family: 'Montserrat';
  color: #3a4142;
  font-size: 13px;
  text-transform: uppercase;
}

.portfolio-full-more-txt {
  width: 48%;
  float: right;
  font-size: 13px;
  color: #687172;
  line-height: 24px;
  padding: 9px 0px 32px 0px;
  font-family: 'Raleway';
}

.portfolio-full-more-txt:nth-child(2n) {
  float: left;
}

.portfolio-full-more-p {
  padding-left: 30px;
}

.portfolio-full-more-r h3 {
  margin-bottom: 9px;
}



.blog-sidebar {
  width: 100%;
  float: left;
  font-family: 'Raleway';
}

.blog-sidebar-l {
  margin: 0px 255px 0px 0px;
}

.blog-sidebar-lb {
  width: 100%;
  float: left;
}

.blog-sidebar-p {
  padding-right: 20px;
}

.blog-sidebar-r {
  width: 255px;
  float: left;
  margin: 4px 0 0 -256px;
}

.blog-post-i {
  width: 100%;
  float: left;
}

.blog-post-l {
  width: 127px;
  float: left;
  text-align: right;
}

.blog-post-c {
  margin: 0px 0px 0px 127px;
}

.blog-post-cb {
  width: 100%;
  float: left;
}

.blog-post-p {
  padding-left: 25px;
  padding-right: 8px;
  padding-top: 3px;
}

.blog-post {
  padding-bottom: 29px;
  margin-bottom: 47px;
  border-bottom: 1px solid #ededed;
}

.blog-post-date {
  border-bottom: 1px solid #ededed;
  padding-bottom: 20px;
  margin-bottom: 9px;
}

.blog-post-date b {
  display: block;
  font-weight: normal;
  font-family: 'Montserrat';
  margin-bottom: 2px;
  font-size: 28px;
  color: #141d1e;
}

.blog-post-date span {
  display: block;
  font-size: 11px;
  color: #6b7373;
}

.blog-post-info {
  font-size: 11px;
  color: #6b7373;
  text-transform: uppercase;
  line-height: 28px;
}

.blog-post-info div {
  margin-bottom: 1px;
}

.blog-post-title {
  margin-bottom: 23px;
}

.blog-post-title a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 17px;
  color: #141d1e;
  font-family: 'Montserrat';
  font-weight: normal;
}

.blog-post-img img {
  width: 100%;
}

.blog-post-txt {
  font-size: 14px;
  color: #626262;
  margin-bottom: 22px;
  line-height: 25px;
}

.blog-post-preview {
  margin-bottom: 17px;
}

.blog-post-more {
  width: 86px;
  height: 21px;
  padding-top: 10px;
  text-transform: uppercase;
  color: #6a7880;
  font-size: 10px;
  font-weight: 700;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  display: block;
  text-align: center;
  text-decoration: none;
}

.blog-post-more:hover {
  color: #fe6739;
}

.blog-post-slider-i img {
  width: 100%;
}

.blog-post-slider {
  position: relative;
}

.blog-post-slider .bx-pager {
  display: none;
}

.blog-post-slider .bx-prev,
.blog-post-slider .bx-next {
  width: 44px;
  height: 44px;
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  position: absolute;
  top: 50%;
  margin-top: -22px;
}

.blog-post-slider .bx-prev {
  background: url(../public/img/p-slider-l.png) left top no-repeat;
  left: 21px;
}

.blog-post-slider .bx-next {
  background: url(../public/img/p-slider-r.png) left top no-repeat;
  right: 21px;
}

.blog-post-qoute {
  border-left: 2px solid #bd8100;
  padding: 0px 0px 0px 17px;
}

.blog-post-qoute-a {
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 9px;
  color: #414f51;
}

.blog-post-qoute-b {
  font-size: 13px;
  color: #6b7373;
}

.blog-post.single-post {
  border: 0px;
}

.mejs-container .mejs-controls {
  background: #fafafa;
}

.mejs-controls .mejs-play button {
  width: 11px;
  height: 11px;
  margin-top: 10px;
  margin-left: 10px;
  background: url(../public/img/btn-play.gif) left top no-repeat;
}

.mejs-controls .mejs-pause button {
  background: url("../public/img/pause.png") left top no-repeat !important;
  margin-left: 9px !important;
  margin-top: 11px !important;
}

.mejs-controls .mejs-fullscreen-button button {
  margin-top: 10px;
  width: 10px;
  height: 10px;
  /* background: url(../public/img/full-btn.png) left top no-repeat; */
}

.mejs-controls .mejs-pause button {
  width: 11px;
  height: 11px;
  margin-top: 10px;
  margin-left: 9px;
  background: url(../public/img/btn-play.gif) left top no-repeat;
  background-position: 0 0;
}

.mejs-controls .mejs-time-rail .mejs-time-total {
  margin-top: 8px;
  background: #dcdcdc;
  height: 6px;
}

.mejs-controls .mejs-time-rail span {
  height: 6px;
}

.mejs-controls .mejs-time-rail .mejs-time-loaded {
  border: 0px;
}

.mejs-controls .mejs-time-rail .mejs-time-current {
  background: #bd8100;
  border: 0px;
}

.mejs-controls .mejs-time-rail span {
  border-radius: 0px;
}

.mejs-overlay-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  margin-left: -15px;
  margin-top: -15px !important;
  /* background: url(../public/img/video-play.png) no-repeat; */
}

.mejs-controls .mejs-mute button {
  margin-top: 11px;
  width: 9px;
  height: 10px;
  /* background: url(../public/img/vol-btn.png) left top no-repeat; */
}

.mejs-container {
  width: 100% !important;
  height: 30px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  /*background: transparent url(../public/img/audio-bg.gif) left top repeat-x !important;*/
}

.mejs-overlay:hover .mejs-overlay-button {
  /* background: url(../public/img/video-play.png) no-repeat; */
  background-position: left 0px;
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  border-radius: 0px;
  /* background: url(../public/img/v-current.png) left top repeat-x; */
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
  height: 6px;
  border-radius: 0px;
  margin-top: 2px;
  background: #dcdcdc;
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  background: #bd8100;
  top: 13px;
  height: 6px;
}

.mejs-controls .mejs-unmute button {
  margin-top: 11px;
  width: 9px;
  height: 10px;
  /* background: url(../public/img/vol-btn.png) left top no-repeat; */
  background-position: left 0 0;
}

.mejs-container .mejs-controls .mejs-time span {
  font-size: 10px;
  color: #898989 !important;
  font-family: 'Open Sans';
}

.blog-pagination {
  margin-bottom: 123px;
  margin-left: 152px;
}

.blog-pagination li {
  float: left;
  margin: 0px 5px 0px 0px;
}

.blog-pagination a {
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  font-family: 'Open Sans';
  border-radius: 2px;
  color: #797979;
  border: 1px solid #c3c3c3;
  opacity: 0.7;
  display: block;
  width: 31px;
  height: 25px;
  padding-top: 6px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.blog-pagination a:hover {
  opacity: 1;
}

.blog-pagination li:first-child a,
.blog-pagination li:last-child a {
  padding-top: 0px;
  height: 31px;
}

.blog-widget {
  font-family: 'Raleway';
  margin-bottom: 38px;
}

.blog-widget h2 {
  font-size: 13px;
  color: #3a4142;
  margin-bottom: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  text-transform: uppercase;
}

.search-widget {
  margin-bottom: 46px;
}

.search-widget input[type='text'] {
  width: 234px;
  margin-top: 22px;
  padding: 10px 10px 10px 10px;
  color: #6a7880;
  font-family: 'Raleway';
  font-size: 11px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  display: block;
  text-decoration: none;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.text-widget {
  margin-bottom: 39px;
}

.text-widget p {
  font-size: 13px;
  color: #455051;
  line-height: 25px;
}

.recent-widget {
  margin-bottom: 47px;
}

.recent-widget nav li a {
  text-decoration: none;
  padding: 17px 0px 15px 0px;
  display: block;
  font-size: 13px;
  color: #455051;
  border-bottom: 1px solid #ededed;
}

.recent-widget nav li:first-child a {
  padding-top: 5px;
}

.tweeter-widget {
  margin-bottom: 46px;
}

.tweeter-item {
  padding-bottom: 12px;
  margin-bottom: 13px;
  border-bottom: 1px solid #ededed;
}

.tweeter-item-l {
  width: 12px;
  margin-top: 8px;
  height: 10px;
  background: url(../public/img/tweeter-i.png) left top no-repeat;
  float: left;
}

.tweeter-item-r {
  line-height: 25px;
  width: 234px;
  float: right;
}

.tweeter-item-r span {
  font-size: 13px;
  display: block;
  color: #455051;
}

.tweeter-item-r b {
  font-size: 13px;
  color: #848b8c;
  font-weight: normal;
}

.tags-row {
  padding-top: 7px;
}

.tags-row a {
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #858585;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  padding: 7px 12px 6px 12px;
  text-decoration: none;
  font-weight: 600;
  font-size: 10px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.tags-row a:hover {
  color: #bd8100;
}

.blog-masonry {
  font-family: 'Raleway';
  margin: 0 auto;
  max-width: 1128px;
}

.blog-masonry-i {
  margin-bottom: 38px;
  opacity: 0;
  margin-left: 1.44%;
  margin-right: 1.44%;
  width: 30.4%;
}

.blog-masonry-lbl {
  margin-bottom: 11px;
}

.blog-masonry-lbl a {
  text-decoration: none;
  font-size: 15px;
  font-family: 'Montserrat';
  color: #141d1e;
  text-transform: uppercase;
}

.blog-masonry-txt {
  font-size: 14px;
  margin-bottom: 12px;
  color: #626262;
  line-height: 25px;
}

.blog-masonry-preview {
  margin-bottom: 22px;
}

.blog-masonry-gallery-i {
  width: 100%;
}

.blog-masonry-img img,
.blog-masonry-gallery-i img {
  width: 100%;
}

.blog-masonry-info {
  font-size: 14px;
  color: #868d8e;
}

.blog-masonry-preview {
  position: relative;
}

.blog-masonry-preview .bx-pager {
  display: none;
}

.blog-masonry-preview .bx-prev,
.blog-masonry-preview .bx-next {
  width: 44px;
  height: 44px;
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  position: absolute;
  top: 50%;
  margin-top: -22px;
}

.blog-masonry-preview .bx-prev {
  background: url(../public/img/p-slider-l.png) left top no-repeat;
  left: 14px;
}

.blog-masonry-preview .bx-next {
  background: url(../public/img/p-slider-r.png) left top no-repeat;
  right: 14px;
}

.blog-masonry-quote-a {
  font-size: 16px;
  color: #414f51;
  padding: 0px 0px 0px 26px;
  margin-bottom: 9px;
  line-height: 27px;
  background: url(../public/img/quote-icon.png) left top no-repeat;
  background-position: left 7px;
}

.blog-masonry-quote-b {
  font-size: 13px;
  color: #6b7373;
  padding-left: 26px;
}

.blog-masonry-quote {
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  padding: 16px 19px 21px 15px;
  margin-bottom: 19px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.blog-more {
  text-align: center;
  padding-bottom: 107px;
  padding-top: 9px;
}

.blog-more a {
  display: inline-block;
  font-size: 12px;
  color: #707070;
  font-family: 'Raleway';
  font-weight: 600;
  width: 173px;
  height: 27px;
  padding-top: 13px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.04);
}

.blog-more a:hover {
  color: #bd8100;
}


.text-quote {
  border-left: 2px solid #bd8100;
  margin-bottom: 12px;
  padding-left: 16px;
}

.text-quote-a {
  margin-bottom: 4px;
  line-height: 28px;
  font-size: 16px;
  color: #414f51;
}

.text-quote-b {
  font-size: 13px;
  color: #6b7373;
}

.post-tags {
  margin-bottom: 48px;
}

.post-tags a {
  font-size: 13px;
  color: #484d4e;
  text-decoration: none;
}

.post-tags span {
  font-size: 13px;
  color: #484d4e;
}

.post-tags a:hover {
  color: #bd8100;
}

.blog-comments {
  margin-bottom: 56px;
}

.blog-comment-i {
  padding-bottom: 24px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ededed;
}

.blog-comment-i .guest-reviews-l {
  margin-top: 4px;
}

.blog-comments-lbl {
  font-weight: normal;
  font-family: 'Montserrat';
  margin-bottom: 26px;
  font-size: 17px;
  color: #141d1e;
  text-transform: uppercase;
}

.blog-comment-lbl {
  font-family: 'Montserrat';
  font-size: 14px;
  margin-bottom: 13px;
  color: #141d1e;
  text-transform: uppercase;
  font-weight: normal;
}

.blog-comment-info {
  font-size: 13px;
  color: #6b7373;
  margin-bottom: 9px;
}

.blog-comment-info a {
  text-decoration: none;
  color: #bd8100;
  margin-left: 14px;
}

.post-comment {
  padding-bottom: 66px;
}

.post-comment .booking-form-i {
  width: 100%;
  float: none;
}

.blog-comment-txt {
  line-height: 25px;
  font-size: 14px;
  color: #687172;
}

.post-comment-btn {
  display: block;
  margin-top: 25px;
  padding-top: 2px;
  width: 124px;
  height: 36px;
  background: #bd8100;
  color: #fff;
  text-transform: uppercase;
  border: 0px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: normal;
}

.post-comment-btn:hover {
  background: #ff8521;
}

.shareholder {
  position: relative;
}

.share-popup {
  background: url(../public/img/share-popup.png) left top no-repeat;
  display: none;
  position: absolute;
  top: 28px;
  right: 3px;
  width: 29px;
  height: 79px;
  padding-top: 10px;
}

.share-popup a {
  width: 12px;
  height: 12px;
  display: block;
  margin: 0 auto;
  margin-bottom: 13px;
}

.shareholder span {
  cursor: pointer;
}

.content-wrapper.columns {
  max-width: 1140px;
}

.columns {
  padding-bottom: 34px;
}

.columns-block-lbl {
  padding-left: 1.8%;
  font-family: 'Montserrat';
  font-size: 15px;
  color: #141d1e;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 13px;
}

.columns-block-lbl span {
  background: url(../public/img/columns-bg.png) left bottom no-repeat;
  padding-bottom: 18px;
  display: block;
}

.mm-12 {
  width: 70%;
  padding: 0px 1.7% 0px 1.7%;
  float: left;
}

.mm-6 {
  width: 63%;
  padding: 0px 1.7% 0px 1.7%;
  float: left;
}

.mm-4 {
  width: 21%;
  padding: 0px 1.7% 0px 1.7%;
  float: left;
}

.mm-3 {
  width: 29.7%;
  padding: 0px 1.7% 0px 1.7%;
  float: left;
}

.mm-2 {
  width: 46.5%;
  padding: 0px 1.7% 0px 1.7%;
  float: left;
}

.columns-block {
  margin-bottom: 48px;
}

.column {
  font-size: 14px;
  color: #606869;
  line-height: 25px;
}

.columns-block {
  font-family: 'Raleway';
}

.typography {
  font-family: 'Raleway';
  padding-bottom: 104px;
}

.typography-heading {
  text-align: center;
  margin-bottom: 35px;
  font-size: 15px;
  color: #141d1e;
  font-weight: 500;
  text-transform: uppercase;
  background: url("../public/img/columns-bg.png") center bottom no-repeat;
  padding-bottom: 17px;
}

.typography h1 {
  font-family: 'Montserrat';
  margin-bottom: 11px;
  text-transform: uppercase;
  font-size: 23px;
  color: #141d1e;
  font-weight: normal;
}

.typography h2 {
  font-family: 'Montserrat';
  margin-bottom: 11px;
  text-transform: uppercase;
  font-size: 20px;
  color: #141d1e;
  font-weight: normal;
}

.typography h3 {
  font-family: 'Montserrat';
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  color: #141d1e;
  font-weight: normal;
}

.typography h4 {
  font-family: 'Montserrat';
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  color: #141d1e;
  font-weight: normal;
}

.typography h5 {
  font-family: 'Montserrat';
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #141d1e;
  font-weight: normal;
}

.typography p {
  font-size: 14px;
  color: #606869;
  line-height: 25px;
}

.typography-block {
  margin-bottom: 29px;
}

.block-qoutes {
  padding: 20px 0px 0px 0px;
}

.blockqoute-tp-a {
  border-left: 2px solid #bd8100;
  margin-bottom: 12px;
  padding-left: 17px;
}

.blockqoute-tp-a span {
  display: block;
  margin-bottom: 7px;
  font-size: 16px;
  color: #414f51;
  line-height: 27px;
}

.blockqoute-tp-a b {
  font-weight: normal;
  font-size: 13px;
  padding-bottom: 5px;
  display: block;
  color: #6b7373;
}

.blockqoute-tp-a.float-left {
  width: 320px;
  float: left;
  margin-top: 5px;
  margin-right: 40px;
}

.blockqoute-tp-a.float-right {
  width: 320px;
  float: right;
  margin-top: 5px;
  margin-left: 40px;
}

.highlights {
  padding: 35px 0px 0px 0px;
}

.hightile-a {
  display: inline-block;
  background: #bd8100;
  color: #fff;
  border-radius: 2px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 3px;
  margin-right: 3px;
}

.hightile-b {
  display: inline-block;
  background: #141d1e;
  color: #fff;
  border-radius: 2px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 3px;
  margin-right: 3px;
}

.hightile-c {
  display: inline-block;
  background: #e7e7e7;
  color: #2c3132;
  border-radius: 2px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 3px;
  margin-right: 3px;
}

.dropcaps {
  padding: 35px 0px 0px 0px;
}

.accordeons-toggles {
  padding-bottom: 47px;
}

.shortcodes-left {
  width: 48.8%;
  float: left;
}

.shortcodes-right {
  width: 48.8%;
  float: right;
}

.toggle {
  margin-top: 1px;
}

.toggle-ia-a {
  width: 100%;
  float: left;
}

.toggle-ia-l {
  width: 58px;
  float: left;
  overflow: hidden;
}

.toggle-ia-r {
  margin: 0px 0px 0px 58px;
}

.toggle-ia-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.toggle-trigger {
  width: 40px;
  height: 40px;
  display: block;
  background: url(../public/img/toggle.png) left top no-repeat;
}

.toggle-lbl {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 11px;
  color: #4f5657;
  text-transform: uppercase;
  font-weight: 500;
}

.toggle-txt {
  display: none;
  padding-bottom: 7px;
  padding-top: 2px;
  font-size: 14px;
  color: #606869;
  line-height: 25px;
}

.toggle-i {
  margin-bottom: 10px;
}

.toggle-i.open .toggle-trigger {
  background-position: left -40px;
}

.toggle-i:first-child .toggle-txt {
  display: block;
}

.accordeon-a {
  cursor: pointer;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  padding: 12px 13px 11px 13px;
}

.accordeon-icon {
  display: block;
  margin: 3px 18px 0px 0px;
  background: url(../public/img/accordeon-i.png) left top no-repeat;
  float: left;
  width: 10px;
  height: 10px;
}

.accordeon-a span {
  font-weight: 500;
  font-size: 14p;
  color: #4f5657;
  text-transform: uppercase;
  float: left;
}

.accordeon-b {
  font-size: 14px;
  display: none;
  color: #606869;
  line-height: 25px;
  padding: 16px 0px 6px 43px;
}

.accordeon-item {
  margin-bottom: 10px;
}

.accordeon-item:first-child .accordeon-b {
  display: block;
}

.accordeon-item.open .accordeon-icon {
  background-position: left -10px;
}

.tabs {
  padding-bottom: 34px;
}

.tabs .typography-heading {
  margin-bottom: 42px;
}

.tabs-type-a .tabs-nav li {
  float: left;
  margin-right: 4px;
}

.tabs-type-a .tabs-nav a {
  display: inline-block;
  font-size: 12px;
  color: #656b70;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  padding: 13px 20px 13px 20px;
}

.tabs-type-a .tabs-nav a:hover {
  color: #30353a;
}

.tabs-type-a .tabs-content {
  padding-top: 15px;
}

.tabs-type-a .tabs-nav a.active {
  color: #bd8100;
}

.tabs-content-i {
  padding-bottom: 5px;
  padding-top: 3px;
  display: none;
}

.tabs-content-i:first-child {
  display: block;
}

.tabs-content-i p {
  margin-bottom: 24px;
}

.tabs-content-i p:last-child {
  margin-bottom: 0px;
}

.tabs-type-bi-a {
  width: 100%;
  float: left;
}

.tabs-type-bi-l {
  width: 128px;
  float: left;
}

.tabs-type-bi-r {
  margin: 0px 0px 0px 128px;
}

.tabs-type-bi-rb {
  width: 100%;
  float: left;
  border-left: 1px solid #e3e3e3;
  overflow: hidden;
}

.tabs-type-b .tabs-nav a {
  text-align: center;
  display: block;
  font-size: 12px;
  position: relative;
  border: 1px solid transparent;
  border-right: 0px;
  border-radius: 2px;
  color: #656b70;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  padding: 14px 0px 13px 0px;
}

.tabs-type-b .tabs-nav a span {
  display: none;
  width: 5px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0px;
  right: -2px;
}

.tabs-type-b .tabs-nav a.active,
.tabs-type-b .tabs-nav a.active:hover {
  color: #bd8100;
  border: 1px solid #e3e3e3;
  border-right: 0px;
}

.tabs-type-b .tabs-nav a.active span {
  display: block;
}

.tabs-type-b .tabs-nav a:hover {
  color: #30353a;
}

.tabs-type-bi-p {
  padding-left: 13px;
}

.message-box {
  padding-bottom: 44px;
}

.message-box .typography-heading {
  margin-bottom: 41px;
}

.message-box-a {
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  margin-bottom: 12px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  color: #555a5e;
  text-align: center;
  padding: 21px 0px 20px 0px;
}

.message-box-b {
  border: 1px solid #f7f7f7;
  background: #f7f7f7;
  margin-bottom: 12px;
  border-radius: 2px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  color: #30353a;
  text-align: center;
  padding: 21px 0px 20px 0px;
}

.message-box-c {
  border: 1px solid #bd8100;
  background: #bd8100;
  margin-bottom: 12px;
  border-radius: 2px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  text-align: center;
  padding: 21px 0px 20px 0px;
}

.list-elements {
  padding-bottom: 39px;
}

.list-elements .typography-heading {
  margin-bottom: 37px;
}

.list-row-i {
  float: left;
  width: 31%;
  margin-right: 3.5%;
}

.list-row-i:last-child {
  float: right;
  margin: 0px;
}

.marked-a li {
  line-height: 18px;
  background: url(../public/img/marked-01.png) left top no-repeat;
  font-size: 14px;
  color: #606869;
  padding: 0px 0px 0px 18px;
  margin-bottom: 13px;
  background-position: left 3px;
}

.marked-b li {
  line-height: 18px;
  background: url(../public/img/marked-02.png) left top no-repeat;
  font-size: 14px;
  color: #606869;
  padding: 0px 0px 0px 18px;
  margin-bottom: 13px;
  background-position: left 2px;
}

.marked-c li {
  line-height: 18px;
  background: url(../public/img/marked-03.png) left top no-repeat;
  font-size: 14px;
  color: #606869;
  padding: 0px 0px 0px 18px;
  margin-bottom: 13px;
  background-position: left 2px;
}

.small-push-3 {
  position: static;
  display: block;
  width: 200px;
  margin: 0 auto;
  padding: 0px !important;
  float: none;
}

.small-push-3 a {
  width: auto;
  display: block;
}

.tables {
  padding-bottom: 20px;
}

.tables .typography-heading {
  margin-bottom: 40px;
}

.table-a {
  width: 100%;
  border-collapse: collapse;
}

.table-a th,
.table-a td {
  border: 1px solid #e6e6e6;
  font-family: 'Raleway';
}

.table-a th {
  font-weight: 600;
  padding: 13px 14px 13px 14px;
  text-transform: uppercase;
  font-size: 12px;
  color: #2f3341;
  text-align: left;
}

.table-a td {
  font-family: 'Open Sans';
  padding: 12px 14px 12px 14px;
  font-size: 13px;
  color: #6b7373;
}

.table-a tr:nth-child(2n+1) td {
  background: #fcfcfc;
}

.table-a.light tr th,
.table-a.light tr td {
  border-left: 0px;
  border-right: 0px;
}

.icon_combination {
  padding-bottom: 89px;
}

.icon_combination .typography-heading {
  margin-bottom: 40px;
  font-family: 'Montserrat';
}

.icons-three-colls .icons-item {
  width: 30.6%;
  margin-right: 4%;
  text-align: center;
  float: left;
}

.icons-three-colls .icons-item:last-child {
  float: right;
  margin: 0px;
}

.icons-two-colls {
  padding-top: 66px;
}

.icons-two-colls .icons-item {
  width: 20.5%;
  margin-right: 6%;
  text-align: center;
  float: left;
}

.icons-two-colls .icons-item:last-child {
  float: right;
  margin: 0px;
}

.icons-item-img {
  margin-bottom: 21px;
}

.icons-item-lbl {
  font-family: 'Montserrat';
  margin-bottom: 16px;
  text-transform: uppercase;
  color: #3a4142;
  font-weight: normal;
}

.icons-item-txt {
  font-size: 14px;
  color: #606869;
  line-height: 25px;
}

.typography-heading {
  font-family: 'Montserrat';
}

.counters {
  padding-bottom: 93px;
}

.counters .typography-heading {
  margin-bottom: 28px;
  font-family: 'Montserrat';
}

.counters-five-colls .counters-row-i {
  float: left;
  margin-right: 7.2%;
  width: 14%;
  text-align: center;
}

.counters-five-colls .counters-row-i:last-child {
  float: right;
  margin: 0px;
}

.counters-five-colls b {
  background: url(../public/img/counters-d.png) center bottom no-repeat;
  padding-bottom: 9px;
  margin-bottom: 9px;
}

.counters-four-colls {
  padding-top: 55px;
}

.counters-four-colls .counters-row-i {
  float: left;
  margin-right: 2%;
  width: 23.5%;
  text-align: center;
}

.counters-four-colls .counters-row-i:last-child {
  float: right;
  margin: 0px;
}

.counters-four-colls b {
  margin-bottom: 7px;
}

.counters-row-i b {
  display: block;
  font-size: 50px;
  color: #373737;
  font-weight: 300;
}

.counters-row-i span {
  display: block;
  font-size: 12px;
  color: #4d4d4d;
  font-weight: 500;
  text-transform: uppercase;
}

.counters-row-i img {
  margin-bottom: 2px;
}

.progress-bars {
  padding-bottom: 77px;
}

.progress-bars .typography-heading {
  margin-bottom: 37px;
}

.chart {
  position: relative;
  display: inline-block;
  width: 131px;
  height: 131px;
  text-align: center;
  font-family: 'Open Sans';
  font-weight: 600;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  line-height: 131px;
  z-index: 2;
  padding-left: 7px;
}

.percent:after {
  content: '%';
  font-family: 'Open Sans';
  font-weight: 600;
  margin-left: 0.1em;
  font-size: 15px;
}

.pie-charts {
  padding-bottom: 89px;
}

.pie-charts .typography-heading {
  margin-bottom: 40px;
}

.pie-charts-i {
  width: 20.1%;
  margin-right: 6.5%;
  text-align: center;
  float: left;
}

.pie-charts-i:last-child {
  float: right;
  margin: 0px;
}

.pie-charts-a {
  margin-bottom: 22px;
}

.pie-charts-lbl {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 8px;
  color: #3a4142;
  font-weight: 500;
  padding-bottom: 11px;
  background: url(../public/img/columns-bg.png) center bottom no-repeat;
}

.pie-charts-txt {
  font-size: 14px;
  color: #6b7373;
  line-height: 25px;
}

.align-right {
  text-align: right;
}

.interactive-block .typography-heading {
  margin-bottom: 40px;
}

.interactive-inverse {
  padding-top: 19px;
}

.inter-i {
  margin-bottom: 34px;
}

.inter-ia {
  width: 100%;
  float: left;
}

.inter-i-l {
  width: 53px;
  float: left;
  overflow: hidden;
}

.inter-i-r {
  margin: 0px 0px 0px 53px;
}

.inter-i-rb {
  width: 100%;
  float: left;
  overflow: hidden;
}

.inter-i-b {
  padding: 1px 0px 0px 13px;
}

.inter-i-lbl {
  font-family: 'Montserrat';
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #3a4142;
  font-size: 14px;
  font-weight: normal;
}

.inter-i-txt {
  font-size: 14px;
  color: #606869;
  line-height: 25px;
}

.mp-popular {
  padding-left: 20px;
  padding-right: 20px;
}

.mp-popular-row .offer-slider-i {
  width: 31.3%;
}

.testimonials-b {
  padding-left: 60px;
  padding-right: 60px;
}

.row.collapse {
  display: block;
}

.datepicker-popup {
  margin-top: 15px;
  margin-left: -8px;
}

.form-element-popup-container-spacer {
  display: none;
}




*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

.plane {
  margin: 6px 20px 20px 95px;
  max-width: 388px;
  text-align: center;
}

.plane .cockpit {
  height: auto;
  position: relative;
  overflow: hidden;
  text-align: center;
  /* border-bottom: 5px solid #d8d8d8; */
}

.plane .cockpit:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 364px;
  width: 100%;
  border-radius: 50%;
  /* border-right: 5px solid #d8d8d8;
    border-left: 5px solid #d8d8d8; */
}

.plane .cockpit h1 {
  width: 60%;
  margin: 5px auto 10px auto;
  font-size: 20px;
  font-weight: 800;
  color: #785eff;
  font-family: 'Raleway';
  text-transform: capitalize;
}

.plane .cockpit h1 .brcockpit {
  font-size: 15px;
}

.plane .cockpit h1 .brcockpit1 {
  text-transform: capitalize;
  font-size: 12px;
  font-family: 'Open Sans';
  font-weight: 600;
}

.plane .exit_img {
  position: relative;
  height: 40px;
}

.plane .exit {
  position: relative;
  height: 10px;
}

.plane .exit:before {


  font-size: 10px;
  line-height: 30px;
  padding: 0px 2px;
  font-weight: 600;
  font-family: "Raleway";
  display: block;
  position: absolute;
  border-left: 3px solid #bd8100;
  color: #2c2c2c;
  top: 50%;
  transform: translate(0, -50%);
}

.plane .exit:after {

  font-size: 10px;
  line-height: 30px;
  padding: 0px 2px;
  font-weight: 600;
  font-family: "Raleway";
  display: block;
  position: absolute;
  border-right: 3px solid #bd8100;
  color: #2c2c2c;
  top: 50%;
  transform: translate(0, -50%);
}

.plane .exit:before {
  left: 0;
}

.plane .exit:after {
  right: 0;
}

/* .plane .fuselage {
  border-right: 5px solid #d8d8d8;
  border-left: 5px solid #d8d8d8;
} */
.plane ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.plane .row {
  /* width: 287px; */
  margin-left: 1%;
}

.plane .seats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.plane .Business {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 70px;
  justify-content: flex-start;
}

.plane .First {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 70px;
  justify-content: flex-start;
}

.plane .cabin .row .seats .seat {
  display: flex;
  flex: 0 0 9.285714%;
  padding: 5px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 7.285714%;
  }

  &:nth-child(1) {

    margin-left: 60px;
    margin-right: -1.714286%;

  }
}

.plane .cabin .row .olrow12 .lirow12 {
  display: flex;
  flex: 0 0 7.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: 0.285714%;
    margin-left: 0%;
  }
}

.plane .cabin .row .olrow12 .lirow12 {
  display: flex;
  flex: 0 0 0.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: -9.714286%;
  }
}

.plane .cabin .row .First .First_class11 {
  display: flex;
  flex: 0 0 0.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: -4.714286%;
    margin-top: 1%;
    margin-left: 1%;
  }
}

.plane .cabin .row .First .First_class10 {
  display: flex;
  flex: 0 0 0.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: -4.714286%;
    margin-top: 1%;
    margin-left: 1%;
  }
}

.plane .cabin .row .First .First_class12 {
  display: flex;
  flex: 0 0 0.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: 0.285714%;
    margin-top: 1%;
    margin-left: -4%;
  }
}

.plane .cabin .row .Business .Business_class10 {
  display: flex;
  flex: 0 0 0.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: 0.285714%;
    margin-top: 1%;
    margin-left: -4%;
  }
}

.plane .cabin .row .Business .Business_class12 {
  display: flex;
  flex: 0 0 0.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: 0.285714%;
    margin-top: 1%;
    margin-left: -4%;
  }
}

.plane .cabin .row .Business .Business_class11 {
  display: flex;
  flex: 0 0 0.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: 0.285714%;
    margin-top: 1%;
    margin-left: -4%;
  }
}

.plane .cabin .row .Economy .Economy_class11 {
  display: flex;
  flex: 0 0 8.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: 0.285714%;
    margin-top: 1%;
    margin-left: -4%;
  }
}

.plane .cabin .row .Economy .Economy_class12 {
  display: flex;
  flex: 0 0 8.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(4) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: 0.285714%;
    margin-top: 1%;
    margin-left: -4%;
  }
}

.plane .cabin .row .Economy .Economy_class10 {
  display: flex;
  flex: 0 0 8.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(3) {
    margin-right: 3.285714%;
  }

  &:nth-child(4) {
    margin-right: 0;
  }

  &:nth-child(7) {
    margin-right: 3.285714%;
  }

  &:nth-child(1) {
    margin-right: 0.285714%;
    margin-top: 1%;
    margin-left: 4%;
  }
}

.plane .cabin .row .olrow11 .lirow11 {
  
  &:nth-child(4) {
    margin-right: 3.285714%;
  }
  &:nth-child(7) {
    margin-right: 3.285714%;
  }
  &:nth-child(1) {
    margin-right: -4.714286%;
    margin-left: -4%;
  }
} 
.plane .cabin .row .olrow10 .lirow10 {
  
  &:nth-child(3) {
    margin-right: 3.285714%;
  }
  &:nth-child(4) {
    margin-right: 0;
  }
  &:nth-child(7) {
    margin-right: 3.285714%;
  }
  &:nth-child(8) {
    margin-right: 0;
  }
  &:nth-child(1) {
    margin-left: 8%;
    margin-right: -3.714286%;
  }
}
.plane .cabin .row .olrow9 .lirow9 {
  display: flex;
  flex: 0 0 11.285714%;
  padding-right: 1px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(3) {
    margin-right: 3.285714%;
  }

  &:nth-child(4) {
    margin-right: 0;
  }

  &:nth-child(7) {
    margin-right: 3.285714%;
  }

  &:nth-child(8) {
    margin-right: 0;
  }

  &:nth-child(1) {
    margin-right: -3.714286%;
  }
}

.plane .cabin .row .Business .Business_class9 {
  display: flex;
  flex: 0 0 0 0 2.285714%;
  padding: 5px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(3) {
    margin-right: 1.285714%;
  }

  &:nth-child(1) {
    margin-right: -6.714286%;
    margin-left: 1%;
    margin-top: 5%;
  }

  &:nth-child(4) {
    margin-right: 0%;
  }
}

.plane .cabin .row .Business .Business_class5 {
  display: flex;
  flex: 0 0 0 0 2.285714%;
  padding: 5px;
  position: relative;
  font-size: 10px;
  font-family: 'Open Sans';

  &:nth-child(3) {
    margin-right: 1.285714%;
  }

  &:nth-child(1) {
    margin-right: -6.714286%;
    margin-left: 25%;
    margin-top: 5%;
  }

  &:nth-child(4) {
    margin-right: 0%;
  }
}

.plane .cabin .row .seats .seat input[type=checkbox],
.plane .cabin .row .seats .seat input[type=radio] {
  position: absolute;
  opacity: 0;
}

.plane .cabin .row .seats .seat input[type=checkbox]:checked,
.plane .cabin .row .seats .seat input[type=radio]:checked {
  +label {
    background: #0091ff;
    color: #0091ff;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    animation-duration: 300ms;
    animation-fill-mode: both;
  }

  +label:before {
    background: #0091ff;
    color: #0091ff;

  }
}

.plane .cabin .row .Business .Business_class input[type=checkbox],
.plane .cabin .row .Business .Business_class input[type=radio] {
  +label {
    background:url(../public/img/taxivaxi/meal_seats/Business_Current_copy_18.svg) center top no-repeat; 
    background-size: 40px auto;
    height: auto;
    width: 30px;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    animation-duration: 300ms;
    animation-fill-mode: both;
  }

  +label:before {
    background: transparent;

  }
}

.plane .cabin .row .Business .Business_class input[type=checkbox]:checked,
.plane .cabin .row .Business .Business_class input[type=radio]:checked {
  +label {
    background:url(../public/img/taxivaxi/meal_seats/2_Business_Current_copy_2.png) center top no-repeat;
    background-size: 40px auto;
    height: auto;
    width: 30px;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    animation-duration: 300ms;
    animation-fill-mode: both;
  }

  +label:before {
    background:url(../public/img/taxivaxi/meal_seats/2_Business_Current_copy_2.png) center top no-repeat; 
    background-size: 40px auto;
    width: 30px;
    height: auto;

  }
}

.plane .cabin .row .First .First_class input[type=checkbox],
.plane .cabin .row .First .First_class input[type=radio] {
  +label {
    background:url(../public/img/taxivaxi/meal_seats/first_class_available_seat.png) center top no-repeat; 
    background-size: 40px auto;
    height: auto;
    width: 30px;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    animation-duration: 300ms;
    animation-fill-mode: both;
  }

  +label:before {
    background: transparent;

  }
}

.plane .cabin .row .First .First_class input[type=checkbox]:checked,
.plane .cabin .row .First .First_class input[type=radio]:checked {
  +label {
    background:url(../public/img/taxivaxi/meal_seats/first_class_selected_seat.png) center top no-repeat; 
    background-size: 40px auto;
    height: auto;
    width: 30px;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    animation-duration: 300ms;
    animation-fill-mode: both;
  }

  +label:before {
    background:url(../public/img/taxivaxi/meal_seats/first_class_selected_seat.png) center top no-repeat; 
    background-size: 50px auto;
    height: auto;

  }
}

.plane .cabin .row .seats .seat label.seat-label-selected-by-other-0,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-1,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-2,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-3,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-4,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-5,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-6,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-7,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-8,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-9 {
  background: #0091ff;
  color: #0091ff;
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.plane .cabin .row .seats .seat label.seat-label-selected-by-other-0:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-1:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-2:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-3:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-4:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-5:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-6:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-7:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-8:before,
.plane .cabin .row .seats .seat label.seat-label-selected-by-other-9:before {
  background: #0091ff;
  color: #0091ff;
}

.plane .cabin .row .seats .seat input[type=checkbox]:disabled,
.plane .cabin .row .seats .seat input[type=radio]:disabled {
  +label {

    background: #d8d8d8;
    text-indent: -9999px;

  }

  +label:hover {

    text-transform: none;
    border: none;
  }
}

.plane .cabin .row .Business .Business_class input[type=checkbox]:disabled,
.plane .cabin .row .Business .Business_class input[type=radio]:disabled {
  +label {
    background:url(../public/img/taxivaxi/meal_seats/Business_Booked_copy.svg) center top no-repeat;
    text-indent: -9999px;
    background-size: 40px;
    width: 30px;
  }

  +label:hover {

    text-transform: none;
    border: none;
  }
}

.plane .cabin .row .First .First_class input[type=checkbox]:disabled,
.plane .cabin .row .First .First_class input[type=radio]:disabled {
  +label {

    background: url(../public/img/taxivaxi/meal_seats/first_class_occupied_seat.png) center top no-repeat;
    text-indent: -9999px;
    background-size: 40px;
    width: 30px;
  }

  +label:hover {

    text-transform: none;
    border: none;
  }
}

.plane .cabin .row .seats .seat input[type=checkbox]:disabled::after,
.plane .cabin .row .seats .seat input[type=radio]:disabled::after {
  content: "X";
  text-indent: 0;
  position: absolute;
  width: 0;
  height: 0;

}

.plane .cabin .row .seats .seat label,
.plane .cabin .row .seats .seat .more300 {
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  text-align: center;
  font-size: 10px;

  line-height: 0.8rem;
  font-family: 'Open Sans';
  color: #b29bd1;
  padding: 4px 0;
  background-color: #b29bd1;

  border-radius: 5px;
  animation-duration: 300ms;
  animation-fill-mode: both;
}


.plane .cabin .row .seats .seat .less300 {
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  text-align: center;
  font-size: 10px;

  line-height: 0.8rem;
  font-family: 'Open Sans';
  color: #92daff;
  padding: 4px 0;
  background-color: #92daff;

  border-radius: 5px;
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.plane .cabin .row .seats .seat .free {
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  text-align: center;
  font-size: 10px;

  line-height: 0.8rem;
  font-family: 'Open Sans';
  color: rgb(80, 227, 194);
  padding: 4px 0;
  background-color: rgb(80, 227, 194);

  border-radius: 5px;
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.plane .cabin .row .Business .Business_class label {
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  text-align: center;
  font-size: 10px;

  line-height: 0.8rem;
  font-family: 'Open Sans';

  padding: 4px 0;
  background-color: transparent;

  border-radius: 5px;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.plane .cabin .row .First .First_class label {
  display: block;
  position: relative;
  /* width: 100%; */
  height: 20px;
  text-align: center;
  font-size: 10px;
  width: 23px;
  line-height: 0.8rem;
  font-family: 'Open Sans';

  padding: 4px 0;
  background-color: transparent;

  border-radius: 5px;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.plane .cabin .row .seats .seat .tooltip {
  visibility: hidden;
  position: fixed;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
}

.plane .cabin .row .seats .seat:hover .tooltip {
  visibility: visible;
}

.plane .cabin .row .seats .seat .Blocked {
  background-color: #d8d8d8;

}

.plane .cabin .row .seats .seat .Blocked:before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #d8d8d8;
  border-radius: 3px;

}

.plane .cabin .row .Business .Business_class .Blocked {
  background: url(../public/img/taxivaxi/meal_seats/Business_Booked_copy.svg) center top no-repeat;
  background-size: 40px;
  width: 30px;
}

.plane .cabin .row .Business .Business_class .Blocked:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: url(../public/img/taxivaxi/meal_seats/Business_Booked_copy.svg) center top no-repeat;
  background-size: 40px;
  border-radius: 3px;

}

.plane .cabin .row .First .First_class .Blocked {
  background: url(../public/img/taxivaxi/meal_seats/first_class_occupied_seat.png) center top no-repeat;
  background-size: 40px;
  width: 30px;
}

.plane .cabin .row .First .First_class .Blocked:before {
  content: "";
  position: absolute;
  /* width: 75%; */
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: url(../public/img/taxivaxi/meal_seats/first_class_occupied_seat.png) center top no-repeat;
  background-size: 40px;
  width: 30px;
  border-radius: 3px;

}

.plane .cabin .row .seats .seat .Occupied {
  background-color: #d8d8d8;

}

.plane .cabin .row .Business .Business_class .Occupied {
  background: url(../public/img/taxivaxi/meal_seats/Business_Booked_copy.svg) center top no-repeat;
  background-size: 40px;
  width: 30px;
}

.plane .cabin .row .First .First_class .Occupied {
  background: url(../public/img/taxivaxi/meal_seats/first_class_occupied_seat.png) center top no-repeat;
  background-size: 40px;
  width: 30px;
}

.plane .cabin .row .seats .seat .Occupied:before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: #d8d8d8;
  border-radius: 3px;
}

.plane .cabin .row .Business .Business_class .Occupied:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: url(../public/img/taxivaxi/meal_seats/Business_Booked_copy.svg) center top no-repeat;
  background-size: 40px;
  border-radius: 3px;
}

.plane .cabin .row .First .First_class .Occupied:before {
  content: "";
  position: absolute;
  /* width: 75%; */
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: url(../public/img/taxivaxi/meal_seats/first_class_occupied_seat.png) center top no-repeat;
  background-size: 40px;
  width: 30px;
  border-radius: 3px;
}

.plane .cabin .row .seats .seat label:before,
.plane .cabin .row .seats .seat .more300:before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #b29bd1;
  border-radius: 3px;
}

.plane .cabin .row .seats .seat .less300:before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #92daff;
  border-radius: 3px;
}
.plane .cabin .row .seats .seat .free:before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: rgb(80, 227, 194);
  border-radius: 3px;
}
.plane .cabin .row .Business .Business_class label:before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: transparent;
  border-radius: 3px;
}

.plane .cabin .row .First .First_class label:before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: -6px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: transparent;
  border-radius: 3px;
}

.plane .cabin .row .seats .seat label:hover {
  cursor: pointer;

}

.plane .cabin .row .seats .seat input[data-groups]:disabled+label:before {
  /* Your styles for the disabled :before pseudo-element */
  content: "";
  /* Example: Add content if needed */
  background-color: #d8d8d8;
}

.plane .cabin .row .Business .seat input[data-groups]:disabled+label:before,
.plane .cabin .row .First .seat input[data-groups]:disabled+label:before {
  /* Your styles for the disabled :before pseudo-element */
  content: "";
  /* Example: Add content if needed */
  background-color: transparent;
}

.plane .cabin .row .seats .seat input[type=checkbox]:disabled:hover,
.plane .cabin .row .seats .seat input[type=radio]:disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.plane .rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}


@media screen and (max-width:1130px) {
  .mp-popular-row .offer-slider-i {
    width: 31.1%;
    margin-right: 3.3%;
  }

  .mp-popular-row .offer-slider-i:last-child {
    margin-right: 0px;
    float: right;
  }

  .swiper-pagination {
    display: none;
  }

  .special-offer-b {
    display: none;
  }

  .page-search {
    float: none;
    display: inline-block;
    margin: 0px;
    margin-right: 0px;
    vertical-align: top;
  }

  .special-offer-a {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
  }

  .wrapper-a {
    text-align: center;
  }

  .page-search-content label,
  .special-offer-foot-l {
    text-align: left;
  }

  .mp-slider-lbl {
    padding-top: 170px;
  }

  .mp-slider .arrow-left {
    left: 20px;
  }

  .mp-slider .arrow-right {
    right: 20px;
  }

  .mp-slider-lbl {
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
  }

  .offer-slider-c .owl-next,
  .offer-slider-c .owl-prev {
    display: none !important;
  }

  .mp-b-left {
    display: none;
  }

  .mp-b-right {
    float: none;
    width: 100%;
  }

  .wrapper-padding,
  .offer-slider-c,
  .offer-slider header,
  .mp-popular,
  .partners,
  .testimonials,
  .inner-breadcrumbs .content-wrapper,
  .about-content .content-wrapper,
  .why-we .content-wrapper,
  .about-slider-h,
  .blog-page,
  .contacts-page,
  .typography .content-wrapper,
  .inner-page .content-wrapper,
  .portfolio-holder {
    padding-left: 60px;
    padding-right: 60px;
  }

  .hdr-srch-overlay-a {
    margin-left: 60px;
    margin-right: 60px;
  }

  .about-content.no-wrapper .content-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .columns .content-wrapper {
    padding-left: 47px;
    padding-right: 47px;
  }

  .blog-wrapper {
    padding-left: 47px;
    padding-right: 47px;
  }

  .reasons-item-a,
  .reasons-item-b,
  .reasons-item-c,
  .reasons-item-d {
    width: 41.5%;
  }

  .footer-a .section:last-child {
    float: none;
    width: 100%;
    clear: both;
  }

  .footer-a .section {
    width: 37%;
  }

  .footer-a .section:nth-child(3) {
    float: right;
    width: 230px;
  }

  .footer-subscribe {
    width: 100%;
  }

  .footer-subscribe-a input {
    width: 98%;
  }

  .offer-slider-devider {
    display: none;
  }

  .page-search.full-width-search {
    width: 100%;
  }

  .search-line-a.large input[type="text"] {
    width: 440px;
  }

  .srch-horizontal {
    margin-right: 0px;
  }

  .srch-horizontal .search-line-a.middle:last-child {
    margin-right: 0px;
  }

  .search-line-a.middle input[type="text"] {
    width: 161px;
  }

  .search-line-select.middle {
    width: 270px;
    margin-top: 16px;
  }

  .search-line-select.middle .search-select {
    width: 270px;
  }

  .search-select select {
    width: 108%;
    background-position: 93% center;
  }

  .full-width-search .wrapper-a {
    bottom: 147px;
  }

  /* .search-asvanced .search-line-a.middle-a input[type="text"] {
    width: 108px;
  }

  .search-asvanced .search-select.full-width-a {
    width: 270px;
  }

  .search-asvanced .search-line-select.middle-b {
    margin-left: 20px;
  }

  .search-asvanced .search-select.full-width-b {
    width: 270px;
  } */

  .custom-select {
    text-align: left;
  }

  .search-type-padding {
    padding-left: 60px;
    padding-right: 60px;
  }

  .partners {
    padding-bottom: 7px;
  }

  .partners a:first-child {
    margin-left: 24px;
  }

  .partners a:last-child {
    margin-right: 24px;
  }

  .partners a {
    margin-bottom: 25px;
    display: inline-block;
  }

  .pop-destinations {
    padding-left: 60px;
    padding-right: 60px;
  }

  .t-experience-i:first-child {
    margin-left: 29px;
  }

  .t-experience-i:last-child {
    margin-right: 29px;
  }

  .alt-fligt-table {
    width: 100%;
  }

  .two-colls-left {
    width: 30%;
  }

  .two-colls-left1 {
    width: 30%;
  }

  .two-colls-left2 {
    width: 30%;
  }

  .two-colls-right {
    margin-left: 30%;
  }

  .two-colls-right1 {
    margin-left: 30%;
  }

  .two-colls-right2 {
    margin-left: 0;
  }

  #departure_return {
    width: 93%;
  }

  .two-colls-right .padding {
    padding-left: 16px;
  }

  .two-colls-right1 .padding {
    padding-left: 16px;
  }

  .two-colls-right2 .padding {
    padding-left: 0px;
  }

  .alt-fligt-table .cat-list-btn {
    padding-left: 15px;
    padding-right: 15px;
  }

  .alt-fligt-table .flt-i-price-b {
    font-size: 8px;
  }

  .alt-fligt-table-info .alt-departure {
    margin-bottom: 10px;
  }

  .alt-fligt-table-content {
    padding-left: 0px;
    padding-right: 0px;
  }

  .search-select select {
    width: 118%;
  }

  .counters-i {
    width: 18%;
    margin-right: 2%;
  }

  .counters-i:first-child {
    margin-left: 0px;
  }

  .sp-page-l {
    margin-right: 35%;
  }

  .sp-page-r {
    width: 35%;
    margin-left: -35%;
  }

  .alt-time {
    margin-top: 10px;
  }

  .alt-details-i:nth-child(3) {
    margin-top: 12px;
  }

  .sp-page-l {
    margin-right: 33%;
  }

  .sp-page-r {
    width: 33%;
    margin-left: -33%;
  }

  .chk-fligth-devider,
  .chk-fligth-time {
    display: none;
  }

  .chk-departure {
    margin-bottom: 5px;
  }

  .chk-departure,
  .chk-arrival {
    width: 100%;
  }

  .chk-departure span,
  .chk-arrival span {
    display: inline;
    float: none;
  }

  .chk-departure b,
  .chk-arrival b {
    display: inline;
    float: none;
  }

  .chk-departure b br,
  .chk-arrival b br {
    display: none;
  }

  .booking-form .booking-form-i {
    width: 100%;
    float: none;
  }

  .form-calendar {
    float: left;
    margin-left: 5px;
  }

  .flight-image img {
    width: 100%;
  }

  .tab-calendar-colls table tbody td {
    height: 55px;
  }

  .flight-d-left {
    width: auto;
    border: 0px;
    float: none;
    display: block;
    margin: 0px 0px 20px 0px;
    padding: 0px;
  }

  .flight-d-right {
    width: auto;
    float: none;
    display: inline;
    margin: 0px;
    padding: 0px;
  }

  .flight-d-rightp {
    padding: 0px;
  }

  .book-btn {
    width: 100%;
  }

  .book-btn-l {
    width: 18%;
  }

  .book-btn-r {
    width: 81%;
  }

  .wishlist-btn-l {
    width: 18%;
  }

  .wishlist-btn-r {
    width: 81%;
  }

  .preferences-list li {
    width: 40%;
    float: left;
    margin-right: 0px;
  }

  .preferences-list li:nth-child(2n) {
    float: right;
    margin-right: 0px;
  }

  .faq-item-left {
    margin-right: 0px;
  }

  .faq-item-i {
    display: none;
  }

  .flt-i-b {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .flt-i-c {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .flt-i-padding {
    padding: 20px 0px 0px 0px;
    text-align: right;
  }

  .flight-details-l {
    float: none;
    margin-bottom: 12px;
  }

  .flight-details-m {
    float: none;
    margin-bottom: 12px;
  }

  .flight-details-r {
    float: none;
    margin-bottom: 12px;
  }

  .tab-gallery-big img {
    width: 100%;
  }

  .content-tabs-head ul li a {
    padding-left: 12px;
    padding-right: 12px;
  }

  .preferences-list-alt li {
    width: 40%;
    float: left;
    margin-right: 0px;
  }

  .preferences-list-alt li:nth-child(2n) {
    width: 40%;
    float: right;
    margin-right: 0px;
  }

  .hotel-reviews-i {
    width: 45%;
    float: left;
    margin-right: 0px;
  }

  .hotel-reviews-i:nth-child(2n) {
    width: 45%;
    float: right;
    margin-right: 0px;
  }

  .todo-row .cat-list-content-l {
    width: 100%;
    float: none;
    margin: 0px 0px 15px 0px;
  }

  .todo-row .cat-list-content-r {
    width: 100%;
    float: none;
    overflow: visible;
    margin: 0px 0px 0px 0px;
  }

  .todo-row .cat-list-content-p {
    padding-left: 0px;
  }

  .shortcodes-left img,
  .shortcodes-right img {
    width: 100%;
  }

  .offer-slider-txt {
    min-height: 80px;
  }

  .with-text .offer-slider-txt {
    min-height: 180px;
  }

  .offer-slider-link a {
    font-size: 12px;
  }

  .catalog-head.large label {
    float: none;
    display: block;
    margin-bottom: 10px;
  }

  .catalog-head.large .search-select {
    width: 16%;
  }

  .list-rows .cat-list-item-l {
    width: 30%;
  }

  .list-rows .cat-list-item-l img {
    width: 100%;
  }

  .list-rows .cat-list-item-r {
    margin-left: 30%;
  }

  .list-rows .cat-list-content-r {
    overflow: visible;
  }

  .list-rows .cat-list-content-p {
    padding-left: 18px;
  }

  .list-rows .cat-list-content-lpadding {
    padding-bottom: 16px;
  }

  .portfolio-three-colls .portfolio-i {
    width: 31.1%;
    margin-left: 1.1%;
    margin-right: 1.1%;
  }

  .portfolio-holder-three {
    padding-left: 51px;
    padding-right: 51px;
  }

  .tour-i-holder {
    float: none;
    width: 100%;
  }

  .tour-duration {
    width: 100%;
    float: none;
    margin: 8px 0px 0px 0px;
  }

  .header-nav ul li {
    margin-left: 20px;
  }

  .theme-teaser-r img {
    width: 100%;
  }
}

@media screen and (max-width:1050px) {

  .tab-reasons-i,
  .tab-reasons-i:nth-child(2n) {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }
}

@media screen and (max-width:1035px) {
  .alt-departure {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .alt-arrival {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .alt-time {
    width: 100%;
    float: none;
    margin-bottom: 0px;
  }

  .tab-calendar-colls table tbody td {
    height: 40px;
  }

  .chk-nights {
    width: 100%;
    float: none;
    margin: 0px 0px 10px 0px;
    display: block;
  }

  .chk-line {
    float: none;
    width: 100%;
    margin: 0px;
    display: block;
  }

  .chk-persons {
    display: block;
    margin-top: 5px;
  }

  .rates-line {
    width: 100%;
    float: none;
  }

  .rates-line-right {
    width: 89%;
    float: left;
    margin-left: 0px;
  }

  .grid .offer-slider-l {
    width: 100%;
    margin-bottom: 12px;
    float: none;
  }

  .grid .offer-slider-r {
    width: 100%;
    float: none;
  }

  .columns .content-wrapper {
    padding-left: 48px;
    padding-right: 48px;
  }
}

@media screen and (max-width:1010px) {
  .footer-a .section {
    width: 35%;
  }

  .header-curency {
    display: none;
  }

  .header-viewed {
    display: none;
  }

  .page-search.full-width-search {
    width: 100%;
  }

  .search-line-a.large input[type="text"] {
    width: 317px;
  }

  .search-line-a.middle input[type="text"] {
    width: 124px;
  }

  .search-line-select.middle {
    width: 204px;
    margin-top: 16px;
  }

  .search-line-select.middle .search-select {
    width: 204px;
  }

  .search-only .mp-slider-lbl {
    padding-top: 167px;
  }

  /* .search-asvanced .search-select.full-width-a {
    width: 170px;
  }

  .search-asvanced .search-select.full-width-b {
    width: 170px;
  } */

  
   

  .search-select.full-width select,
  .search-select.full-width-a select,
  .search-select.full-width-b select,
  .search-select.full-width-c select {
    background-position: 90% center;
    width: 111%;
  }

  .advantages-i {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 18px;
  }

  .advantages-i:last-child {
    margin-bottom: 0px;
  }

  .blog-sidebar-r {
    width: auto;
    margin-left: -25.2%;
    margin-top: 12px;
  }

  .blog-sidebar-l {
    margin-right: 25%;
  }

  .search-widget input[type="text"] {
    display: block;
    width: 90%;
  }

  .blog-sidebar-p {
    padding-right: 10px;
  }

  .blog-post-l {
    width: 19%;
  }

  .blog-post-c {
    margin-left: 19%;
  }

  .blog-post-info {
    font-size: 9px;
  }

  .tweeter-item-r {
    width: 89%;
  }

  .blog-post-info div {
    line-height: 18px;
    margin-bottom: 10px;
  }

  .flight-d-depart {
    float: none;
    margin-bottom: 20px;
  }

  .flight-d-time {
    float: none;
    margin-bottom: 20px;
  }

  .flight-d-arrival {
    float: none;
  }

  .h-stars-list {
    float: none;
    margin: 0px 0px 0px 0px;
  }

  .h-stars-list li {
    margin-bottom: 4px;
  }

  .h-stars-lbl {
    float: none;
    clear: both;
    margin: 5px 0px 10px 0px;
  }

  .h-add-review {
    float: none;
  }

  .content-tabs-head ul li a {
    padding-left: 9px;
    padding-right: 9px;
  }

  .facilities table td {
    line-height: 21px;
  }

  .portfolio-i {
    width: 31.1%;
    margin-right: 1.1%;
    margin-left: 1.1%;
  }

  .columns .content-wrapper {
    padding-left: 49px;
    padding-right: 49px;
  }

  .portfolio-holder {
    padding-left: 53px;
    padding-right: 52px;
  }

  .shortcodes-left,
  .shortcodes-right {
    width: 48%;
  }

  .tabs-type-a .tabs-nav a {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-nav ul li {
    margin-left: 18px;
  }

  .header-nav {
    display: none;
  }

  .hdr-srch-devider {
    display: none;
  }

  .menu-btn {
    display: block;
  }
}

@media screen and (max-width:910px) {
  .header-social {
    display: none;
  }

  .reasons-item-a,
  .reasons-item-b,
  .reasons-item-c,
  .reasons-item-d {
    width: 40.5%;
  }

  .footer-a .section {
    width: 26%;
    margin-right: 10%;
  }

  .footer-a .section:nth-child(3) {
    margin: 0px;
    width: 170px;
  }

  .tweet {
    width: auto;
  }

  .t-experience-i:first-child,
  .t-experience-i:last-child,
  .t-experience-i {
    margin-left: 0px;
    margin-bottom: 20px;
    margin-right: 0px;
    width: 100%;
  }

  .t-experience-i:last-child {
    margin-bottom: 0px;
  }

  .theme-teaser-r {
    display: none;
  }

  .theme-teaser-l {
    width: 100%;
    padding-right: 0px;
    text-align: center;
  }

  .two-colls-left {
    width: 100%;
    margin: 0px;
    padding: 0px;
    float: none;
  }

  .two-colls-left1 {
    width: 100%;
    margin: 0px;
    padding: 0px;
    float: none;
  }

  .two-colls-left2 {
    width: 100%;
    margin: 0px;
    padding: 0px;
    float: none;
  }

  .two-colls-right {
    width: 100%;
    margin: 0px;
    padding: 0px;
    float: none;
  }

  .two-colls-right .padding {
    padding-left: 0px;
  }

  .two-colls-right1 {
    width: 100%;
    margin: 0px;
    padding: 0px;
    float: none;
  }

  .two-colls-right1 .padding {
    padding-left: 0px;
  }

  .two-colls-right2 {
    width: 0;
    margin: 0px;
    padding: 0px;
    float: none;
  }

  .two-colls-right2 .padding {
    padding-left: 0px;
  }

  .two-colls-left .srch-tab-left {
    width: 48%;
  }

  .two-colls-left1 .srch-tab-left {
    width: 48%;
  }

  .two-colls-left2 .srch-tab-left {
    width: 48%;
  }

  .two-colls-left .srch-tab-right {
    width: 48%;
  }

  .two-colls-left1 .srch-tab-right {
    width: 48%;
  }

  .two-colls-left2 .srch-tab-right {
    width: 48%;
  }

  .team-row-i {
    width: 49%;
    float: left;
    margin: 0px 0px 20px 0px;
  }

  .team-row-i:nth-child(2n) {
    width: 49%;
    float: right;
    margin: 0px 0px 20px 0px;
  }

  .blog-masonry-i {
    margin-bottom: 38px;
    opacity: 0;
    margin-left: 1.64%;
    margin-right: 1.64%;
    width: 46.5%;
  }

  .blog-sidebar-l {
    width: 100%;
    float: none;
    margin: 0px 0px 0px 0px;
  }

  .blog-sidebar-r {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .blog-sidebar-p {
    padding: 0px;
  }

  .blog-post-p {
    padding-right: 0px;
  }

  .tweeter-item-r {
    width: 97%;
  }

  .blog-pagination {
    margin-left: 0px;
    margin-bottom: 60px;
  }

  .blog-sidebar {
    float: none;
  }

  .search-widget input[type="text"] {
    width: 97%;
  }

  .sp-page-l {
    margin-right: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .sp-page-r {
    width: 100%;
    margin: 0px;
  }

  .sp-page-p {
    padding-right: 0px;
  }

  .alt-flight-lr .padding {
    padding-left: 15px;
  }

  .alt-departure {
    width: auto;
    float: left;
    margin-bottom: 0px;
    margin-right: 17px;
  }

  .alt-arrival {
    width: auto;
    float: left;
    margin-bottom: 0px;
    margin-right: 17px;
  }

  .alt-time {
    width: auto;
    float: left;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 0px;
  }

  .tab-calendar-colls table tbody td {
    height: 72px;
  }

  .flight-d-depart {
    float: left;
    margin-bottom: 20px;
  }

  .flight-d-time {
    float: left;
    margin-bottom: 20px;
  }

  .flight-d-arrival {
    float: left;
  }

  .flight-d-left {
    float: left;
    margin-right: 35px;
  }

  .flight-d-right {
    float: left;
  }

  .book-btn-l {
    display: none;
  }

  .book-btn-r {
    width: 100%;
  }

  .tab-calendar-collsl {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .tab-calendar-collsr {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .map-symbol {
    float: left;
    margin-right: 20px;
  }

  .tab-calendar-s {
    padding-left: 0px;
  }

  .wishlist-btn-l {
    display: none;
  }

  .wishlist-btn-r {
    width: 100%;
  }

  .counters-five-colls {
    text-align: center;
  }

  .counters-five-colls .counters-row-i,
  .counters-five-colls .counters-row-i:last-child {
    float: none;
    vertical-align: top;
    width: 150px;
    display: inline-block;
    margin: 0px 20px 25px 20px;
  }

  .p-item-page-l {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .p-item-page-r {
    width: 100%;
    float: none;
    margin: 0px 0px 40px 0px;
  }

  .p-item-padding {
    margin-right: 0px;
  }

  .p-item-page {
    float: none;
  }

  .with-text .offer-slider-txt,
  .offer-slider-txt {
    min-height: auto;
  }
}

@media screen and (max-width:860px) {

  .shortcodes-left,
  .shortcodes-right {
    width: 100%;
    float: none;
    margin: 0px 0px 60px 0px;
  }

  .about-content .about-slider-txt {
    width: 100%
  }

  .offer-slider header p {
    width: 100%;
  }

  .mp-popular header p,
  .page-lbl p {
    width: 100%;
  }

  .fancybox-prev span {
    left: 10px;
  }

  .fancybox-next span {
    right: 10px;
  }


}

@media screen and (max-width:800px) {
  .special-offer-a {
    display: none;
  }

  .tweeter-item-r {
    width: 96%;
  }

  .reasons-item-a,
  .reasons-item-b,
  .reasons-item-c,
  .reasons-item-d {
    width: 36%;
  }

  .footer-a .section {
    width: 23%;
    margin-right: 8%;
  }

  .footer-a .section:nth-child(3) {
    margin: 0px;
    width: 140px;
  }

  .page-search.full-width-search {
    width: 100%;
  }

  .search-line-a.large input[type="text"] {
    width: 287px;
  }

  .search-line-select.middle .search-select {
    width: 83px;
  }

  .search-select select {
    background-position: 86% center;
    width: 115%;
  }

  .search-line-a.large input[type="text"] {
    margin-bottom: 18px;
  }

  .search-line-select.middle {
    width: 87px;
  }

  .full-width-search .wrapper-a {
    bottom: 299px;
  }

  .search-line-select.middle {
    margin-top: 18px;
  }

  .search-only .mp-slider-lbl {
    padding-top: 110px;
  }

  .full-width-search .page-search-p {
    padding-bottom: 29px;
  }

  .offer-slider-lbl {
    line-height: 36px;
    padding-bottom: 12px;
  }

  .srch-tab-line .custom-select {
    text-align: left;
  }

  .page-search.full-width-search {
    width: 347px;
  }

  .search-type-padding {
    padding: 0px;
  }

  /* .search-large-i {
    width: 50%;
    float: none;
    margin: 0px 0px 16px 0px;
  } */

  /* .search-large-i1 {
    width: 100%;
    float: none;
    margin: 0px 0px 16px 0px;
  } */

  /* .search-large-i:nth-child(3) {
    float: none;
    margin: 0px;
  } */

  /* .search-large-i1:nth-child(3) {
    float: none;
    margin: 0px;
  } */

  .pop-destinations .mp-popular-row .offer-slider-i {
    width: 47.6%;
    float: left;
    margin: 0px 0px 20px 0px;
  }

  .pop-destinations .mp-popular-row .offer-slider-i:nth-child(2n) {
    width: 47.6%;
    float: right;
    margin: 0px 0px 20px 0px;
  }

  .flat-adv,
  .flat-adv:nth-child(2n) {
    width: 100%;
    float: none;
    margin: 0px 0px 45px 0px;
  }

  .flat-adv.large .flat-adv-l {
    width: 100%;
    float: none;
    margin: 0px 0px 12px 0px;
    padding: 0px;
    text-align: center;
  }

  .flat-adv.large .flat-adv-r {
    width: 100%;
    float: none;
    margin: 0px 0px 12px 0px;
    padding: 0px;
    text-align: center;
  }

  .flat-adv-a {
    float: none;
  }

  .flat-adv-rb {
    float: none;
  }

  .breadcrumbs {
    display: none;
  }

  .tree-colls-i {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .three-team {
    text-align: center;
  }

  .three-team .tree-colls-i {
    width: 41%;
    display: inline-block;
    float: none;
    margin: 0px 20px 20px 20px;
  }

  .about-two-colls-l {
    width: 100%;
    margin: 0px 0px 20px 0px;
    float: none;
  }

  .about-two-colls-r {
    width: 100%;
    margin: 0px;
    float: none;
  }

  .about-two-colls-rp {
    padding-left: 0px;
  }

  .counters {
    text-align: center;
  }

  .counters-i,
  .counters-i:first-child,
  .counters-i:last-child {
    width: 22%;
    vertical-align: top;
    float: none;
    display: inline-block;
    margin: 0px 20px 30px 20px;
  }

  .comlete-alert-a b {
    margin-bottom: 5px;
  }

  .alt-time {
    margin-top: 10px;
  }

  .datepicker-v2-month-container {
    margin-top: 15px !important;
  }

  .search-engine-calendar-done-button {
    margin-top: 10px;
  }

  .contacts-colls-l {
    width: 100%;
    float: none;
    margin: 0px 0px 50px 0px;
    padding: 0px;
  }

  .contacts-colls-r {
    width: 100%;
    float: none;
    margin: 0px;
    padding: 0px;
  }

  .portfolio-full-more-l {
    width: 100%;
    float: none;
    margin: 0px 0px 50px 0px;
  }

  .portfolio-full-more-r {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .portfolio-full-more-p {
    padding-left: 0px;
  }

  .list-rows .cat-list-item-l {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .list-rows .cat-list-item-r {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .services-i {
    width: 47%;
    margin: 0px 0px 20px 0px;
    float: left;
  }

  .services-i:nth-child(2n) {
    float: right;
  }

  .solutions-i {
    width: 100%;
    margin: 0px 0px 20px 0px;
    float: none;
  }

  .list-row-i {
    width: 100%;
    float: none;
    margin: 0px 0px 40px 0px;
  }

  .list-row-i:last-child {
    margin-bottom: 0px;
  }

  .datepicker-popup {
    width: 490px !important;
    min-width: 490px !important;
  }

  .datepicker-v2-month-container:first-child,
  .datepicker-v2-month-container:last-child {
    width: 48%;
  }

  .datepicker-v2-month-container:first-child table,
  .datepicker-v2-month-container:last-child table {
    width: 100%;
  }

  .footer-a .section,
  .footer-a .section:nth-child(3) {
    width: 100%;
    float: none;
    margin-bottom: 21px;
  }

  .footer-social {
    display: none;
  }

  .footer-subscribe {
    width: 98%;
  }

  .footer-a .section:nth-child(1) {
    margin-bottom: 42px;
  }

  .footer-a .section:nth-child(2) {
    margin-bottom: 18px;
  }

  .footer-a .section:nth-child(3) {
    margin-bottom: 43px;
  }
}


@media screen and (max-width:734px) {
  .alt-departure {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .alt-arrival {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .alt-time {
    width: 100%;
    float: none;
    margin-bottom: 0px;
  }

  .sp-page-l nav ul {
    display: inline-block;
    width: 100%;
  }

  .content-tabs-head ul li {
    float: none;
    margin: 0px;
    width: 100%;
    border-bottom: 1px solid #fff;
  }

  .tab-calendar-colls table tbody td {
    height: 65px;
  }

  .content-tabs-head.last-item ul li:last-child a {
    width: 100%;
  }

  .cat-list-content-l {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .cat-list-content-r {
    width: 100%;
    float: none;
    margin: 0px;
    overflow: visible;
  }

  .available-row .cat-list-content-p {
    padding-left: 0px;
  }

  .cat-list-content-lpadding {
    padding-right: 0px;
  }

  .pie-charts-i {
    width: 49%;
    float: left;
    margin: 0px 0px 30px 0px;
  }

  .pie-charts-i:nth-child(2n) {
    float: right;
  }

  .icons-two-colls .icons-item {
    width: 49%;
    float: left;
    margin: 0px 0px 30px 0px;
  }

  .icons-two-colls .icons-item:nth-child(2n) {
    float: right;
  }

  .counters-four-colls .counters-row-i {
    width: 49%;
    float: left;
    margin: 0px 0px 30px 0px;
  }

  .counters-four-colls .counters-row-i:nth-child(2n) {
    width: 49%;
    float: right;
    margin: 0px 0px 30px 0px;
  }

  .counters-five-colls .counters-row-i,
  .counters-five-colls .counters-row-i:last-child {
    width: 49%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .shortcodes-left {
    width: 100%;
    float: none;
    margin: 0px 0px 25px 0px;
  }

  .shortcodes-right {
    width: 100%;
    float: none;
    margin: 0px 0px 25px 0px;
  }

  .offer-slider-i.catalog-i,
  .offer-slider-i.catalog-i:nth-child(3n) {
    margin-bottom: 20px;
    width: 48%;
    float: left;
    margin-left: 0px;
    margin-right: 0px;
  }

  .offer-slider-i.catalog-i:nth-child(2n) {
    width: 48%;
    float: right;
    margin-left: 0px;
    margin-right: 0px;
  }

  .show-list,
  .show-thumbs {
    display: none;
  }

  .list-rows .cat-list-content-l {
    margin-right: 125px;
    width: auto;
    float: left;
  }

  .list-rows .cat-list-content-r {
    width: 125px;
    float: left;
    margin-left: -125px;
    overflow: visible;
  }

  .list-rows .cat-list-content-lpadding {
    padding-bottom: 16px;
  }

  .list-rows .cat-list-content-lpadding {
    padding-right: 30px;
  }

  .portfolio-i,
  .portfolio-three-colls .portfolio-i {
    width: 45.4%;
    margin-right: 2.3%;
    margin-left: 2.3%;
  }

  .portfolio-holder {
    padding-left: 48px;
    padding-right: 48px;
  }

  .tour-item .cat-list-content-lpadding {
    border-right: 0px;
  }

  .datepicker-popup {
    width: 440px !important;
    min-width: 440px !important;
  }

  .reasons-item-a,
  .reasons-item-b,
  .reasons-item-c,
  .reasons-item-d {
    width: auto;
    float: none;
  }

  .mp-slider-lbl {
    font-size: 36px;
    padding-top: 172px;
  }

}

@media screen and (max-width:660px) {

  .mp-slider .arrow-left,
  .mp-slider .arrow-right {
    display: none;
  }

  .mp-slider-lbl-a {
    padding-left: 20px;
    padding-right: 20px;
  }

  .popular-destinations .offer-slider-i {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .testimonials-holder .bx-prev,
  .testimonials-holder .bx-next {
    display: none;
  }

  .testimonials-b {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pop-destinations .mp-popular-row .offer-slider-i {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .pop-destinations .mp-popular-row .offer-slider-i:nth-child(2n) {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .alt-fligt-table {
    width: 100%;
  }

  .alt-fligt-table:nth-child(2n) {
    width: 48%;
    margin-right: 0px !important;
    float: right !important;
  }

  .catalog-head label {
    width: 100%;
    display: block;
    float: none;
    margin: 0px 0px 10px 0px;
  }

  .search-select select {
    width: 120%;
  }

  .three-team .tree-colls-i {
    width: 100%;
    display: block;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .tree-colls-i:last-child {
    margin-bottom: 0px;
  }

  .counters-i,
  .counters-i:first-child,
  .counters-i:last-child {
    width: 30%;
  }

  .team-row-i,
  .team-row-i:nth-child(2n) {
    width: 100%;
    float: none;
  }

  .tweeter-item-r {
    width: 95%;
  }

  .search-widget input[type="text"] {
    width: 95%;
  }

  .alt-center-l {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .alt-center-c {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .alt-logo {
    width: 100%;
  }

  .alt-flight-a {
    float: none;
  }

  .alt-flight-l {
    width: 100%;
    margin: 0px;
    float: none;
  }

  .alt-flight-lr {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .alt-center-lp {
    padding-right: 18px;
  }

  .alt-center-cp {
    border-right: 0px;
    padding-bottom: 20px;
    padding-right: 18px;
    padding-top: 0px;
  }

  .alt-departure,
  .alt-arrival,
  .alt-time {
    width: auto;
    float: left;
    margin: 0px 9px 0px 0px;
  }

  .alt-flight-lr .padding {
    padding-top: 0px;
    padding-left: 18px;
  }

  .flight-d-depart {
    float: none;
    margin-bottom: 20px;
  }

  .flight-d-time {
    float: none;
    margin-bottom: 20px;
  }

  .flight-d-arrival {
    float: none;
  }

  .flight-d-left {
    width: 100%;
    float: none;
  }

  .tab-calendar-colls table tbody td {
    height: 50px;
  }

  .preferences-list li,
  .preferences-list li:nth-child(2n) {
    float: none;
    width: 100%;
  }

  .flight-line-b {
    float: none;
    margin: 10px 0px 0px 32px;
  }

  .flight-line-b b {
    display: inline;
    padding-right: 12px;
    margin-right: 5px;
  }

  .flight-line-b span {
    display: inline;
  }

  .flight-line-b b.open {
    display: inline;
  }

  .flt-l-b {
    width: 100%;
    float: none;
    margin: 0px 0px 26px 0px;
  }

  .flt-l-b1 {
    width: 100%;
    float: none;
    margin: 0px 0px 26px 0px;
  }

  .flt-l-c {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .flt-l-c1 {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .flt-i-padding {
    text-align: left;
    margin-left: 25px;
  }

  .flight-radio {
    margin-left: 0px;
  }

  .flight-line-b {
    margin-left: 25px;
  }

  .alternative .flight-line-b {
    float: right;
    margin-right: 0px;
    margin-left: 0px;
  }

  .alternative .flight-line-b b {
    display: block;
  }

  .flight-details {
    padding-left: 25px;
  }

  .tab-reasons-i {
    padding: 40px 0px 0px 0px;
    text-align: center;
  }

  .tab-reasons-i.reasons-01 {
    background: url(../public/img/tabs-i-01.png) center top no-repeat;
  }

  .tab-reasons-i.reasons-02 {
    background: url(../public/img/tabs-i-02.png) center top no-repeat;
  }

  .tab-reasons-i.reasons-03 {
    background: url(../public/img/tabs-i-03.png) center top no-repeat;
  }

  .tab-reasons-i.reasons-04 {
    background: url(../public/img/tabs-i-04.png) center top no-repeat;
  }

  .preferences-list-alt li,
  .preferences-list-alt li:nth-child(2n) {
    width: 100%;
    float: none;
  }

  .guest-reviews-bl {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .guest-reviews-br {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .guest-reviews-padding {
    padding-left: 0px;
    padding-top: 10px;
  }

  .guest-rating-txt {
    margin-top: 10px;
  }

  .review-ranger-r {
    width: 100%;
    float: none;
  }

  .review-ranger label {
    float: none;
    display: block;
  }

  .icons-three-colls .icons-item {
    width: 100%;
    float: none;
    text-align: center;
    margin: 0px 0px 25px 0px;
  }

  .catalog-head.large .search-select {
    width: 46%;
    margin-bottom: 15px;
    margin-right: 0px;
    float: left;
  }

  .catalog-head.large .search-select:nth-child(2n) {
    float: right;
  }

  .show-table {
    display: none;
  }

  .tour-item .cat-list-item-l {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
    padding: 0px;
  }

  .tour-item .cat-list-item-l img {
    width: 100%;
  }

  .tour-item .cat-list-item-r {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .blockqoute-tp-a.float-left {
    width: auto;
    float: none;
    margin-right: 0px;
  }

  .blockqoute-tp-a.float-right {
    width: auto;
    float: none;
    margin-left: 0px;
  }

  .mm-4,
  .mm-3,
  .mm-2,
  .mm-6,
  .mm-12 {
    width: 100%;
    float: none;
    padding: 0px;
    margin-bottom: 15px;
  }

  .columns .content-wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  .datepicker-popup {
    width: 350px !important;
    min-width: 350px !important;
  }

  .datepicker-v2-month-container:first-child,
  .datepicker-v2-month-container:last-child {
    width: 100%;
  }

  .tour-item-a {
    padding-top: 0px;
  }

}

@media screen and (max-width:560px) {
  .blog-masonry-i {
    margin-bottom: 38px;
    opacity: 0;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }

  .blog-wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  .blog-post-i {
    float: none;
  }

  .blog-post-l {
    width: 100%;
    text-align: left;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .blog-post-c {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .blog-post-p {
    padding: 0px;
  }

  .alt-details-i {
    width: 100%;
    margin: 0px 0px 12px 0px;
    float: none;
  }

  .alt-details-i:nth-child(3) {
    margin-bottom: 0px;
  }

  .faq-item-i {
    margin-top: -5px;
  }

  .h-tabs-right span {
    display: none;
  }

  .available-row .cat-list-item-l {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .available-row .cat-list-item-l img {
    width: 100%;
  }

  .available-row .cat-list-item-r {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .available-row .cat-list-item-p {
    padding-left: 0px;
  }

  .hotel-reviews-i,
  .hotel-reviews-i:nth-child(2n) {
    width: 100%;
    float: none;
  }

  .reviews-l {
    width: 100%;
    float: none;
    margin: 0px 0px 25px 0px;
  }

  .reviews-r {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .todo-row .cat-list-item-l {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .todo-row .cat-list-item-r {
    float: none;
    margin: 0px;
    width: 100%;
  }

  .todo-row .cat-list-item-l img {
    width: 100%;
  }

  .icons-two-colls .icons-item,
  .icons-two-colls .icons-item:nth-child(2n) {
    width: 100%;
    float: none;
    text-align: center;
    margin: 0px 0px 25px 0px;
  }

  .services-i,
  .services-i:nth-child(2n) {
    width: 100%;
    float: none;
  }

  .services-i:last-child {
    margin-bottom: 0px;
  }

  .datepicker-popup {
    width: 300px !important;
    min-width: 300px !important;
  }

  .blog-post-info div {
    display: inline-block;
    margin-right: 15px;
  }
}

@media screen and (max-width:500px) {

  .wrapper-padding,
  .offer-slider-c,
  .offer-slider header,
  .mp-popular,
  .partners,
  .testimonials,
  .pop-destinations,
  .theme-teaser-padding,
  .mobile-menu li,
  .about-content .content-wrapper,
  .about-slider-h,
  .why-we .content-wrapper,
  .inner-breadcrumbs .content-wrapper,
  .blog-wrapper,
  .blog-page,
  .contacts-page,
  .typography .content-wrapper,
  .inner-page .content-wrapper,
  .columns .content-wrapper,
  .testimonials-b {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hdr-srch-overlay-a {
    margin-left: 20px;
    margin-right: 20px;
  }

  .testimonials-flat {
    width: auto;
  }

  .counters-i,
  .counters-i:first-child,
  .counters-i:last-child {
    width: 100%;
    display: block;
    margin: 0px 0px 60px 0px;
  }

  .counters-i:last-child {
    margin-bottom: 0px;
  }

  .about-slider-h.styled {
    height: 310px;
    padding: 50px 0px 0px 0px;
  }

  .flight-line-d1 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .flight-line-d2,
  .flight-line-d4 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .flight-line-d3 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .tab-reasons h2 {
    line-height: 25px;
    margin-bottom: 20px;
  }

  .rates-line-right {
    width: 84%;
  }

  .guest-reviews-l {
    width: 100%;
    float: none;
    margin: 0px;
    text-align: center;
  }

  .guest-reviews-r {
    width: 100%;
    float: none;
    margin: 0px;
    text-align: center;
  }

  .guest-reviews-img {
    margin: 0 auto;
    margin-bottom: 13px;
  }

  .guest-reviews-br nav {
    float: none;
  }

  .guest-rating {
    float: none;
  }

  .guest-reviews-br nav li {
    display: inline-block;
    float: none;
  }

  .counters-five-colls .counters-row-i,
  .counters-five-colls .counters-row-i:last-child {
    width: 100%;
    float: none;
    margin: 0px 0px 30px 0px;
  }

  .counters-four-colls .counters-row-i,
  .counters-four-colls .counters-row-i:nth-child(2n) {
    width: 100%;
    float: none;
    margin: 0px 0px 40px 0px;
  }

  .pie-charts-i,
  .pie-charts-i:nth-child(2n) {
    width: 100%;
    float: none;
    margin: 0px 0px 40px 0px;
  }

  .portfolio-full-more-txt,
  .portfolio-full-more-txt:nth-child(2n) {
    width: 100%;
    float: none;
  }

  .offer-slider-i.catalog-i,
  .offer-slider-i.catalog-i:nth-child(2n),
  .offer-slider-i.catalog-i:nth-child(3n) {
    float: none;
    width: 100%;
  }

  .list-rows .cat-list-content-l {
    margin: 0px;
    float: none;
    width: 100%;
    float: none;
  }

  .list-rows .cat-list-content-r {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .list-rows .cat-list-content-lpadding {
    padding-bottom: 16px;
  }

  .list-rows .cat-list-content-lpadding {
    padding-right: 0px;
  }

  .list-rows .cat-list-content-p {
    padding-left: 0px;
  }

  .portfolio-i {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .portfolio-holder {
    padding-left: 20px;
    padding-right: 20px;
  }

  .portfolio-three-colls .portfolio-i {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .portfolio-holder-three {
    padding-left: 20px;
    padding-right: 20px;
  }

  .table-a tr th:nth-child(4),
  .table-a tr td:nth-child(4) {
    display: none;
  }

  .tabs-type-a .tabs-nav li {
    float: none;
    width: 100%;
    margin: 0px 0px 5px 0px;
  }

  .tabs-type-a .tabs-nav li a {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }

  .tabs-type-bi-l {
    width: 100%;
    float: none;
    margin: 0px 0px 20px 0px;
  }

  .tabs-type-bi-r {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .tabs-type-bi-p {
    padding-left: 0px;
  }

  .tabs-type-bi-rb {
    border-left: 0px;
  }

  .tabs-type-b .tabs-nav a.active,
  .tabs-type-b .tabs-nav a.active:hover {
    border-right: 1px solid #E3E3E3 !important;
  }

  .tabs-type-b .tabs-nav a.active span {
    display: none;
  }

  .accordeon-a span {
    float: right;
    width: 90%;
    line-height: 20px;
  }

  .toggle-lbl {
    line-height: 20px;
    margin-top: 10px;
  }

  .datepicker-popup {
    width: 280px !important;
    min-width: 280px !important;
  }

  .testimonials-flat {
    padding: 40px 0px 0px 0px;
    height: 320px;
  }

  .list-rows .cat-list-content-lpadding {
    border-right: 0px;
  }
}

@media screen and (max-width:435px) {
  .form-sex {
    float: none;
    width: 100%;
    margin-bottom: 17px;
  }

  .form-calendar {
    float: none;
    width: 100%;
    margin: 0px;
  }

  .tab-calendar-colls table tbody td {
    height: 40px;
  }

  .accordeon-a span {
    float: right;
    width: 89%;
  }

  .mp-slider-lbl {
    font-size: 29px;
    padding-top: 150px;
  }
}

@media screen and (max-width:401px) {
  .alt-time {
    margin-top: 10px;
  }
}

@media screen and (max-width:390px) {
  .about-slider-txt {
    line-height: 22px;
  }

  .mp-slider-lbl {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
  }

  .mp-slider-lbl-a {
    font-size: 15px;
    line-height: 24px;
    padding-top: 11px;
    padding-bottom: 19px;
  }

  .mp-slider-lbl {
    padding-top: 123px;
  }

  .page-search {
    width: 302px;
  }

  .search-tab {
    padding-left: 25px;
    padding-right: 26px;
  }

  .search-tab.nth {
    padding-right: 26px;
    padding-left: 25px;
  }

  .search-line input[type="text"] {
    width: 240px;
  }

  .search-line-a input[type="text"],
  .search-line-b input[type="text"] {
    width: 102px;
  }

  .search-line-a,
  .search-line-b {
    width: 121px;
  }

  .search-select select {
    width: 114%;
  }

  .search-line-select {
    width: 122px;
  }

  .search-select {
    width: 118px;
  }

  .search-line-select {
    margin-right: 0px;
    margin-bottom: 18px;
  }

  .search-line-select.adults {
    float: right;
    margin-right: 0px;
  }

  .search-line-select.nth {
    float: none;
    margin-bottom: 0px;
  }

  .search-line-select.nth .search-select {
    width: 253px;
  }

  .wrapper-a {
    bottom: 374px;
  }

  .header-phone {
    border-left: 0px;
    padding-left: 0px;
  }

  .header-lang a {
    border-left: 0px;
  }

  .search-select.full-width {
    width: 253px;
  }

  .search-select.full-width select {
    width: 109%;
  }

  .search-select.half-width {
    width: 118px;
  }

  .search-select.half-width select {
    width: 113%;
  }

  .search-line-a.hotel-stars {
    float: none;
    margin-bottom: 20px;
    width: 100%;
    margin-right: 0px;
  }

  .search-line-a.hotel-stars .search-select.half-width {
    width: 253px;
  }

  .search-line-b.peoples {
    float: none;
    width: 100%;
    margin-right: 0px;
  }

  .search-line-b.peoples .search-select.half-width {
    width: 253px;
  }

  .search-select.half-width select {
    width: 110.7%;
  }

  .search-line-a.peoples,
  .search-line-a.price {
    float: none;
    margin-bottom: 20px;
    width: 100%;
    margin-right: 0px;
  }

  .search-line-a.peoples .search-select.half-width,
  .search-line-a.price .search-select.half-width {
    width: 253px;
  }

  .search-line-b.class,
  .search-line-b.company {
    float: none;
    width: 100%;
    margin-right: 0px;
  }

  .search-line-b.class .search-select.half-width,
  .search-line-b.company .search-select.half-width {
    width: 253px;
  }

  .search-type-a .srch-tab-3c {
    width: 46.5%;
    float: left;
    margin: 0px 0px 15px 0px;
  }

  .search-type-a .srch-tab-3c:nth-child(2) {
    width: 46.5%;
    float: right;
    margin: 0px 0px 15px 0px;
  }

  .search-type-a .srch-tab-3c:nth-child(3) {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .search-type-b .srch-tab-3c {
    width: 46.5%;
    float: left;
    margin: 0px 0px 15px 0px;
  }

  .search-type-b .srch-tab-3c:nth-child(2) {
    width: 46.5%;
    float: right;
    margin: 0px 0px 15px 0px;
  }

  .search-type-b .srch-tab-3c:nth-child(3) {
    width: 100%;
    float: none;
    margin: 0px;
  }

  .search-type-a .srch-tab-left.transformed {
    width: 100%;
    float: none;
    margin: 0px 0px 15px 0px;
  }

  .search-type-a .srch-tab-right.transformed {
    width: 100%;
    float: none;
    margin: 0px 0px 0px 0px;
  }

  .search-type-b .srch-tab-left.transformed {
    width: 100%;
    float: none;
    margin: 0px 0px 15px 0px;
  }

  .search-type-b .srch-tab-right.transformed {
    width: 100%;
    float: none;
    margin: 0px 0px 0px 0px;
  }

  .search-only .mp-slider-lbl {
    padding-top: 60px;
  }

  .page-search.full-width-search {
    width: 302px;
  }

  .page-search.full-width-search .page-search-tabs {
    width: 100%;
  }

  .full-width-search .wrapper-a {
    bottom: 374px;
  }

  .footer-subscribe {
    width: 97.5%;
  }

  .alt-fligt-table {
    width: 100%;
    margin-right: 0px !important;
    float: none !important;
  }

  .alt-fligt-table:nth-child(2n) {
    width: 100%;
    margin-right: 0px !important;
    float: none !important;
  }

  .search-select select {
    width: 122%;
  }

  .catalog-head .search-select {
    width: 100%;
    float: none;
    margin-bottom: 15px;
  }

  .catalog-head .search-select:last-child {
    margin-bottom: 0px;
  }

  .header-account a {
    padding-left: 19px;
    padding-right: 19px;
  }

  .header-lang {
    display: none;
  }

  .tweeter-item-r {
    width: 93.2%;
  }

  .search-widget input[type="text"] {
    width: 93.2%;
  }

  .alt-departure {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .alt-arrival {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .alt-time {
    width: 100%;
    float: none;
    margin-bottom: 0px;
  }

  .contacts-colls .booking-form-i {
    width: 100%;
    float: none;
  }

  .booking-form .checkbox label {
    width: 100%;
    float: none;
  }

  .add-passanger {
    float: none;
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }

  .card-type {
    width: 100%;
    float: none;
    margin-bottom: 9px;
  }

  .payment-tab .checkbox label {
    line-height: 19px;
  }

  .flight-line-a {
    /* margin-top: 13px; */
    /* margin-left: 5px;
    margin-right: 5px; */
  }

  .flight-image span {
    display: none;
  }

  .tab-calendar-colls table tbody td {
    height: 27px;
  }

  .calendar-tab-select select,
  .calendar-tab-select .customSelect1 {
    width: 95%;
  }

  .tab-calendar-colls table tbody td span p {
    padding: 4px 6px 0px 0px;
  }

  .flight-line-d1 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .flight-line-d2,
  .flight-line-d4 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .flight-line-d3 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .rates-line-right {
    width: 100%;
    float: none;
  }

  .rates-line .srch-tab-left {
    width: 100%;
    float: none;
    margin-bottom: 15px;
  }

  .rates-line .srch-tab-right {
    width: 100%;
    float: none;
  }

  .rates-srch {
    width: 100%;
    background-position: center center;
  }

  .rates-srch:hover {
    width: 100%;
    background-position: center center;
  }

  .catalog-head.large .search-select,
  .catalog-head.large .search-select:nth-child(2n) {
    width: 100%;
    float: none;
  }

  .catalog-head.large .search-select:nth-child(5) {
    margin-bottom: 5px;
  }

  .accordeon-a span {
    float: right;
    width: 87%;
  }

  .datepicker-popup {
    width: 210px !important;
    min-width: 210px !important;
  }

  .testimonials-flat {
    padding: 30px 0px 0px 0px;
    height: 330px;
  }
}


@media screen and (max-width:313px) {
  .header-logo {
    margin: -12px 0px 0px 0px;
  }

  .header-right {
    margin-top: 0;
  }
}

.paddingrbus {
  padding: 50px 10px 10px 10px;
}

.chooseseat {
  font-size: 14px;
  color: #4c4c4c;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
  font-family: "Montserrat";
  font-size: 20px;
  color: #bd8100;
  font-weight: 600;
}

.knowseat {
  font-size: 14px;
  color: #4c4c4c;
  text-decoration: none;
  font-weight: normal;
  font-family: "Montserrat";

  color: #bd8100;
  font-weight: 600;
}

.seattype table,
.seattype table thead th,
.seattype table tbody td {
  font-size: 14px;
  color: #4c4c4c;
  text-decoration: none;
  font-weight: normal;
  font-family: "Montserrat";

}

.busr .bus,
.busl .bus {
  border: 1px solid #ddd;
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
}

.busr .bus.seat2-3,
.busr .bus.seat2-2,
.busl .bus.seat2-3,
.busl .bus.seat2-2 {
  .seats .seat:nth-child(1) {
    margin-right: 20.285714%
  }
}

.busr .bus.seat3-2,
.busl .bus.seat3-2 {
  .seats .seat:nth-child(3) {
    margin-right: 14.28571428571429%;
  }
}

.busr .seats,
.busl .seats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-flex-pack: center !important;

  padding: 0;
  margin-bottom: -2px;
}

.busr .seats .seat,
.busl .seats .seat {
  display: flex;
  flex: 0 0 44.285714%;
  padding: 3px;
  position: relative;
}

.busr .seats .seat label,
.busl .seats .seat label {
  border-radius: 4px;
  background: #3783b5;
  padding: 0;
  width: 25px;
  height: 25px;
  margin-bottom: 0.1rem;
  display: inline-block;
  font-size: 0.7rem;
}

.busr .seats .seat input[type="checkbox"],
.busl .seats .seat input[type="checkbox"] {
  display: none !important;
}

.busr .seats .seat input[type="checkbox"]+.seater,
.busl .seats .seat input[type="checkbox"]+.seater {
  background: url(../public/img/taxivaxi/meal_seats/bue_seater_seat_white.svg) center top no-repeat;
  background-size: 30px auto;
  height: auto;
  width: 30px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 25px 0px;
  color: #fff;
}

.busr .seats .seat input[type="checkbox"]+.sleeper,
.busl .seats .seat input[type="checkbox"]+.sleeper {
  background: url(../public/img/taxivaxi/meal_seats/bue_available_sleeper_seat.svg) center top no-repeat;
  background-size: 40px auto;
  height: auto;
  width: 30px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 25px 0px;
  color: #fff;
}

.busr .seats .seat input[type="checkbox"]:hover+label,
.busl .seats .seat input[type="checkbox"]:hover+label {
  /* Additional hover styles if needed */
}

.busr .seats .seat input[type="checkbox"]:checked+.seater,
.busl .seats .seat input[type="checkbox"]:checked+.seater {
  background: url(../public/img/taxivaxi/meal_seats/bue_seater_seat_orange.svg) center top no-repeat;
  background-size: 30px auto;
  height: auto;
  width: 30px;
}

.busr .seats .seat input[type="checkbox"]:checked+.sleeper,
.busl .seats .seat input[type="checkbox"]:checked+.sleeper {
  background: url(../public/img/taxivaxi/meal_seats/bus_sleeper_seat_select.svg) center top no-repeat;
  background-size: 40px auto;
  height: auto;
  width: 30px;
}

.busr .seats .seat input[type="checkbox"]:disabled+.seater,
.busl .seats .seat input[type="checkbox"]:disabled+.seater {
  cursor: not-allowed;
  background: url(../public/img/taxivaxi/meal_seats/bue_seater_seat_disabled.svg) center top no-repeat;
  background-size: 30px auto;
  height: auto;
  width: 30px;
}

.busr .seats .seat input[type="checkbox"]:disabled+.sleeper,
.busl .seats .seat input[type="checkbox"]:disabled+.sleeper {
  cursor: not-allowed;
  background: url(../public/img/taxivaxi/meal_seats/bue_choosen_sleeper_seat.svg) center top no-repeat;
  background-size: 40px auto;
  height: auto;
  width: 30px;
}

.busr .text-left .mb-2,
.busl .text-left .mb-2 {
  padding: 0px;
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: normal;

}

.busseat {
  padding: 20px;
  /* min-height:450px; */
  background-color: #fff;
}

.busseat .busl {
  border: 2px solid #ebebeb;
  height: 100%;
  padding: 10px 15px;
  margin-right: 2%;

}

.busr {
  width: 0px 0px 0px 340px;

}

.busl .seatplace {
  color: black;
  font-family: 'Montserrat';
}

.buspassenger {
  margin-top: 2%;
  background-color: #fff;
  padding: 20px;
  min-height: 295px;
  width: 100%;
}

.buspassenger .form-group {
  padding: 1%;
}

.buspassengerrl {
  width: 50%;
  float: left;
}

.buspassengerr {
  width: 50%;
  float: left;
}

.buspassenger .form-group label {
  margin-bottom: 1%;
}

.buspassenger .passenger_button {
  float: inline-end;
  margin-right: 0.6%;
  padding: 6px 20px;
  margin-top: 1%;
  font-family: "Montserrat";
  font-size: 13px;
  background-color: #785eff;
  border-color: #785eff;
  font-weight: 600;
  text-transform: uppercase;
}

.buspassenger .passenger_button:hover {
  color: #785eff;
  background-color: #fff;
  border-color: #785eff;
}

.buspassenger form {
  font-family: "Montserrat";
  font-size: 13px;
  color: black;
}

.buspassenger .form-control {
  font-family: "Montserrat";
  font-size: 13px;
}

.busprice .reviews-total {
  margin-top: 10px;
  font-size: 25px;
}

.busprice .reviews-l {
  width: 100px;
}

.busprice .reviews-r {
  margin: 0px 0px 0px 100px;
}

.busprice .form-check {
  margin-top: 5%;
  font-family: 'Montserrat';
  padding: 0.5em 1.5em;
}

.busprice .guest-reviews-l {
  width: 40px;
}

.busprice .guest-reviews-l .guest-reviews-img {
  width: 30px;
  height: 40px;
  margin-top: 0px;
}

.busprice .guest-reviews-r {
  margin: 0px 0px 0px 40px;
}

.busprice .guest-reviews-img span {
  height: 30px;
  padding-top: 0px;
}

.busprice .guest-reviews-bl {
  margin: 0px 55px 0px 0px;
}

.busprice .guest-reviews-padding {
  padding-left: 120px;
}

.busprice .review-ranger-r {
  width: 100%;
}

.buscontact {
  min-height: 280px;

}

.busprice table,
.busprice table tbody,
.busprice table tbody tr {
  width: 100%;
}

.busprice table tbody tr td {
  width: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #bd8100;
}

input:focus+.slider {
  box-shadow: 0 0 1px #bd8100;
}

input:checked+.slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.seattype table thead th {
  text-align: center;
}

.seattype table tbody td img {
  display: block;
  margin: auto;
}

.lowerdeck,
.upperdeck {
  float: left;

  padding: 1%;
  margin-right: 1%;
}

.img0 {
  position: absolute;
  width: 40px;

}

.img1 {
  position: relative;
  left: 40px;
  width: 20px;
  height: 20px;
  top: -12px;
  border: 1px solid black;
  border-radius: 50%;
}

.img2,
.img3,
.img4,
.img5,
.img6 {
  width: 0px;
}

.imgsup {
  position: relative;
  top: -30px;
  width: 15px;
  left: 55px;
  display: block;
  border-radius: 50%;
  padding: 1%;

  line-height: 12px;
  height: 15px;
  background-color: #bd8100;
}

.selecthead {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

}

.selectpricehead {
  background-color: #fff;
  padding: 1% 3%;
  font-weight: 700;
  color: #bd8100;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.selectpricehead .booktype {
  float: inline-end;
  text-transform: capitalize;
}

.multipleflight .modal-header {
  background-color: #785eff;
  color: #fff;
}

.multipleflight .modal-header .modal-title {
  font-weight: 700;
  font-size: 19px;
  /* margin-left: 2%; */
  font-family: 'Montserrat';
  text-transform: uppercase;
}

.multipleflight .modal-header .modal-title span {
  font-size: 13px;
  text-transform: capitalize;
}

.multipleflight .modal-header .close {
  border: none;
    border-radius: 50%;
    font-weight: 900;
    font-size: larger;
    margin-right: 15px;
    padding: 4px;
    line-height: 9px;
}

.multipleflight .morebutton {
  background-color: #fff;
  color: black;
  border: 1px solid black;
  font-size: 13px;
  padding: 7px;
  font-weight: 600;
}

.multipleflight .taxbutton {
  background-color: #fff;
  border: none;
  color: black;
  font-size: 12px;
  float: left;
  padding: 0px;
  font-family: 'Montserrat';
}

.multipleflight .taxbutton:hover,
.multipleflight .taxbutton:focus {
  border: none;
  color: black;
  background-color: #fff;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.multipleflight .morebutton:hover,
.multipleflight .morebutton:focus {
  border: 1px solid black;
  color: black;
  background-color: #fff;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.selectcontainer {
  margin: 1% 2%;
}

.selectcontainer .optionsflights {
  margin: 1% 1%;
  padding: 7px 10px;
  font-size: 12px;
  cursor: pointer;
  /* width: 23%; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px;
  background: white;
  border: 1px solid #e0e0e0;
  /* border-radius: 10px; */
  position: relative; /* Needed for positioning the button */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  transition: box-shadow 0.2s ease;
}

.selectcontainer .optionsflights .optionsflight{
  height: 40px;
}
.selectcontainer .optionsflights:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #785eff;
}
.modal-body .continuebutton {
  background-color: #785eff;
  /* padding: 7px 7px; */
  border: 1px solid #785eff;
  color: #fff;
  font-family: 'Open Sans';
  margin-left: 2%;
  
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
  float: right;
  
  text-transform: uppercase;
}
.modal-header .modal-title{
  margin-left: 0 !important;
}
.modal-body .continuebutton:hover {
  background: #644ed4;
  border-color: #644ed4;
}



.optionsflights .seelctheader {
  font-size: 12px;
  font-weight: 600;
  color: black;

  text-transform: capitalize;
  font-family: 'Montserrat';
}



.optionsflights .selectdetail ul {
  list-style: none;
  margin: 0px;
  padding: 2px;
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: normal;
  /* border: 1px solid #785eff; */
  /* background-color: grey; */
}
/* .selectdetail {
  display: block !important; 
  background-color: #FBFBFB;  
  color: #000 !important;  
  padding: 10px; 
  float: left;
} */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
  z-index: 999; /* Ensure it appears above other content */
}

/* Popup container */
.selectdetail {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff; 
  color: #000; 
  font-size: 13px;
  font-weight: 450;
  padding: 15px;
  width: 80%; 
  max-width: 450px; 
  border-radius: 4px; 
  border: 2px solid black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000; 
  text-align: center; 
}

/* Close button */
.selectdetail-close {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  color: #888;
  font-size: 20px;
  cursor: pointer;
}

.selectdetail-close:hover {
  color: #000;
}


.optionsflights .selectprice {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat';
}

.packagemore {
  float: right;
  margin-right: 1%;
  color: #fff;
  font-size: 12px;
  border: 1px solid #785eff;
  font-family: 'Open Sans';
  padding: 8px;
  line-height: 13px;
 
  border-radius: 3px;
  margin-top: -4px;
  font-weight: 700;
  background: #785eff;
  text-transform: uppercase;
}
.packagemorespan{
  width: 9px;
  display: block;
  height: 10px;
}
.packagemore:hover {
  border: 1px solid #644ed4;
  background-color: #644ed4;
}

.multipleflight .tabcontent {
  display: none;
  padding: 6px 12px;
  border: none;

}

.farefirstindex {
  display: block;
}

.multipleflight .tab {
  overflow: hidden;
  background-color: #fff;
  border: none;
}

.multipleflight .accordionpackage {
  background-color: #fff;
  float: left;
  border: 1px solid #bd8100;
  width: 95%;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  color: #bd8100;
  font-weight: 800;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  margin: 1% 2.4%;
}


.accordionpackage.active,
.accordionpackage:hover {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
}

.multipleflight .panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}
.multipleflight .panelfare .fareaccordion{
color: #785eff;
}
.accordionfarecabinbag1,.accordionfarehandbag1,
.multipleflight .panelfare {
  font-size: 10px;
  font-weight: 600;
  margin: 3% 1%;
}
.accordionfarecabinbag1 ul,.accordionfarehandbag1 ul,
.multipleflight .panelfare ul{
  list-style: none;
  padding: 0%;
  margin-bottom: 0px;
}
.multipleflight .panelfare .accordionfare {
  border: none;
  background-color: #fff;
  padding: 0px;

  font-weight: 700;

  color: #bd8100;

}

.multipleflight .panelfare {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 3%;
  /* margin: 2% 5%; */
}

.electcontainer .checkedflight:checked+.optionsflights {
  border: 1px solid #785eff;
}

/* .selectedDiv {
  border: 2px solid #bd8100;
} */

.accordionpackage {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

.accordionpackage:first {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */


/* Style the accordion panel. Note: hidden by default */
.panelpackage {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

#back_button {
  text-decoration: none;
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding: 1%;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  border: none;
  font-family: 'Open Sans';
  border-radius: 4px;
  text-transform: uppercase;
  width: 130px;
  margin-right: 4px;
}

#back_button:hover {
  background-color: #fff;
  border: 1px solid black;
  color: black;
}
.flight_nav{
  display: flex;
  border: none;
}
.flight_nav  .nav-item a{
border: none;
font-size: 11px;
color: #848484;
text-transform: uppercase;
font-family: 'Montserrat';
font-weight: 600;
text-decoration: none;
padding: 9px 12px;
    margin-right: 7px;
cursor: pointer;
background-color: rgba(0,0,0,.03);
}
.flight_nav  .nav-item .nav-link.active{
background: #785ef7;
color: #fff;
}
.moreairlineimg{
  width: 20px;
  position: absolute;
  margin-top: -50px;
  margin-left: -9px;
  
}
.modal-header{
  background-color: #785eff;
    color: #fff;
}
.modal-header .modal-title{
  font-weight: 700;
  font-size: 19px;
  margin-left: 2%;
  font-family: 'Montserrat';
  text-transform: uppercase;
  line-height: 40px;
}

.modal-header .modal-title span{
  font-size: 13px;
    text-transform: capitalize;
}

.booking-left .accordion div{
  margin: 0px;
}
.seatprevbutton{
  margin-top: 35%;
  margin-left: 5%;
  border: 2px solid #bd8100;
  border-radius: 60%;
  float: left;
  background: #bd8100;
  text-transform: capitalize;
  font-family: 'Raleway';
  font-size: 17px;
  color: #fff;
  font-weight: 600;
}

.seatnextbutton{
  margin-top: 35%;
    margin-right: -15%;
    border: 2px solid #bd8100;
    border-radius: 60%;
    float: right;
    background: #bd8100;
    text-transform: capitalize;
    font-family: 'Raleway';
    font-size: 17px;
    color: #fff;
    font-weight: 600;
}
.rc-slider-track{
  background-color: #785eff !important; 
}
.rc-slider-handle{
  border: solid 2px #785eff !important;
}
.rc-slider{
  /* margin-left: 8px !important; */
}
.dropdown-content p {
  padding: 7px 14px;
    text-decoration: none;
    display: inline;
    margin-bottom: 2%;
    font-weight: 500;
    line-height: 18px;
    margin-right: 1%;
    width: 100px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Open Sans';
    background-color: #bd8100;
    border: 1px solid #bd8100;
    cursor: pointer;
    font-weight: 600;
    width: 100px;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 50px;
    color: #fff;
}
.dropdown-content p:hover {
  background: #ffca27;
  border-color: #ffca27;
}

.accordionfarecabinclass,
.accordionfarecabinbag,
.accordionfarehandbag{
  font-size: 12px;
    width: auto;
    color: black;
    font-family: 'Montserrat';
    font-weight: 600;
    margin: auto;
    text-align: center;
    
}
.accordionfarename,
.accordionfarename1{
  font-size: 12px;
    width: auto;
    color: black;
    font-family: 'Montserrat';
    font-weight: 600;
    margin: auto;
}
.accordionfarecabinclass1,
.accordionfarecabinbag1,
.accordionfarehandbag1{
  color: #000;
    font-size: 13px;
    margin: auto;
    font-weight: 500;
    text-align: center;
}


.modal-header .btn-close {
  border-radius: 50% ;
  font-weight: 900;
  background-color: white;
  font-size: 5px;
  margin-right: 0px !important;
  padding: 6px;
  color: black;
  font-weight: 900;
  opacity: 1;
  line-height: 1px;

}
.react-datepicker__header {
  background-color: #785eff !important;
  border-bottom: 1px solid #785eff !important;
  
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,
.react-datepicker__day-name {
  color: #FFF !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: #785eff !important;
  
}

/* .react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #ffca27 !important;


} */

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  fill: #785eff;
    color: #785eff;
    stroke: #785eff;
}
.swapbutton{
  background: none;
    padding: 0px;
    margin: 3px;
    padding-top: 30px;
    border: none;
    height: 70px;
    line-height: 5.5em;
}
.govid{
  font-weight: 500;
  text-transform: math-auto;
  
  color: black;
  padding: 0% 1%;
  background-color: yellow;
}
.swal2-confirm{
  padding: 0.425em 1.1em !important;
  background-color: #785eff !important;
}
.swal2-confirm:hover{
  background: #644ed4 !important;
  border-color: #644ed4 !important;
}
.swal2-icon{
  width: 3em !important;
  height: 3em !important;
  border-color: #785eff !important;
  margin: 0.5em auto 0.6em !important;
}

.swal2-icon-content{
  font-size: 2.75em !important;
  color: #785eff !important;
}

.swal2-cancel {
  padding: 0.425em 1.1em !important;
  background-color: #000 !important;
}
.swal2-cancel:hover {
  background: #fff !important;
  border: 1px solid;
  border-color: #000 !important;
  color: #000 !important;
}
.swal2-html-container{
  font-family: 'Open Sans' !important;
  font-size: 15px !important;
}
.swal2-title{
  font-size: 23px !important;
}
.headingpolicies{
  background-color: #fff;
  color: #444;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  padding: 18px;
  width: 100% !important; 
  border: none;
  display: block;
  text-align: left;
  outline: none;
  font-size: 13px;
  transition: 0.4s;
  margin: 0px 0px;
  /* display: flex; */
}
.headingpolicies .farerules{
  float: right;
    color: #785eff;
    background-color: #fff;
    border: none;
    font-weight: 600;
    text-transform: capitalize;
    display: flex !important;
    align-items: center !important;
    margin-left: 66%;
}
.policydetails2{
  margin: 0% !important;
}
.emptyseatmap{
  background-color: rgba(0, 0, 0, .03) !important;
  cursor: not-allowed !important;
}
.catalog-row .catalog_filters{
  background-color: #fff;
  margin-left: 0px;
  margin-bottom: 1%;
  font-family: 'Montserrat';
  padding: 1%;
  font-weight: 600;
  color: black;
  font-size: 14px;
  width: 953px;

}
.catalog-row .catalog_filters .departurefilter{
  text-align: right;
  padding-left: 5%;
  cursor: pointer;
}
.catalog-row .catalog_filters .travelfilter{
  text-align: right;
  cursor: pointer;
}
.catalog-row .catalog_filters .arriavelfilter{
  text-align: right;
  padding-left: 8%;
  cursor: pointer;
}
.catalog-row .catalog_filters .stopsfilter{
  text-align: center;
  cursor: pointer;
}
.catalog-row .catalog_filters .pricefilter{
  text-align: center;
  padding-left: 13%;
  cursor: pointer;
}
.packagehead{
  font-weight: 600;
    font-family: 'Montserrat';
    font-size: 14px;
}
.airline-name{

}
.flightnumber{
  font-size: 11px;
    font-family: 'Montserrat';
    display: flex;
    width: auto;
    float: left;
    color: #000;
    margin-top: 5px;
}
.flightnumber1{
  font-size: 11px;
  font-family: 'Montserrat';
}
.company{
  width: 100%;
  margin-left: 5%;
}
.airlineimg1{
    position: absolute;
  /* top: -22%; */
  margin-top: -50px;
  margin-left: -6px;
  width: 25px;
}
.airlineimg2{
  display: none;
}



.extrabaggage .tab-content .extrabaggageinput input[type="radio"]{
  height: 10px !important;
  margin: 0px !important;
 
  width: 10px !important;
}

.extrabaggage .tab-content .extrabaggageinput label{
padding-left: 10px !important;
}


.extrabaggage{
  padding: 30px 20px 16px;
}

.extrabaggage .tab-content {
  border-color: #fff #785eff #785eff #785eff !important;
  border-style: solid !important;
  border-width: 0px 1px 1px 1px !important;
margin-right: 3% !important;
}
.extrabaggage .nav-tabs{
  border-color: #785eff !important;
  border-bottom-width: 1px !important;
  margin-right: 3% !important;
}

.extrabaggage .nav-tabs .nav-link{
  border-top-color: rgba(0, 0, 0, .03) !important;
  border-left-color: rgba(0, 0, 0, .03) !important;
  border-right-color: rgba(0, 0, 0, .03) !important;
  background-color: rgba(0, 0, 0, .03) !important;
  color: #000 !important;
  width: 100% !important;
    padding: 8px 25px !important;
    font-size: 14px !important;
    text-align: center !important;
    border-radius: 0px !important;
    font-weight: 600 !important;
   cursor: pointer !important;
    font-family: 'Montserrat' !important;
 
}
.extrabaggage .nav-tabs .nav-link.active{
  border-bottom-color: #fff !important;
  border-top-color:  #785eff !important;
  border-left-color:  #785eff !important;
  border-right-color:  #785eff !important;
  background-color: #fff !important;
  color: #785eff !important;
  
}
.extrabaggage .tab-content .extrabaggageinput{
  padding: 5px 20px !important;
  font-family: 'Montserrat' !important;
  text-transform: lowercase !important;
  background-color: #fff !important;
  margin: 1% !important;
  font-size: 13px;
  box-shadow: 5px 3px 5px #e3e3e3;
  color: #785eff !important;
}

.extrabaggageinput input[type='radio']:checked ~ .extrabaggageinput {
  background-color: #bd8100 !important;
}
.extrabaggageinput .baggageoptionbutton{
  background-color: #fff;
  color: #fff;
  border: 1px solid #bd8100;
  border-radius: 3px;
  padding: 7px 7px;
  font-size: 14px;
  width: 92px;
  text-transform: uppercase;
  font-weight: 700;
}
.extrabaggageinput .baggageoptionbuttonselected{
color: #785eff !important;
background-color: #fff !important;
}

.extrabaggage .tab-content .tab-pane{
  padding: 2%;
}
.extrabaggageinput .baggageoptionbuttons{
  text-align: end;
  padding: 1%;
  font-size: 15px;
}

.extrabaggageinput .buttonoption0{
  float: left;
  padding: 1%;
    color: #785eff;
    font-weight: 600;
    text-align: center;
    width: 30%;
    background-color: #fff;
    border: 1px solid #785eff;
    display: inline-block;
    cursor: pointer;
}
.extrabaggageinput .buttonoptionspan{
  background-color: #fff;
  padding: 1%;
  color: #785eff;
  text-align: center;
  font-weight: 600;
  width: 40%;
  border: 1px solid #785eff;
  display: inline-block;
 
}
.extrabaggageinput .buttonoption1{
      float: right;
      padding: 1%;
      cursor: pointer;
  text-align: center;
  color: #785eff;
  font-weight: 600;
  background-color: #fff;
  width: 30%;
  border: 1px solid #785eff;
  display: inline-block;
}
.baggaegdetails{
  font-weight: 500;
  font-size: 11px;
}
.headingofflights{
  padding: 2%;
  padding-left: 6%;
  font-weight: 700;
  text-transform: capitalize;
  color: black;
  font-family: 'Montserrat'
}
.seatbutton,.baggagebutton{
float: inline-end;
margin-right: 5%;
}

.seatbutton .seatbuttonskip,
.baggagebutton .baggagebuttonskip{
  background-color: #785eff;
    border: 1px solid #785eff;
    margin-top: 9%;
    margin-bottom: 10%;
    padding: 5px 14px;
    
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 600;
    border-radius: 3px;
    font-size: 14px;
    text-transform: uppercase;
}
.disabledskip{
  background-color: #e3e3e3 !important;
  color: #4c4c4c !important;
  border: 1px solid #e3e3e3 !important;
  cursor: not-allowed;
}
.seatbutton .seatbuttonskip:hover,
.baggagebutton .baggagebuttonskip:hover{
  background: #644ed4;
  border-color: #644ed4;
}
.complete-txt .reservehead{
  font-family: 'Montserrat';
  font-size: 14px;
  color: #bd8100;
  font-weight: 600;
}
.complete-txt .service_details{
  font-family: 'Montserrat';
  font-size: 10px;
}
.complete-txt .service_details ul{
  list-style: none;
  padding: 0px;
}
.complete-txt .servicesdiv{
  box-shadow: 1px 1px 4px 1px #e3e3e3;
  padding: 15px;
  margin: 2%;
}
.complete-txt .servicesdiv .servicesdivhead{
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 21px;

}
.complete-txt .serviceinfo{
  margin-left: 0px;
  margin-right: 3px;
}
.complete-txt .serviceinfo .servicess{
  width: 100%;
  padding: 1.5%;
  font-family: 'Montserrat';
  font-size: 15px;
  color: #bd8100;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 1px 1px 1px 1px #e3e3e3;
  /* background: #fff; */


}
.complete-txt .serviceinfo .servicedetails{
  border: 1px solid #e3e3e3;

}
.abcspan{
  display: inline-block;
  margin: auto;
  text-align: center;
}
.seat_selection .noted_seat{
  float: right;
    width: 113px;
    color: #785eff;
    font-size: 10px;
    font-family: 'Montserrat';
    padding: 1%;
    margin: 1%;
    font-weight: 600;
    border: 1px solid #785eff;
}
.seat_selection .noted_seat .noted_seat_free{
  border: 1px solid rgb(80, 227, 194);
  width: 0;
  border-radius: 3px;
  padding: 7%;
  background-color: rgb(80, 227, 194);
}
.seat_selection .noted_seat .noted_seat_300{
  border: 1px solid rgb(186, 218, 255);
  width: 0;
  border-radius: 3px;
  padding: 7%;
  background-color: rgb(186, 218, 255);
}
.seat_selection .noted_seat .noted_seat_g300{
  border: 1px solid #9B7AC7;
  width: 0;
  border-radius: 3px;
  padding: 7%;
  background-color: #9B7AC7;
}
.seat_selection .noted_seat .noted_seat_clear{
  margin-bottom: 2px;
}

.seat_selection .noted_seat  .noted_seat_disabled{
  border: 1px solid #d8d8d8;
  width: 0;
  height: 10px;
  border-radius: 3px;
  padding: 7%;
  background-color: #d8d8d8;
}

.seat_selection .noted_seat  .noted_seat_noseat{
  width: 0px;
  margin-left: -12px;
}
.seat_selection .noted_seat  .noted_seat_noseat img{
width: 19px;
}
.card-body .seat_selection::-webkit-scrollbar {
  width: 10px; 
  border-radius:3px;
}

.card-body .seat_selection::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.card-body .seat_selection::-webkit-scrollbar-thumb {
  background: #785eff; 
}

.card-body .seat_selection::-webkit-scrollbar-thumb:hover {
  background: #785eff; 
}
body::-webkit-scrollbar {
  width: 10px; 
  border-radius:3px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #785eff; 
}

body::-webkit-scrollbar-thumb:hover {
  background: #785ef7; 
}
.serviceinfo .passengers{
  width: 100%;
  margin: 1%;
}
.serviceinfo .complete-txt-link{
  margin: 1%;
}
.scrolltotop{
    width: 100%;
    border: 1px solid #785eff;
    padding: 5px;
    font-family: 'Montserrat';
    font-size: 15px;
    color: #785eff;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #fff;
}
.scrolltotop:hover{
  color: #644ed4;
  border: 1px solid #644ed4;
}
.datanotfound{
  font-family: 'Montserrat';
}
.selectcontainer .optionsflights::-webkit-scrollbar {
  width: 5px; 
  border-radius:3px;
}

.selectcontainer .optionsflights::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.selectcontainer .optionsflights::-webkit-scrollbar-thumb {
  background: #785eff; 
}

.selectcontainer .optionsflights::-webkit-scrollbar-thumb:hover {
  background: #785eff; 
}
.back-home-btn{
  border-color: #785eff;
  background-color: #785eff;
  padding: 11px 17px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  
  border-radius: 3px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 14px;
}
.back-home-btn:hover{
  background-color: #644ed4;
  border-color: #644ed4;

}
.extradisabled{
  font-family: 'Montserrat';
    color: #000;
    background: red;
    padding: 0 5px;
    margin: 0 5px;
    font-weight: 500;
    text-transform: capitalize;
}
.apiairportresult{
  width: 150px;
  display: inline-block;
  white-space: normal;
  /* opacity: 1; */
  color: #a0a0a0 !important;
  font-size: 9px !important;
}
.fareapiairport{
  color: #a0a0a0 !important;
  font-size: 11px !important;
  font-weight: 500;
}
.confirmtocontinue{
    width: 93%;
    font-size: 13px;
    margin: 1%;
    font-family: 'Montserrat';
}
.accordionfarename .apicircle{
  width: 3%;
  font-size: 9px;
  font-weight: 600;
  margin: 1%;
}
.accordionfarename .airportname{
  font-size: 14px;
  width: auto;
  color: black;
  font-family: 'Montserrat';
  font-weight: 600;
  margin: 1%;
}
.accordionfarename .airport{
  /* font-size: 14px;*/
   width: auto; 
  color: black;
  font-family: 'Montserrat';
  font-weight: 600;
  margin: 1%;
}
.flight-details-container {
  background: #f4f4f4;
  padding: 15px;
  /* border-radius: 5px; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.accordionfarename .vertical_line{
  border-left: 2px dashed #a0a0a0;
    height: 22px;
    margin-left: 2.8%;
    width: 4.6%;
}
.airportcabin{
  font-size: 13px;
}

.airportflightnumber{
  /* margin-left: 1%; */
}
.airportairline{
  color: black;
    font-weight: 600;
}
.apiairportname1{
  color: #a0a0a0 !important;
  font-weight: 500 !important;
  font-size: 11px !important;
}
#grad1 {
  height: auto;
  padding: 1;
  background-image: linear-gradient(to right, 
    green 0%,green 100%);
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2% 0;
}
#grad0 {
  margin: 2% 0;
  height: auto;
  padding: 1;
  background-image: linear-gradient(to right, 
  green 0%, 
    orange 33% ,
    red 100%);
  background-size: 100%;
  background-repeat: no-repeat;
}

.price-item-container {
  display: flex;
  flex-wrap: wrap;
  height: 9px;
}

.price-item {
  margin-bottom: 1;
  padding: 1;
 
}

.price-item span {
  display: inline-block;
  width: calc(100%); /* Adjust width based on padding */
}

.price-bar {
  height: 2px;
  background-color: green;
}
.price-item .conditionSpan{
  position: relative;
  top: 10px;
  width: auto;
}

.price-item .conditionSpanprice{
  position: relative;
  top: -20px;
  left: 40%;
  font-family: 'Open Sans';
width: auto;
}
.swal2-html-container .review-details{
  font-size: 11px !important;
    font-family: 'Montserrat' !important;
    text-align: left !important;
}
.swal2-html-container .review-passenger-details{
  font-size: 13px !important;
    font-family: 'Montserrat' !important;
    text-align: left !important;
}

.equipmentno{
  border: 1px solid #000;
  border-radius: 9px;
  margin: 0 1%;
  color: #000;
  padding: 0 6px;
  background-color: rgba(0, 0, 0, .03);
  width:auto;
}
.pages_filter{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: relative;
  top: -44px;
  left: 12%;
  width: 70%;
  background: #fff;
    color: #000;
    border-radius: 10px;
    margin: 0% 2%;
}
.pages_filter ul{
display: flex;
width: 100%;
}
.pages_filter ul li{
  list-style: none;
  width: 100%;
  cursor: pointer;
  font-family: 'Montserrat';
  padding: 1%;
  font-size: 12px;
  color: #000;

  }
  .filter_img{
    align-items: center;
    margin: auto;
    display: block;
  }

  /* .nav-links {
    display: inline-block;
    border-radius: 0.5rem;
    margin-top: 1px;
    transition: background-color 0.3s ease;
    line-height: 1; /
  }

  .nav-links:hover {
      
      background-color: #e5e5e5; 
  } */


.header-nav a {
    text-decoration: none !important;  /* Remove underline from links */
    padding: 0.5rem 0.7rem !important;  /* Add some padding around the links */
    transition: background-color 0.3s ease !important;
    color: #000 !important;  /* Default link color */
    background-color: transparent !important;  /* Default background */
    
}

.header-nav a:hover {
    background-color: #e5e5e5 !important;  /* Light gray hover effect */
    color: #000 !important;  /* Text color on hover */
    border-radius: 10px !important;  /* Optional: Rounded corners on hover */
}

.clear {
    clear: both !important;
}

/* Dropdown Menu Styling */
ul.dropdown-menu {
  background-color: white !important; /* White background */
  padding: 5px 5px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  border: 1px solid #ddd; /* Light border */
  list-style-type: none; /* Remove default list styling */
  margin: 0;
  padding: 0;
}

ul.dropdown-menu li {
  margin: 0;
  padding: 2px 0; /* Spacing between items */
  border-bottom: 1px solid #eee; /* Light separator line between each item */
}

ul.dropdown-menu li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

ul.dropdown-menu li a {
  color: black; /* Text color */
  font-weight: 500; /* Make text a little bold */
  font-family: 'Nunito', sans-serif;
  text-decoration: none;
  display: block;
  padding: 5px 0;
}

ul.dropdown-menu li a:hover {
  color: #785eff !important; /* Text color on hover */
  background-color: transparent !important;
}
.timeSlotsOuter {
  display: flex;
  justify-content: flex-start;
}

.filterTimeSlots {
  cursor: pointer;
  width: 65px;
  height: 64px;
  border-radius: 4px;
  border: 0.7px solid #dfdfdf;
  background-color: #fff;
  /* border-color: black; */
  text-align: center;
  color: #000;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0;
  align-items: center;
  margin-right: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect */
.filterTimeSlots:hover {
  background-color: #f0f0f0; /* Light gray on hover */
  border-color: #785eff; /* Border color change on hover */
}

/* Selected state */
.filterTimeSlots.selected {
  background-color: #785eff; /* Highlight color for selected */
  color: #fff; /* White text color for contrast */
  border-color: #785eff; /* Border matches background color */
}
.boldFont {
  font-weight: 650;
}
.appendBottom2 {
  margin-bottom: 2px;
}
.checkBlockIcon {
  width: 34px;
  height: 34px;
  display: inline-block;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.custom-loader
{
    height: 14vh !important;
    width: 100% !important;
    color: blue !important;
    background-color: rgba(0,0,0,0.0) !important;
    position: relative !important;
    /* z-index: 999999999999 !important; */
    display: grid !important;
    place-items: center !important;
}
.stop-badge-container {
  display: block;
  margin-top: 5px;
  text-align: center;
}

.flight-line-a {
  position: relative; /* To position the tooltip relative to this container */
  display: inline-block;
}

.stop-badge {
  color: blue;
    font-size: 10px;
    cursor: pointer;
}

.stop-badge .tooltip-content {
  visibility: hidden;
  width: 200px; /* Increased width */
  background-color: #ffffff;
  color: #000;
  text-align: center;
  padding: 12px; /* Increased padding */
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  /* top: -30%; */
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  font-size: 10px; /* Increased font size for tooltip content */
}

/* Tooltip Arrow */
.stop-badge .tooltip-content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

/* Show Tooltip on Hover */
.stop-badge:hover .tooltip-content {
  visibility: visible;
}
/* .bottom-block {
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: white;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.selected-flight-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  background-color: #281f55; /* Dark blue background */
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 10000;
}

.selected-flight-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.selected-flight-list {
  max-height: 500px;
  overflow-y: auto;
  margin: 10px 0;
}
.selected-flight-list::-webkit-scrollbar {
  display: none; 
}

.flight-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: #000;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.flight-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 10px;
}

/* .flight-details {
  flex: 1;
} */

.flight-airline {
  font-weight: bold;
  font-size: 13px;
}

.flight-time {
  font-size: 14px;
  color: #555;
}

.flight-price {
  text-align: right;
  margin-right: 1px;
}

.price {
  font-weight: bold;
  color: #1E326E;
}
.flight-detailss {
  flex: 2; /* Takes more space for airline and time */
  display: flex;
  flex-direction: column;
  color: #000; /* Ensure text is visible */
  margin-left: 12px;
}


.fare-type {
  font-size: 12px;
  color: #555;
}

.remove-btn {
  background: none;
  border: none;
  color: #FF0000;
  font-size: 18px;
  cursor: pointer;
}

.share-button-container {
  text-align: center;
  margin-top: 10px;
}

.share-btn {
  background: #785eff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.minimized-ball {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #1E326E;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: white;
  font-size: 24px;
  z-index: 1000;
}

.minimized-ball:hover {
  background-color: #333;
}


/* General Reset */
body, h1, h2, h3, h4, h5, h6, p, ul, li, input, button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.bgGradient {
  background-image: linear-gradient(0deg,#15457b,#051423);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 160px;
}

header.search-bar {
  background-color: #281f55; /* Dark Blue Background */
  padding: 15px;
  margin: 0 auto;
  max-width: 1325px;
  border-radius: 5px;
  margin-top: 53px; /* Space below fixed header */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 10; /* Ensure it stays on top */
  margin-bottom: 10px;
}
header.search-barr {
  background-color: #281f55; /* Dark Blue Background */
  padding: 15px;
  margin: 0 auto;
  max-width: 1325px;
  /* border-radius: 5px; */
  margin-top: 53px; /* Space below fixed header */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  /* position: sticky;
  top: 0;
  z-index: 10;  */
}

.hsw_inner {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap; /* Wrap on smaller screens */
  gap: 5px;
  /* margin-left: '9%'; */
}

.hsw_inputBox {
  /* margin-right: 15px; */
  position: relative;
  /* display: flex; */
  flex-direction: column;
}
.hsw_inputBox .redsearchdeparture ,
.hsw_inputBox .redsearchdeparture1,
.hsw_inputBox .redsearchreturn1,
.hsw_inputBox .infantmore,
.hsw_inputBox .redpassenger,
.hsw_inputBox .redsearchreturn{
  color: red;
  font-size: 12px;
  font-family: 'Raleway';
  display: none;
}

.lbl_input {
  color: white; /* Light Blue for Labels */
  font-size: 12px;
  /* font-weight: bold; */
  margin-bottom: 5px;
  font-weight: 600;
}

.hsw_inputField {
  background-color: #1e2a47; /* Input Box Background */
  border: none;
  color: #fff;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  width: 160px;
}

.hsw_inputField::placeholder {
  color: #7a8aa6; /* Placeholder Color */
}

.swap-icon {
  background-image: url('https://cdn-icons-png.flaticon.com/512/126/126507.png'); /* Swap Icon */
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

button#search-button {
  background: #785eff; /* Gradient Blue */
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
}

button#search-button:hover {
  background: #644ed4;
}

.fareTypeWrapper {
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: #7a8aa6;
}

.fareTypeWrapper span {
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
}

.fareTypeOptions {
  display: flex;
}

.fareTypeOptions li {
  list-style: none;
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.fareTypeOptions li span {
  color: #fff;
  font-size: 12px;
}

.fareTypeOptions .radioSelect {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #4facfe;
  border-radius: 50%;
  position: relative;
}

.fareTypeOptions .active .radioSelect {
  background-color: #4facfe;
}

.fareOptionTooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #1e2a47;
  padding: 10px;
  color: #fff;
  font-size: 10px;
  width: 200px;
  border-radius: 5px;
}

.fareTypeOptions li:hover .fareOptionTooltip {
  display: block;
}

/* Ensure spacing below fixed header */
.fixed-header + .search-bar {
  margin-top: 53px;
}
.page-center-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Full height to cover the page */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional, for a subtle background */
  z-index: 9999; /* Ensure it stays on top */
}
.page-center-loaderr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Full height to cover the page */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* Optional, for a subtle background */
  z-index: 9999; /* Ensure it stays on top */
}

.big-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: blue !important;
}

.big-icon {
  width: 100px; /* Increased icon size */
  height: 100px; /* Increased icon size */
}

.tripTypeLabel {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #0073e6; /* Blue color for label */
  margin-bottom: 5px;
  display: block;
}

/* Dropdown Container */
.selectDropdown {
  position: relative;
  width: 100px; /* Adjust width as needed */
  border-radius: 5px;
}

/* Select Styling */
.tripTypeSelect {
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none; /* Remove default dropdown arrow */
  background-color: #fff;
  cursor: pointer;
  outline: none;
}

/* Dropdown Arrow */
.tripTypeSelect::after {
  content: "▼";
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #666;
}

/* Select Focus State */
.tripTypeSelect:focus {
  border-color: #0073e6;
  box-shadow: 0 0 5px rgba(0, 115, 230, 0.5);
}
.dropdownIcon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent interference with the dropdown */
}
.custom-modal-header {
  color: white;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}

.custom-modal-header .close-btn {
  background: none;
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Modal Body */
.custom-modal-body {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.form-group {
  margin-bottom: 20px;
  flex: 1;
  
}

.form-group label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group select {
  appearance: none;
  background: white;
}

.chips-input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 40px;
  gap: 5px;
  background: white;
  font-size: 14px;
}

.chips-input-container input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 14px;
  min-width: 150px; /* Adjust based on desired input width */
}

.chip {
  display: flex;
  align-items: center;
  background: #e3f2fd;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.chip button {
  background: none;
  border: none;
  color: #1976d2;
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Remove placeholder when there are chips */
.chips-input-container input::placeholder {
  color: #aaa;
}

/* Input focus styles */
.chips-input-container:focus-within {
  border-color: #1976d2;
}

/* Modal Footer */
.custom-modal-footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
  border-top: none;
}

.send-button {
  padding: 10px 20px;
  background-color: #785ef7;
  /* background: linear-gradient(to right, rgb(120, 94, 255), rgb(180, 74, 203)); */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.send-button:hover {
  background-color: #644ed4;
}
.invalid {
  border: 2px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.clientNameDisplay {
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin-top: 5px;
}
.booking-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.trip-type-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.trip-type-container input[type="radio"] {
  margin-right: 5px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.section label {
  font-weight: bold;
  margin-bottom: 5px;
}

.section input {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  width: 150px; /* Adjust width as needed */
}

.section input[type="date"] {
  width: 100px; /* Adjust width for date inputs */
}

.section select {
  width: 150px; /* Adjust width for select element */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.form-roww {
  display: flex;
  justify-content: space-between;
  position: relative;
  /* gap: 15px; */
}
.form-groupp {
  margin-bottom: 20px;
  flex: 1;
  border: 1px solid #D9DFC6;
  height: 93px;
}
.search-buttonn {
  background-color: #785ef7;
  color: #fff;
  padding: 8px 35px;
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 200px;
  margin-top: 20px; /* Add spacing above the button */
  margin: auto;
}
.search-buttonn:hover{
  background-color: #644ed4;
}
.swapbuttonn {
  position: absolute; /* Position the button absolutely */
  top: 42%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  left: 20%;
  background: white; /* Make the button background transparent */
  border: none; /* Remove button border */
  z-index: 1;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
 /* .location-info {
  width: 100%;
  height: 60%;
  font-size: large;
  font-weight: bold;

}

.location-info input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
} */

.location-info {
  font-family: 'Montserrat', sans-serif;
  padding-left: 12px;
  margin-right: 5px;
  /* margin: 10px 0; */
}

.location-header {
  font-size: 11px;
  font-weight: bold;
  color: #7a7a7a;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.city-name-input {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.city-name-input::placeholder {
  color: #ccc;
  font-weight: normal;
}

.location-details {
  /* border: 1px solid #e3e3e3; */
  /* padding: 10px; */
  /* border-radius: 4px; */
  /* background-color: #fff; */
  position: relative;
}

.airport-name {
  font-size: 10px;
  color: #383838;
  /* margin-top: 5px; */
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  /* border-radius: 4px; */
  width: 180px;
  z-index: 9999;
  max-height: 150px;
  overflow-y: auto;
  margin-top: 5px;
  padding: 0;
  list-style: none;
}

.dropdown-item {
  /* padding: 10px; */
  cursor: pointer;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: #4c4c4c;
  /* border-bottom: 1px solid #e3e3e3; */
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item .airport-name {
  font-size: 10px;
  color: #7a7a7a;
}

.loader-gif {
  width: 130px;
  height: 130px;
}
.booking-container1 {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between rows */
  margin: 5px;
}

/* Row Layout */
.booking-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

/* Individual Field Container */
.booking-field {
  flex: 1;
  min-width: 30%;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Labels */
.booking-field label {
  font-size: 12px;
  color: #626262;
  margin-bottom: 5px;
  display: inline-flex; /* Keeps text and star on the same line */
  align-items: center;
  gap: 4px; /* Adds space between text and star */
  white-space: nowrap; /* Prevents wrapping */
}

/* Input and Select Styles */
.booking-field input,
.booking-field select {
  width: 100%;
  padding: 6px;
  border: 1px solid #e3e3e3;
  height: 36px;
  font-size: 13px;
  color: #626262;
  border-radius: 4px;
  outline: none;
}

/* Select Dropdown Styling */
.custom-select {
  appearance: none;
  background-color: #fff;
  cursor: pointer;
}
.booking-field.booking-mobile {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mobile-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.phone-input {
  width: 100%;
}

/* Error Message Styling */
.error-message {
  font-size: 11px;
  color: red;
  margin-top: 4px;
  display: none; /* Initially hidden */
}
